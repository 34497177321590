// @flow
import * as React from "react";
import styles from "./styles";
import { makeStyles } from "@material-ui/core";
import SharedTextField from "../SharedTextField/SharedTextField";

const useStyles = makeStyles(styles);

type Props = {
  handleChangeConfirmInputText?: () => void,
};

export default function ConfirmInput({
  handleChangeConfirmInputText,
}: Props): React.Node {
  const classes = useStyles();
  return (
    <SharedTextField
      fullWidth
      variant="outlined"
      id="title"
      type="text"
      autoFocus
      autoComplete="off"
      onChange={handleChangeConfirmInputText}
      className={classes.confirmInput}
    />
  );
}
