import React from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  Hidden,
  AppBar,
  Button,
  makeStyles,
  Toolbar,
} from "@material-ui/core";
import classNames from "classnames";
import { AiFillCloseCircle } from "react-icons/ai";

import styles from "./styles";
import NavLogo from "./NavLogo";
import NavButtonGroup from "./NavButtonGroup";

const useStyles = makeStyles(styles);

export default function NavbarUI({ actions }) {
  const { handleOpenDrawer, handleCloseDrawer, open } = actions;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar style={{ minHeight: "80px" }}>
          <NavLogo />
          <Hidden smDown implementation="css">
            <NavButtonGroup actions={actions} />
          </Hidden>
          <Hidden mdUp implementation="css">
            <Button
              onClick={handleOpenDrawer}
              className={classes.mobileMenuButton}
            >
              Menu
            </Button>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={open}
              classes={{
                paper: classNames(classes.drawerPaper),
              }}
              onClose={handleCloseDrawer}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <Button
                onClick={handleCloseDrawer}
                className={classes.mobileMenuCloseIconButton}
              >
                <AiFillCloseCircle className={classes.mobileMenuCloseIcon} />
              </Button>
              <NavButtonGroup actions={actions} />
            </Drawer>
          </Hidden>
        </Toolbar>
      </AppBar>
    </div>
  );
}

NavbarUI.propTypes = {
  actions: PropTypes.shape({
    handleOpenDrawer: PropTypes.func.isRequired,
    handleCloseDrawer: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  }).isRequired,
};
