import React from "react";
import Base from "./Base";
import { MdOutlineError } from "react-icons/md";

export default function InternalServer() {
  return (
    <Base
      icon={<MdOutlineError style={{ fontSize: "100px" }} />}
      error="500: Internal Server Error"
      description="The server has encountered an internal error and was unable to complete your request"
      btnIconMsg="Go back"
      handleClick={() => window.history.go(-2)}
    />
  );
}
