import * as yup from "yup";

export const applicantSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  middleName: yup.string(),
  lastName: yup.string().required("Required"),
  email: yup
    .string()
    .email("This must be a valid email")
    .required("The user's email is required"),
  phoneNumber: yup.string().required("The phone number is required"),
  whyWorkHere: yup.string().required("This field is required"),
  whyFitRole: yup.string().required("This field is required"),
});
