import {
  grayColor,
  hexToRgb,
  blackColor,
} from "../../assets/jss/universalStyle";

const customDialogStyle = (theme) => ({
  dialogPaper: {
    maxHeight: (props) => props.maxHeight || "85vh",
    minHeight: (props) => props.minHeight || "85vh",
    minWidth: (props) => props.minWidth || "600px",
    maxWidth: (props) => props.maxWidth,
    [theme.breakpoints.down("md")]: {
      maxHeight: "75vh",
    },
    transition: "all 150ms ease 0s",
  },

  "@media screen and (max-width: 768px)": {
    dialogPaper: {
      maxHeight: (props) => props.maxHeight || "80vh",
      minHeight: (props) => props.minHeight || "80vh",
      minWidth: (props) => props.minWidth || "90vw",
      maxWidth: (props) => props.maxWidth,
      [theme.breakpoints.down("md")]: {
        maxHeight: "75vh",
      },
      transition: "all 150ms ease 0s",
    },
  },

  dialogActionBtn: {
    width: "180px",
    color: "white",
    textTransform: "none",
    borderRadius: "8px",
    background: "#ff5e00",
    fontWeight: "600",
    "&:hover": {
      background: "#c42800",
      boxShadow:
        "0 10px 20px -10px rgba(" +
        hexToRgb(grayColor[0]) +
        ", 0.2), 0 4px 10px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.3), 0 8px 10px -5px rgba(" +
        hexToRgb(grayColor[0]) +
        ", 0.3)",
    },
  },

  dialogActionBtnLoading: {
    width: "180px",
    color: "white",
    textTransform: "none",
    borderRadius: "8px",
    background: "#e0e0e0",
    fontWeight: "600",
    "&:hover": {
      boxShadow:
        "0 10px 20px -10px rgba(" +
        hexToRgb(grayColor[0]) +
        ", 0.2), 0 4px 10px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.3), 0 8px 10px -5px rgba(" +
        hexToRgb(grayColor[0]) +
        ", 0.3)",
    },
  },
});

export default customDialogStyle;
