import React, { useState } from "react";
// import { useAuth0 } from "@auth0/auth0-react";
import NavbarUI from "./NavbarUI";

const Navbar = () => {
  // const { loginWithRedirect } = useAuth0();

  const [open, setOpen] = useState(false);

  function handleLogin() {
    setOpen(false);
    // loginWithRedirect();
    window.location.href = '/login';
  }

  function handleSignup() {
    setOpen(false);
    // loginWithRedirect({ authorizationParams: { screen_hint: "signup" }, });
    window.location.href = '/register';
  }

  function handleCloseDrawer() {
    setOpen(false);
  }

  function handleOpenDrawer() {
    setOpen(true);
  }

  const actions = {
    handleLogin,
    handleSignup,
    handleCloseDrawer,
    handleOpenDrawer,
    open,
  };

  return <NavbarUI actions={actions} />;
};

export default Navbar;
