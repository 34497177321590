import { GiTeacher } from "react-icons/gi";
import { IoIosSchool } from "react-icons/io";
import { MdSecurity } from "react-icons/md";

export const roleConfig = (user = {}) => [
  {
    role: "Administrator",
    icon: MdSecurity,
    label: "Admin",
    roleCardLink: `/admin/${user.sub}/next-steps`,
  },
  {
    role: "Teacher",
    icon: GiTeacher,
    label: "Teacher / Instructor",
    roleCardLink: `/teacher-organizations`,
  },
  {
    role: "Student",
    icon: IoIosSchool,
    label: "Student / Trainee",
    roleCardLink: `/student-organizations`,
  },
  // Add more roles as needed in the future
];
