import React from "react";
import Base from "./Base";
import { MdOutlineError } from "react-icons/md";

export default function BadRequest() {
  return (
    <Base
      icon={<MdOutlineError style={{ fontSize: "100px" }} />}
      error="400: Bad Request Error"
      description="Uh oh... Your browser sent something I don't understand"
      btnIconMsg="Go back"
      handleClick={() => window.history.go(-2)}
    />
  );
}
