import { Card, CardContent, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CircleSpinner from "../../components/CircleSpinner/CircleSpinner";
import OrganizationCard from "../../components/OrganizationCard/OrganizationCard";
import "./styles.css";
import useGetAuthUser from "../../hooks/Users/useGetAuthUser";
import { activeRoute } from "../../utils/activeRoute";
import IconBtn from "../../components/IconBtn/IconBtn";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { AiOutlineArrowLeft } from "react-icons/ai";

function MyOrganizationsPage() {
  const { user } = useGetAuthUser();

  const [organizationsWithUsersRole, setOrganizationsWithUsersRole] = useState(
    []
  );

  const GET_TEACHER_ORGANIZATIONS = gql`
    query getTeacherOrganizations($userId: String!) {
      getTeacherOrganizations(userId: $userId) {
        organizationId
        title
        logoUrl
        type
        teachers {
          teacherId
          userId
        }
      }
    }
  `;

  const GET_STUDENT_ORGANIZATIONS = gql`
    query getStudentOrganizations($userId: String!) {
      getStudentOrganizations(userId: $userId) {
        organizationId
        title
        logoUrl
        type
        students {
          studentId
          userId
        }
      }
    }
  `;

  const [getTeacherOrganizations, { loading: getTeacherOrganizationsLoading }] =
    useLazyQuery(GET_TEACHER_ORGANIZATIONS);

  const [getStudentOrganizations, { loading: getStudentOrganizationsLoading }] =
    useLazyQuery(GET_STUDENT_ORGANIZATIONS);

  useEffect(() => {
    if (activeRoute("/teacher-organizations")) {
      getTeacherOrganizations({
        variables: {
          userId: user?.sub,
        },
      }).then((response) => {
        setOrganizationsWithUsersRole(response.data.getTeacherOrganizations);
      });
    } else if (activeRoute("/student-organizations")) {
      getStudentOrganizations({
        variables: {
          userId: user?.sub,
        },
      }).then((response) => {
        setOrganizationsWithUsersRole(response.data.getStudentOrganizations);
      });
    }
  }, []);

  const organizationCards = organizationsWithUsersRole?.map((organization) => {
    return (
      <OrganizationCard
        key={organization.organizationId}
        organization={organization}
        linkTo={
          activeRoute("/student-organizations")
            ? `/student/${organization.students[0].studentId}/${organization.type}/${organization.organizationId}/next-steps`
            : activeRoute("/teacher-organizations")
            ? `/teacher/${organization.teachers[0].teacherId}/${organization.type}/${organization.organizationId}/next-steps`
            : "/"
        }
      />
    );
  });

  if (getTeacherOrganizationsLoading || getStudentOrganizationsLoading)
    return <CircleSpinner />;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        minHeight: "100vh",
        backgroundImage: "linear-gradient(to right, #ff5e00, purple)",
      }}
    >
      <Card
        style={{
          padding: "10px",
          borderRadius: "10px",
          margin: "10px",
          width: "600px",
        }}
      >
        <CardContent style={{ textAlign: "left" }}>
          <p
            style={{
              fontSize: "20px",
              color: "black",
              marginTop: "20px",
              marginBottom: "30px",
            }}
          >
            {organizationCards && organizationCards.length === 0
              ? "You are not enrolled in any organization."
              : "Choose an organization to proceed"}
          </p>
          <Grid
            justifyContent="center"
            container
            alignItems="center"
            spacing={2}
          >
            {organizationCards}
            <Grid align="center" item xs={12} sm={12} md={12} lg={12}>
              <IconBtn
                handleClick={() => window.history.back()}
                icon={<AiOutlineArrowLeft style={{ padding: "2px" }} />}
              />
              <p style={{ fontSize: "14px", color: "grey" }}>
                Go back to your roles
              </p>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default MyOrganizationsPage;
