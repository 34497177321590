import React from "react";
import { Card, makeStyles } from "@material-ui/core";

import styles from "./styles";

const useStyles = makeStyles(styles);

function WrapperCard({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.wrapperCardDiv}>
      <Card className={classes.wrapperCard}>{children}</Card>
    </div>
  );
}

export default WrapperCard;
