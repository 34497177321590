import { useQuery } from "@apollo/client";
import { GET_TEACHERS } from "../../utils/graphql";

export default function useGetTeachersQuery(whereParams, query, onCompleted) {
  const fn = () => {
    return;
  };
  const { loading: getTeachersLoading, data: teachersData } = useQuery(
    query || GET_TEACHERS,
    {
      variables: {
        where: whereParams,
      },
      onCompleted: onCompleted || fn,
    }
  );

  // get the currently logged in teacher
  const teacher = teachersData && teachersData.teachers[0];

  const hasTeacherProfile = teacher?.firstName ? true : false;

  return {
    teacher,
    hasTeacherProfile,
    getTeachersLoading,
  };
}
