import { useMutation } from "@apollo/client";
import { CREATE_CUSTOMER } from "../../utils/graphql";

export default function useCreateCustomer() {
  // [Mutation]createCustomer
  const [createCustomer, { loading: createCustomerLoading }] =
    useMutation(CREATE_CUSTOMER);

  return {
    createCustomer,
    createCustomerLoading,
  };
}
