import { gql, useMutation, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { Card, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import CircleSpinner from "../../components/CircleSpinner/CircleSpinner";
import IconBtn from "../../components/IconBtn/IconBtn";
import { NotificationContext } from "../../components/Notifications/NotificationContext";
import {
  organizationTypeOptions,
  salesAgentOptions,
} from "../../utils/dropdownOptions";
import {
  ADDRESS_INFO_FRAGMENT,
  ADMIN_INFO_FRAGMENT,
  CREATE_ORGANIZATION,
  REMOVE_FILE,
  UPDATE_ORGANIZATION,
  UPLOAD_FILE,
} from "../../utils/graphql";
import "./styles.css";
import { organizationSchema } from "../../utils/Validations/organizationCreateValidation";
import { FaPowerOff } from "react-icons/fa";
import useAssignRolesToUser from "../../hooks/rest-gql-auth0/useAssignRolesToUser";
import useGetAllRoles from "../../hooks/rest-gql-auth0/useGetAllRoles";
import useCreateCustomer from "../../hooks/rest-gql-auth0/useCreateCustomer";
import useDeleteCustomer from "../../hooks/rest-gql-auth0/useDeleteCustomer";
import SignUpStepper from "../../components/Signup/SignUpStepper";
import ButtonGroup from "../../components/Signup/ButtonGroup";
import { userSchema } from "../../utils/Validations/userCreateValidation";
import OrganizationCreatorDialogBody1 from "../../components/CreatorCard/OrganizationCreatorDialogBody1";
import OrganizationCreatorDialogBody2 from "../../components/CreatorCard/OrganizationCreatorDialogBody2";
import OrganizationCreatorDialogBody3 from "../../components/CreatorCard/OrganizationCreatorDialogBody3";
import OrganizationCreatorDialogBody4 from "../../components/CreatorCard/OrganizationCreatorDialogBody4";
import SignUpStepperMobile from "../../components/Signup/SignUpStepperMobile";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

const useStyles = makeStyles({
  container: {
    height: "100vh",
  },
  item1: {
    background: "white",
  },
  item2: {
    background: "linear-gradient(to right, #ff5e00, purple)",
  },
  img: {
    width: "140px",
    margin: "60px 0 0 60px",
  },
  mainHeading: {
    fontSize: "50px",
    margin: "40px 20px 20px 60px",
    fontWeight: "bold",
  },
  "@media screen and (max-width: 768px)": {
    mainHeading: {
      fontSize: "25px",
      margin: "20px 20px 0px 40px",
      fontWeight: "bold",
    },
    img: {
      width: "140px",
      margin: "40px 0 0 40px",
    },
  },
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
  },
  rootMobile: {
    display: "flex",
    justifyContent: "center",
  },
  card: {
    width: "160px",
    height: "80px",
    padding: "5px",
    textAlign: "center",
    background: "#111b29",
    "&:hover": {
      boxShadow: " 0px 0px 10px 4px rgba(163,163,163,0.9)",
    },
  },
  heading: {
    fontSize: "15px",
    fontWeight: "300",
    color: "white",
    textTransform: "none",
  },

  title: {
    justifyContent: "center",
    marginBottom: "20px",
    fontSize: "16px",
    fontWeight: "600",
    background: "-webkit-linear-gradient(45deg, #ff5e00 10%, purple 90%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    textTransform: "uppercase",
  },
  subTitle: {
    color: "grey",
    margin: "25px 0 25px 0",
    lineHeight: "24px",
    fontSize: "14px",
  },

  button: {
    width: "180px",
    color: "white",
    background: "#9076d1",
    margin: "10px 10px 20px 10px ",
    textTransform: "none",
    "&:hover": {
      background: "#604a9f",
    },
  },

  buttonLoading: {
    width: "180px",
    background: "#e1e1e1",
    margin: "10px 10px 20px 10px ",
    textTransform: "none",
  },
  stepText: {
    background: "-webkit-linear-gradient(45deg, #ff903e 10%, #604a9f 95%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: "bold",
  },
});

const SelfCreateOrganizationPage = () => {
  const classes = useStyles();

  const { user, logout } = useAuth0();

  const history = useHistory();

  const { createNotification } = useContext(NotificationContext);

  const { createCustomer } = useCreateCustomer();
  const { deleteCustomer } = useDeleteCustomer();

  const { assignUserRole, assignRolesToUserLoading } = useAssignRolesToUser();

  //  states
  const [authRoles, setAuthRoles] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [files, setFiles] = useState([]);
  const [srcImage, setSrcImage] = useState(null);
  const [createCustomerLoading, setCreateCustomerLoading] = useState(false);
  const [width, setWidth] = React.useState(window.innerWidth);

  const [activeStep, setActiveStep] = React.useState(0);

  const breakpoint = 700;

  React.useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  function getSteps() {
    return [
      "Basic Organization Information",
      "Organization Branding (Optional)",
      "Create your Administrator Profile",
      "Referral Program",
    ];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return `Please provide the name, type and email of your organization`;
      case 1:
        return "Add your organization logo";
      case 2:
        return "This profile will allow you to onboard other administrators, instructors and trainees, and to create and manage courses in your organization";
      case 3:
        return "As part of our commitment to delivering exceptional customer service, we would like to recognize the sales agent who initiated your sign-up process. We believe in acknowledging the hard work and dedication of our team members.";
    }
  }

  const steps = getSteps();

  const handleNext = () => {
    (activeStep === 0 && formik.isValid) ||
    activeStep === 1 ||
    (activeStep === 2 && formik2.isValid) ||
    activeStep === 3
      ? setActiveStep((prevActiveStep) => prevActiveStep + 1)
      : createNotification({
          message: `You are missing some required fields`,
          status: "warning",
        });

    ((activeStep === 0 && formik.isValid) ||
      activeStep === 1 ||
      (activeStep === 2 && formik2.isValid) ||
      activeStep === 3) &&
      window.scrollTo(0, 0);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scrollTo(0, 0);
  };

  const handleReset = () => {
    formik.resetForm();
    formik2.resetForm();
    setPhoneNumber("");
    setFiles([]);
    setActiveStep(0);
  };

  const handleSubmit = () => {
    formik.submitForm();
  };

  const { getAllRolesLoading } = useGetAllRoles((data) => {
    setAuthRoles(data?.getAllRoles);
  });

  // GET ADMIN AND THEIR PARTICIPATING ORGANIZATIONS
  const GET_ADMINS = gql`
    query admins($where: AdminWhere) {
      admins(where: $where) {
        ...AdminInfo
        address {
          ...AddressInfo
        }
        organizations {
          organizationId
          title
          type
          logoUrl
        }
      }
    }
    ${ADMIN_INFO_FRAGMENT}
    ${ADDRESS_INFO_FRAGMENT}
  `;

  // get admins query
  const { loading: getAdminsLoading, data: adminsData } = useQuery(GET_ADMINS, {
    variables: {
      where: {
        userId: user?.sub,
      },
      onCompleted: (data) => {
        console.log(data);
      },
    },
    fetchPolicy: "cache-and-network",
  });

  // get the currently logged in admin
  const admin = adminsData && adminsData.admins[0];

  const createOrganizationDone = admin?.organizations?.length > 0;

  const createProfileDone =
    admin?.firstName !== null && admin?.lastName !== null;

  const handleRedirectToNextPage = () => {
    if (createOrganizationDone && createProfileDone) {
      history.push(`/admin/${user.sub}/next-steps`);
    }
    return;
  };

  // mutations
  const [createOrganizations, { loading: createOrganizationsLoading }] =
    useMutation(CREATE_ORGANIZATION, {
      onCompleted: () => {
        createNotification({
          message: "Organization created successfully",
          status: "success",
        });
      },
      refetchQueries: [GET_ADMINS],
      notifyOnNetworkStatusChange: true,
    });

  // [Mutation] upload file to fs
  const [uploadFile, { loading: uploadFileLoading }] = useMutation(UPLOAD_FILE);

  // [Mutation]delete file from google cloud
  const [removeFile, { loading: removeFileLoading }] = useMutation(REMOVE_FILE);

  const onChangePhoneNumber = (e) => {
    setPhoneNumber(e);
  };

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      salesAgent: "Self Signup",
      title: "",
      type: "",
      email: "",
    },
    onSubmit: ({ salesAgent, title, type, email }) => {
      setCreateCustomerLoading(true);
      createCustomer({
        variables: {
          name: title,
          email,
          type,
        },
      })
        .then((response) => {
          // first upload the logo file and generate a gc url for neo4j
          Promise.all(
            files.map((file) => {
              return uploadFile({ variables: { file } }).then((res) => {
                return {
                  url: res.data.UploadFile.url,
                  gcName: res.data.UploadFile.gcName,
                };
              });
            })
          ).then((res) => {
            createOrganizations({
              variables: {
                input: [
                  {
                    createdAt: new Date().toISOString(),
                    stripeCustomerId: response.data.createCustomer.id,
                    salesAgent,
                    title,
                    email,
                    type,
                    phoneNumber,
                    gcLogoName: res?.[0]?.gcName,
                    logoUrl: res?.[0]?.url,
                    address: {
                      create: {
                        node: {
                          createdAt: new Date().toISOString(),
                          addressLine1: "",
                          addressLine2: "",
                          city: "",
                          country: "",
                          state: "",
                          postalCode: "",
                        },
                      },
                    },
                    admins: {
                      create: {
                        node: {
                          createdAt: new Date().toISOString(),
                          userId: user.sub,
                          authName: user.name,
                          authPicture: user.picture,
                          email: user.email,
                          firstName: formik2.values.firstName,
                          middleName: formik2.values.middleName,
                          lastName: formik2.values.lastName,
                          dateJoined: new Date().toISOString(),
                          username: user.nickname,
                          isSuperAdmin: false,
                          photo: user.picture,
                          address: {
                            create: {
                              node: {
                                createdAt: new Date().toISOString(),
                                addressLine1: "",
                                addressLine2: "",
                                city: "",
                                country: "",
                                state: "",
                                postalCode: "",
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                ],
              },
            })
              .then(() => assignUserRole(authRoles, user.sub, "Administrator"))
              .catch((err) => {
                console.log(err);
                createNotification({
                  message: `Oops - Something went wrong`,
                  status: "error",
                });
                //   delete the stripe customer coz an error occured above
                deleteCustomer({
                  variables: {
                    customerId: response.data.createCustomer.id,
                  },
                });
                // remove the organization logo from google cloud coz an error occured
                removeFile({
                  variables: {
                    gcName: res?.[0]?.gcName,
                  },
                });
              });
          });
        })
        .then(() => setCreateCustomerLoading(false))
        .catch((err) =>
          createNotification({
            message: err,
            status: "error",
          })
        );
    },
    validationSchema: organizationSchema,
  });

  const formik2 = useFormik({
    validateOnMount: true,
    initialValues: {
      email: user?.email,
      firstName: "",
      middleName: "",
      lastName: "",
    },
    validationSchema: userSchema,
  });

  // ############################################################################
  // File handling (files to be uploaded)
  // ############################################################################

  // Adding a file to the list of files to be uploaded
  const handleAddFilesToStage = (acceptedFiles) => {
    setFiles([...acceptedFiles]);
    if (acceptedFiles.length !== 0)
      setSrcImage(URL.createObjectURL(acceptedFiles[0]));
  };

  // Handler for deleting a assessment file that has been staged(this fn is passed
  // down as a prop to the assessment creator component)
  const handleRemoveFileFromStage = (selectedFileIdx) => {
    const filtered = files.filter((file, index) => index !== selectedFileIdx);
    setSrcImage(null);
    setFiles(filtered);
  };

  if (
    getAllRolesLoading ||
    getAdminsLoading ||
    assignRolesToUserLoading ||
    createCustomerLoading ||
    uploadFileLoading ||
    createOrganizationsLoading ||
    removeFileLoading
  )
    return <CircleSpinner />;

  handleRedirectToNextPage();

  const item1 = (
    <Grid item xs={12} sm={5} md={5} lg={5} className={classes.item1}>
      <img
        src="https://res.cloudinary.com/teenkevo-cloud/image/upload/v1658287281/vlearned-beta/vlearned-final-logo_pazz2u_oypnii.webp"
        alt="logo"
        className={classes.img}
      />
      <p className={classes.mainHeading}>
        Hi, let's get you started in a few steps.
      </p>
      <SignUpStepper
        steps={steps}
        getStepContent={getStepContent}
        activeStep={activeStep}
        handleBack={handleBack}
        handleNext={handleNext}
        handleReset={handleReset}
      />
      <div style={{ textAlign: "center" }}>
        <div style={{ marginTop: "10px" }}>
          <IconBtn
            handleClick={() => {
              logout({
                clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
                logoutParams: {
                  returnTo: window.location.origin,
                },
              });
              // Clear sessionStorage items
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("tokenExpiration");
            }}
            icon={<FaPowerOff style={{ color: "red" }} />}
          />
        </div>
        <p style={{ fontSize: "14px", color: "grey", marginBottom: "40px" }}>
          Logout
        </p>
      </div>
    </Grid>
  );

  const item1Mobile = (
    <Grid item xs={12} sm={5} md={5} lg={5} className={classes.item1}>
      <img
        src="https://res.cloudinary.com/teenkevo-cloud/image/upload/v1658287281/vlearned-beta/vlearned-final-logo_pazz2u_oypnii.webp"
        alt="logo"
        className={classes.img}
      />
      <p className={classes.mainHeading}>
        Hi, let's get you started in a few steps.
      </p>
      <SignUpStepperMobile
        steps={steps}
        getStepContent={getStepContent}
        activeStep={activeStep}
        handleBack={handleBack}
        handleNext={handleNext}
        handleReset={handleReset}
      />
      <div style={{ textAlign: "center" }}>
        <div>
          <IconBtn
            handleClick={() => {
              logout({
                clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
                logoutParams: {
                  returnTo: window.location.origin,
                },
              });
              // Clear sessionStorage items
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("tokenExpiration");
            }}
            icon={<FaPowerOff style={{ color: "red" }} />}
          />
        </div>
        <p style={{ fontSize: "14px", color: "grey", marginBottom: "20px" }}>
          Logout
        </p>
      </div>
    </Grid>
  );

  const item2Mobile = (
    <Grid item xs={12} sm={7} md={7} lg={7} className={classes.item2}>
      <div className={classes.rootMobile}>
        {activeStep === 0 ? (
          <Card className="cardAnimContainerMobile">
            <OrganizationCreatorDialogBody1
              // typed inputs
              onChangeTitle={formik.handleChange}
              onChangeEmail={formik.handleChange}
              onChangePhoneNumber={onChangePhoneNumber}
              //   values
              title={formik.values.title}
              email={formik.values.email}
              phoneNumber={phoneNumber}
              type={formik.values.type}
              typeOptions={organizationTypeOptions}
              // ...onChangeFns
              onChangeType={(value) =>
                formik.setFieldValue("type", value.value)
              }
              // Error handling //
              // ...check if fields have been touched before throwing error
              titleError={formik.touched.title && Boolean(formik.errors.title)}
              emailError={formik.touched.email && Boolean(formik.errors.email)}
              typeError={formik.touched.type && Boolean(formik.errors.type)}
              // ...helperTexts(error messages)
              titleHelperText={formik.touched.title && formik.errors.title}
              emailHelperText={formik.touched.email && formik.errors.email}
              typeHelperText={formik.touched.type && formik.errors.type}
              // ...onBlur prop to show errors more immediately
              onBlur={() => {
                formik.setTouched({ title: true, type: true, email: true });
              }}
            />
            <ButtonGroup
              handleReset={handleReset}
              handleBack={handleBack}
              handleNext={handleNext}
              activeStep={activeStep}
              steps={steps}
            />
          </Card>
        ) : activeStep === 1 ? (
          <Card className="cardAnimContainerMobile">
            <OrganizationCreatorDialogBody2
              // logo upload stuff
              stagedFilesForUpload={files}
              handleAddFilesToStage={handleAddFilesToStage}
              handleRemoveFileFromStage={handleRemoveFileFromStage}
              src={srcImage}
              // ...onBlur prop to show errors more immediately
              onBlur={() => {
                formik.setTouched({ title: true, type: true, email: true });
              }}
            />
            <ButtonGroup
              handleReset={handleReset}
              handleBack={handleBack}
              handleNext={handleNext}
              activeStep={activeStep}
              steps={steps}
            />
          </Card>
        ) : activeStep === 2 ? (
          <Card className="cardAnimContainerMobile">
            <OrganizationCreatorDialogBody3
              // defaults
              email={formik2.values.email}
              firstName={formik2.values.firstName}
              middleName={formik2.values.middleName}
              lastName={formik2.values.lastName}
              // typed inputs
              onChangeEmail={formik2.handleChange}
              onChangeFirstName={formik2.handleChange}
              onChangeMiddleName={formik2.handleChange}
              onChangeLastName={formik2.handleChange}
              // Error handling //
              emailError={
                formik2.touched.email && Boolean(formik2.errors.email)
              }
              firstNameError={
                formik2.touched.firstName && Boolean(formik2.errors.firstName)
              }
              middleNameError={
                formik2.touched.middleName && Boolean(formik2.errors.middleName)
              }
              lastNameError={
                formik2.touched.lastName && Boolean(formik2.errors.lastName)
              }
              // helper texts (error messages)
              emailHelperText={formik2.touched.email && formik2.errors.email}
              firstNameHelperText={
                formik2.touched.firstName && formik2.errors.firstName
              }
              middleNameHelperText={
                formik2.touched.middleName && formik2.errors.middleName
              }
              lastNameHelperText={
                formik2.touched.lastName && formik2.errors.lastName
              }
              // ...onBlur prop to show errors more immediately
              onBlur={formik2.handleBlur}
            />

            <ButtonGroup
              handleReset={handleReset}
              handleBack={handleBack}
              handleNext={handleNext}
              activeStep={activeStep}
              steps={steps}
            />
          </Card>
        ) : (
          <Card className="cardAnimContainerMobile" style={{ height: "600px" }}>
            <OrganizationCreatorDialogBody4
              salesAgent={formik.values.salesAgent}
              // ...options
              salesAgentOptions={salesAgentOptions}
              // ...onChangeFns
              onChangeSalesAgent={(value) =>
                formik.setFieldValue("salesAgent", value.value)
              }
            />
            <ButtonGroup
              handleReset={handleReset}
              handleBack={handleBack}
              handleNext={handleNext}
              handleSubmit={handleSubmit}
              activeStep={activeStep}
              steps={steps}
            />
          </Card>
        )}
      </div>
    </Grid>
  );

  const item2 = (
    <Grid item xs={12} sm={7} md={7} lg={7} className={classes.item2}>
      <div className={classes.root}>
        {activeStep === 0 ? (
          <Card className="cardAnimContainer">
            <div className={classes.title}>Basic Organization Information</div>
            <OrganizationCreatorDialogBody1
              // typed inputs
              onChangeTitle={formik.handleChange}
              onChangeEmail={formik.handleChange}
              onChangePhoneNumber={onChangePhoneNumber}
              //   values
              title={formik.values.title}
              email={formik.values.email}
              phoneNumber={phoneNumber}
              type={formik.values.type}
              typeOptions={organizationTypeOptions}
              // ...onChangeFns

              onChangeType={(value) =>
                formik.setFieldValue("type", value.value)
              }
              // Error handling //
              // ...check if fields have been touched before throwing error
              titleError={formik.touched.title && Boolean(formik.errors.title)}
              emailError={formik.touched.email && Boolean(formik.errors.email)}
              typeError={formik.touched.type && Boolean(formik.errors.type)}
              // ...helperTexts(error messages)
              titleHelperText={formik.touched.title && formik.errors.title}
              emailHelperText={formik.touched.email && formik.errors.email}
              typeHelperText={formik.touched.type && formik.errors.type}
              // ...onBlur prop to show errors more immediately
              onBlur={() => {
                formik.setTouched({ title: true, type: true, email: true });
              }}
            />
            <ButtonGroup
              handleReset={handleReset}
              handleBack={handleBack}
              handleNext={handleNext}
              activeStep={activeStep}
              steps={steps}
            />
          </Card>
        ) : activeStep === 1 ? (
          <Card className="cardAnimContainer">
            <div className={classes.title}>
              Organization Branding (Optional)
            </div>
            <OrganizationCreatorDialogBody2
              // logo upload stuff
              stagedFilesForUpload={files}
              handleAddFilesToStage={handleAddFilesToStage}
              handleRemoveFileFromStage={handleRemoveFileFromStage}
              src={srcImage}
              // ...onBlur prop to show errors more immediately
              onBlur={() => {
                formik.setTouched({ title: true, type: true, email: true });
              }}
            />
            <ButtonGroup
              handleReset={handleReset}
              handleBack={handleBack}
              handleNext={handleNext}
              activeStep={activeStep}
              steps={steps}
            />
          </Card>
        ) : activeStep === 2 ? (
          <Card className="cardAnimContainer">
            <div className={classes.title}>
              Create your Administrator Profile
            </div>
            <OrganizationCreatorDialogBody3
              // defaults
              email={formik2.values.email}
              firstName={formik2.values.firstName}
              middleName={formik2.values.middleName}
              lastName={formik2.values.lastName}
              // typed inputs
              onChangeEmail={formik2.handleChange}
              onChangeFirstName={formik2.handleChange}
              onChangeMiddleName={formik2.handleChange}
              onChangeLastName={formik2.handleChange}
              // Error handling //
              emailError={
                formik2.touched.email && Boolean(formik2.errors.email)
              }
              firstNameError={
                formik2.touched.firstName && Boolean(formik2.errors.firstName)
              }
              middleNameError={
                formik2.touched.middleName && Boolean(formik2.errors.middleName)
              }
              lastNameError={
                formik2.touched.lastName && Boolean(formik2.errors.lastName)
              }
              // helper texts (error messages)
              emailHelperText={formik2.touched.email && formik2.errors.email}
              firstNameHelperText={
                formik2.touched.firstName && formik2.errors.firstName
              }
              middleNameHelperText={
                formik2.touched.middleName && formik2.errors.middleName
              }
              lastNameHelperText={
                formik2.touched.lastName && formik2.errors.lastName
              }
              // ...onBlur prop to show errors more immediately
              onBlur={formik2.handleBlur}
            />

            <ButtonGroup
              handleReset={handleReset}
              handleBack={handleBack}
              handleNext={handleNext}
              activeStep={activeStep}
              steps={steps}
            />
          </Card>
        ) : (
          <Card className="cardAnimContainer" style={{ height: "600px" }}>
            <div className={classes.title}>Refferal Program</div>
            <OrganizationCreatorDialogBody4
              salesAgent={formik.values.salesAgent}
              // ...options
              salesAgentOptions={salesAgentOptions}
              // ...onChangeFns
              onChangeSalesAgent={(value) =>
                formik.setFieldValue("salesAgent", value.value)
              }
            />
            <ButtonGroup
              handleReset={handleReset}
              handleBack={handleBack}
              handleNext={handleNext}
              handleSubmit={handleSubmit}
              activeStep={activeStep}
              steps={steps}
            />
          </Card>
        )}
      </div>
    </Grid>
  );

  return (
    <div className={classes.container}>
      <Grid container className={classes.container}>
        {width > breakpoint ? (
          <>
            {item1}
            {item2}
          </>
        ) : (
          <>
            {item1Mobile}
            {item2Mobile}
          </>
        )}
      </Grid>
    </div>
  );
};

export default SelfCreateOrganizationPage;
