import { useQuery } from "@apollo/client";
import { GET_ALL_ROLES } from "../../utils/graphql";

export default function useGetAllRoles(onCompleted) {
  const defaultCallback = () => {};
  // [Query]remove roles from user
  const { loading: getAllRolesLoading, data: getAllRolesData } = useQuery(
    GET_ALL_ROLES,
    {
      onCompleted: onCompleted || defaultCallback,
    }
  );

  const allRoles = getAllRolesData?.getAllRoles;

  return {
    allRoles,
    getAllRolesLoading,
  };
}
