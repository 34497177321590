import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { TbListDetails } from "react-icons/tb";
import { BsRecordCircle } from "react-icons/bs";
import { StepConnector } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "30px 30px 0px 30px",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  icon: {
    color: "grey",
    fontSize: "25px",
    "&$activeIcon": {
      color: "black",
    },
    "&$completedIcon": {
      color: "#ff5e00",
    },
  },
  activeIcon: {},
  completedIcon: {},

  connector: {
    "&$activeConnector": {
      backgroundColor: "black", // Change to your desired color
    },
    "&$completedConnector": {
      backgroundColor: "#ff5e00", // Change to your desired color
    },
  },
  activeConnector: {},
  completedConnector: {},
}));

export default function SignUpStepperMobile({
  steps,
  getStepContent,
  activeStep,
}) {
  const classes = useStyles();

  const LabelIcon = () => {
    if (activeStep === 0) {
      return <BsRecordCircle className={classes.icon} />;
    }
  };

  return (
    <div className={classes.root}>
      <p
        style={{
          background: "-webkit-linear-gradient(45deg, #ff5e00 10%, purple 90%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontSize: "18px",
          marginLeft: "10px",
          fontWeight: "600",
        }}
      >
        {activeStep === 0
          ? steps[0]
          : activeStep === 1
          ? steps[1]
          : activeStep === 2
          ? steps[2]
          : steps[3]}
      </p>

      <p
        style={{
          color: "grey",
          fontSize: "14px",
          margin: "10px 30px 20px 10px",
        }}
      >
        {activeStep === 0
          ? getStepContent(0)
          : activeStep === 1
          ? getStepContent(1)
          : activeStep === 2
          ? getStepContent(2)
          : getStepContent(3)}
      </p>

      <Stepper
        style={{ background: "transparent", padding: "10px 0px 30px 0px" }}
        activeStep={activeStep}
      >
        {steps.map((label, index) => (
          <Step
            key={label}
            connector={<StepConnector style={{ color: "black" }} />}
          >
            <StepLabel
              StepIconProps={{
                classes: {
                  root: classes.icon,
                  active: classes.activeIcon,
                  completed: classes.completedIcon,
                },
              }}
            ></StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
