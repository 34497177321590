import React, { useEffect, useState, useRef, useContext } from "react";
import Button from "@material-ui/core/Button";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { red } from "@material-ui/core/colors";
import Divider from "@material-ui/core/Divider";
import { useAuth0 } from "@auth0/auth0-react";
import { BiLogOutCircle, BiSupport } from "react-icons/bi";
import SharedAvatar from "../SharedAvatar/SharedAvatar";
import CustomButton from "../CustomButtons/Button";
import { ClickAwayListener } from "@material-ui/core";
import { activeRoute } from "../../utils/activeRoute";
import { Link, useParams } from "react-router-dom";
import { RiOrganizationChart } from "react-icons/ri";
import LibraryBooksSharpIcon from "@material-ui/icons/LibraryBooksSharp";
import { IoMdAnalytics } from "react-icons/io";
import { useTour } from "@reactour/tour";
import UIOnboardingStarterDialog from "../UIOnboardingStarterDialog/UIOnboardingStarterDialog";
import { FaRobot } from "react-icons/fa";
import { adminWelcomeSteps } from "../UIOnboarding/Steps/Admin/adminWelcomeSteps";
import { NotificationContext } from "../Notifications/NotificationContext";


// saved user
import Cookies from 'js-cookie';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  cardRoot: {
    backgroundColor: "white",
    maxWidth: "300px",
  },
  avatar: {
    backgroundColor: red[500],
  },
  cardHeader: {
    padding: "10px",
    marginBottom: "5px",
  },
  button: {
    // display: "block",
    width: "100%",
    padding: "10px 20px 10px 20px",
    margin: "auto",
    color: "grey",
    textAlign: "start",
    boxShadow: "none",
    justifyContent: "flex-start",
    transition: "none",
  },
  popper: {
    zIndex: 1,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
  arrow: {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
}));

export default function ProfilePopper(props) {
  const classes = useStyles();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // const [STUDENT_ID, setSTUDENT_ID] = useState('');

  const { TEACHER_ID } = useParams();

  useEffect(() => {
    const fetchUserData = async () => {
      const userDetails = Cookies.get('user_details');
      // const userDetails = Cookies.get('user_details');
      console.log(organizationId, TEACHER_ID)

      if (!userDetails) {
        window.location.href = '/login';
        return;
      }

      try {
        setUser(JSON.parse(userDetails));
        setIsLoading(false);
      } catch (error) {
        console.error('Error parsing user details:', error);
        window.location.href = '/login';
      }
    };

    fetchUserData();
  }, []);

  const { createNotification } = React.useContext(NotificationContext);

  const { setIsOpen, setSteps, setCurrentStep } = useTour();

  const [open, setOpen] = useState(false);
  const [optInOpen, setOptInOpen] = useState(false);
  const [arrowRef, setArrowRef] = useState(null);
  const anchorRef = useRef(null);

  // const { logout } = useAuth0();

  const name = user?.name;
  const picture = user?.profile_pic;

  const { organizationId, type } = props.organization || {};

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (event)
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleClick = () => {
    // logout({
    //   clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    //   logoutParams: {
    //     returnTo: window.location.origin,
    //   },
    // });

    // Clear sessionStorage items
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("tokenExpiration");
    Cookies.remove('user_details');
    // setIsLoading(true)
    window.location.href = '/login'
    handleClose();
  };

  const handleOpenTour = () => {
    if (activeRoute("admin") && activeRoute("next-steps")) {
      setOptInOpen(false);
      setIsOpen(true);
      setCurrentStep(0);
      setSteps(adminWelcomeSteps);
    } else {
      setOptInOpen(false);
      createNotification({
        message: `Vlearned Buddy is not yet available on this page. Check back later`,
        status: "info",
      });
    }
  };

  const handleOpenOptIn = () => {
    setOpen(false);
    setOptInOpen(true);
  };

  const handleCloseOptIn = () => {
    setOptInOpen(false);
  };

  return (
    <div className={classes.root}>
      <div>
        <UIOnboardingStarterDialog openStatus={optInOpen} handleOpenTour={handleOpenTour} handleClose={handleCloseOptIn} />
        <Button className="seventh-step" ref={anchorRef} aria-haspopup="true" onClick={handleToggle}>
          <SharedAvatar alt={name} src={picture} width="30px" height="30px" />
        </Button>

        <Popper placement="bottom-end" open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal className={classes.popper} modifiers={{ arrow: { enabled: true, element: arrowRef, } }}>
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom-end" ? "center top" : "center bottom", }}>
              <Paper>
                <span className={classes.arrow} ref={setArrowRef} />
                <ClickAwayListener onClickAway={handleClose}>
                  <Card onMouseLeave={props.handleClose} className={classes.cardRoot}>
                    <CustomButton className={classes.button} disabled style={{ color: "black", fontWeight: "800", background: "#d9d9d9", }}>
                      <p>
                        Logged in as{" "}
                        {activeRoute("/teacher")
                          ? "an Instructor"
                          : activeRoute("/student")
                            ? "Trainee"
                            : activeRoute("/parent")
                              ? "Parent"
                              : activeRoute("/admin")
                                ? "Administrator"
                                : null}
                        <p style={{ fontSize: "13px", fontWeight: "400", marginTop: "5px", }}>
                          {user?.email}
                        </p>
                      </p>
                    </CustomButton>
                    <Divider />
                    {activeRoute("/admin") && (
                      <Link style={{ textDecoration: "none", marginRight: "10px" }} to={`/admin/${user._id}/organizations`}>
                        <CustomButton className={classes.button} startIcon={<RiOrganizationChart color="black" />}>
                          Your organizations
                        </CustomButton>
                      </Link>
                    )}
                    <Divider />
                    <Link style={{ textDecoration: "none", marginRight: "10px" }} to={
                      activeRoute("/admin")
                        ? `/admin/${user._id}/course-management`
                        : activeRoute("/teacher")
                          ? `/teacher/${TEACHER_ID}/${type}/${organizationId}/subjects`
                          : activeRoute("/student")
                            ? `/student/${user?.studentId}/${type}/${user?.organizationId}/subjects`
                            : "/"
                    }>
                      <CustomButton className={classes.button} startIcon={<LibraryBooksSharpIcon color="black" />}>
                        {activeRoute("/student")
                          ? "Enrolled courses"
                          : "Your courses"}
                      </CustomButton>
                    </Link>
                    <Divider />
                    <Link style={{ textDecoration: "none", marginRight: "10px" }} to={{ pathname: "https://vlearned.uvdesk.com/apps/form-builder/en/form/html/130326399e0a29119d6399e0a29124a", }} target="_blank">
                      <CustomButton
                        // onClick={handleClick}
                        className={classes.button} startIcon={<BiSupport color="black" />}>
                        {" "}
                        Contact support{" "}
                      </CustomButton>
                    </Link>

                    <Divider />
                    {/* <CustomButton
                      onClick={handleOpenOptIn}
                      className={classes.button}
                      startIcon={<FaRobot color="black" />}
                    >
                      Invoke Vlearned Buddy
                    </CustomButton>
                    <Divider /> */}
                    <CustomButton onClick={handleClick} className={classes.button} startIcon={<BiLogOutCircle color="red" />}>
                      {" "}
                      Log out{" "}
                    </CustomButton>
                  </Card>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
