// MAKE THIS FILE MORE REUSABLE
import React, { useState, useRef, useEffect, useContext } from "react";
// import { useHistory } from "react-router-dom";
import { NotificationContext } from "../../components/Notifications/NotificationContext";
import { makeStyles, IconButton, Button } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import { RiDeleteBin3Line, RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineFileImage, AiOutlineFileWord, AiOutlineFileExcel, AiFillFile, AiOutlineFileText } from "react-icons/ai";
import { FaRegFilePowerpoint } from "react-icons/fa";
import { VscFilePdf } from "react-icons/vsc";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { useParams } from "react-router-dom";
// import { useAuth0 } from "@auth0/auth0-react";
import { GET_TEACHERS,
  // GET_ADMINS,
  // GET_STUDENTS,
  DELETE_FILE,
  REMOVE_FILE,
} from "../../utils/graphql";
import { useMutation,
  // useQuery
} from "@apollo/client";
import CircleSpinner from "../../components/CircleSpinner/CircleSpinner";
import useGetTeachersQuery from "../../hooks/Users/useGetTeachersQuery";
import useGetStudentsQuery from "../../hooks/Users/useGetStudentsQuery";

const useStyles = makeStyles({
  root: {
    background: "#e9e9e9",
    padding: "5px",
    marginTop: "10px",
  },
  stagedFile: {
    display: "flex",
    justifyContent: "space-between",
    borderLeft: "3px solid #ff5e00",
    margin: "10px",
    padding: "10px",
    background: "#FFFFFF",
    fontSize: "12px",
    letterSpacing: "1px",
  },
  stagedFileTypeIcon: {
    fontSize: "25px",
    marginRight: "10px",
    color: "grey",
  },
  stagedFileDeleteRoot: {
    width: "20px",
    height: "20px",
  },
  stagedFileDelete: { color: "#ff5e00", fontSize: "20px" },
  dbFileDelete: { color: "#ff5e00", fontSize: "15px" },
  warning: {
    background: "#fffbdd",
    padding: "10px",
    textAlign: "center",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid #d3cfb7",
  },
  link: {
    textDecoration: "none",
    color: "black",
    "&:hover": {
      color: "#ff5e00",
    },
  },
});

function SingleFile({ file, index, handleRemoveFileFromStage }) {
  const classes = useStyles();

  const { createNotification } = useContext(NotificationContext);

  const {
    SUBJECT_ID,
    LESSON_ID,
    ASSIGNMENT_ID,
    ASSESSMENT_ID,
    // ORGANIZATION_ID,
    TEACHER_ID,
    STUDENT_ID,
  } = useParams();

  // states
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  // We have to check if the component is still mounted
  // before setting states in async fns
  const isMountedRef = useRef(true);
  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    []
  );

  const { teacher, getTeachersLoading } = useGetTeachersQuery({
    teacherId: TEACHER_ID || "",
  });

  const { student, getStudentsLoading } = useGetStudentsQuery({
    studentId: STUDENT_ID || "",
  });

  // Mutation
  const [removeFile, { loading: removeFileLoading }] = useMutation(
    REMOVE_FILE,
    {
      onCompleted: () => {
        console.log("File has been deleted from google cloud successfully");
      },
      onError: (err) => {},
    }
  );

  const [deleteFiles, { loading: deleteFilesLoading }] = useMutation(
    DELETE_FILE,
    {
      onCompleted: () => {
        createNotification({
          message: "File has been deleted",
          status: "warning",
        });
        console.log("File metadata has been deleted from neo4j successfully");
      },
      onError: (err) => {},
    }
  );

  if (getTeachersLoading || getStudentsLoading) return <CircleSpinner />;

  const subject = activeRoute("/teacher")
    ? teacher?.subjects.find((subject) => subject.subjectId === SUBJECT_ID) ||
      {}
    : activeRoute("/student")
    ? student?.subjects.find((subject) => subject.subjectId === SUBJECT_ID) ||
      {}
    : null;

  const lesson = subject?.lessons?.find(
    (lesson) => lesson?._id === LESSON_ID
  );

  const assignment = subject?.assignments?.find(
    (assignment) => assignment?._id === ASSIGNMENT_ID
  );

  const assessment = subject?.assessments?.find(
    (assessment) => assessment._id === ASSESSMENT_ID
  );

  // verifies if routeName is the one active (in browser input)
  // we use this fn to check if route contains /lesson , /assignment , /assessment
  // This allows us to conditionally apply delete logic depending on what we are
  // deleting(oneOF: lessonFiles, assessmentFiles, assignmentFiles)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }

  const switchFileTypeIcon = (type) => {
    switch (type) {
      case "application/pdf":
        return <VscFilePdf className={classes.stagedFileTypeIcon} />;
      case "text/plain":
        return <AiOutlineFileText className={classes.stagedFileTypeIcon} />;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return <AiOutlineFileWord className={classes.stagedFileTypeIcon} />;
      case "application/vnd.ms-excel":
        return <AiOutlineFileExcel className={classes.stagedFileTypeIcon} />;
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return <FaRegFilePowerpoint className={classes.stagedFileTypeIcon} />;
      case "image/png":
        return <AiOutlineFileImage className={classes.stagedFileTypeIcon} />;
      case "image/jpeg":
        return <AiOutlineFileImage className={classes.stagedFileTypeIcon} />;
      default:
        return <AiFillFile className={classes.stagedFileTypeIcon} />;
    }
  };

  const handleRemoveFileFromCloudAndNeo = () => {
    removeFile({
      variables: {
        gcName: file.gcName,
      },
    }).then(() => {
      deleteFiles({
        variables: {
          where: {
            fileId: file.fileId,
          },
        },
        update: (cache) => {
          const oldData = cache.readQuery({
            query: GET_TEACHERS,
            variables: {
              where: {
                teacherId: TEACHER_ID,
              },
            },
          });

          // Index of the cached subject that we are dealing with
          const cachedSubjectIdx = oldData.teachers[0].subjects.findIndex(
            (cacheSubject) => cacheSubject.subjectId === subject.subjectId
          );

          // cloned subjects data from cache
          const newSubjectsData = [...oldData.teachers[0].subjects];

          // ###############################################################
          //  This block of code will check what typedef is being edited in cache
          // ###############################################################

          if (activeRoute("/lesson")) {
            // Index of the cached subject lesson that we are dealing with
            const cachedLessonIdx = oldData.teachers[0].subjects[
              cachedSubjectIdx
            ].lessons.findIndex(
              (cacheLesson) => cacheLesson.lessonId === lesson?._id
            );

            // cloned lessons data from cache
            const newLessonsData = [
              ...oldData.teachers[0].subjects[cachedSubjectIdx].lessons,
            ];

            // new lesson file list after deleting one
            const newLessonFileList = newLessonsData[
              cachedLessonIdx
            ].files.filter((f) => f.fileId !== file.fileId);

            // injecting the new filelist in the cached lesson data at hand
            newLessonsData[cachedLessonIdx] = {
              ...newLessonsData[cachedLessonIdx],
              files: newLessonFileList,
            };

            // injecting the new lessons in the cached subject data
            newSubjectsData[cachedSubjectIdx] = {
              ...newSubjectsData[cachedSubjectIdx],
              lessons: newLessonsData,
            };

            // write newSubjectsData back to cache
            // (with respective file deleted from the lesson)
            cache.writeQuery({
              query: GET_TEACHERS,
              variables: {
                where: {
                  teacherId: TEACHER_ID,
                },
              },
              data: {
                ...oldData,
                teachers: [
                  {
                    ...oldData.teachers[0],
                    subjects: newSubjectsData,
                  },
                ],
              },
            });
          } else if (activeRoute("/assessment")) {
            const cachedAssessmentIdx = oldData.teachers[0].subjects[
              cachedSubjectIdx
            ].assessments.findIndex(
              (cacheAssessment) =>
                cacheAssessment.assessmentId === assessment.assessmentId
            );

            // cloned assessments data from cache
            const newAssessmentsData = [
              ...oldData.teachers[0].subjects[cachedSubjectIdx].assessments,
            ];

            // new file list after deleting one
            const newAssessmentFileList = newAssessmentsData[
              cachedAssessmentIdx
            ].files.filter((f) => f.fileId !== file.fileId);

            // injecting the new filelist in the cached assessment data at hand
            newAssessmentsData[cachedAssessmentIdx] = {
              ...newAssessmentsData[cachedAssessmentIdx],
              files: newAssessmentFileList,
            };

            // injecting the new assessments in the cached subject data
            newSubjectsData[cachedSubjectIdx] = {
              ...newSubjectsData[cachedSubjectIdx],
              assessments: newAssessmentsData,
            };

            // write newSubjectsData back to cache
            // (with respective file deleted from the assessment)
            cache.writeQuery({
              query: GET_TEACHERS,
              variables: {
                where: {
                  teacherId: TEACHER_ID,
                },
              },
              data: {
                ...oldData,
                teachers: [
                  {
                    ...oldData.teachers[0],
                    subjects: newSubjectsData,
                  },
                ],
              },
            });
          } else if (activeRoute("/assignment")) {
            const cachedAssignmentIdx = oldData.teachers[0].subjects[
              cachedSubjectIdx
            ].assignments.findIndex(
              (cacheAssignment) =>
                cacheAssignment.assignmentId === assignment.assignmentId
            );

            // cloned assignments data from cache
            const newAssignmentsData = [
              ...oldData.teachers[0].subjects[cachedSubjectIdx].assignments,
            ];

            // new file list after deleting one
            const newAssignmentFileList = newAssignmentsData[
              cachedAssignmentIdx
            ].files.filter((f) => f.fileId !== file.fileId);

            // injecting the new filelist in the cached assignments data at hand
            newAssignmentsData[cachedAssignmentIdx] = {
              ...newAssignmentsData[cachedAssignmentIdx],
              files: newAssignmentFileList,
            };

            // injecting the new assignments in the cached subject data
            newSubjectsData[cachedSubjectIdx] = {
              ...newSubjectsData[cachedSubjectIdx],
              assignments: newAssignmentsData,
            };

            // write newSubjectsData back to cache
            // (with respective file deleted from the lesson)
            cache.writeQuery({
              query: GET_TEACHERS,
              variables: {
                where: {
                  teacherId: TEACHER_ID,
                },
              },
              data: {
                ...oldData,
                teachers: [
                  {
                    ...oldData.teachers[0],
                    subjects: newSubjectsData,
                  },
                ],
              },
            });
          }
        },
      });
    });
  };

  // ############################################################################
  // Dialog handlers
  // ############################################################################
  const handleConfirmDialogOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  // TODO: Refactor this logic, too confusing (keep in mind that it affects how staged files are removed from the stage)
  return activeRoute("/teacher") ||
    activeRoute("/admin") ||
    // This last conditional allows the remove from stage icon to appear on applicants uploaded files before they are sent to server
    activeRoute("/careers") ? (
    <div className={classes.stagedFile}>
      <a className={classes.link} href={file.url} target="_blank" rel="noreferrer">
        <div style={{ display: "flex" }}>
          {switchFileTypeIcon(file.type)}
          <p style={{ lineHeight: "25px" }}>{file.name}</p>{" "}
        </div>
      </a>
      {handleRemoveFileFromStage ? (
        <IconButton
          onClick={() => {
            handleRemoveFileFromStage(index);
          }}
          className={classes.stagedFileDeleteRoot}
        >
          <RemoveIcon className={classes.stagedFileDelete} />
        </IconButton>
      ) : (
        <div>
          <Button
            onClick={handleConfirmDialogOpen}
            style={{ width: "40px", minWidth: "40px" }}
          >
            <RiDeleteBin6Line className={classes.dbFileDelete} />
          </Button>
          <ConfirmDialog
            open={confirmDialogOpen}
            handleClose={handleConfirmDialogClose}
            actionHandler={handleRemoveFileFromCloudAndNeo}
            actionBtnMessage="Delete this File"
            actionBtnMessageLoading={"Deleting file..."}
            dialogActionBtnLoading={removeFileLoading || deleteFilesLoading}
            warningActionIcon={
              <RiDeleteBin3Line
                fontSize="25"
                style={{ color: "rgb(255, 94, 0)" }}
              />
            }
            requireConfirmInputText={false}
            confirmMessageTitle="Are you sure you want to delete this file?"
            confirmMessage={
              <>
                <>
                  This action <strong>cannot</strong> be undone. The file{" "}
                  <strong>"{file.name}"</strong> will be permanently deleted.
                </>
              </>
            }
          />{" "}
        </div>
      )}
    </div>
  ) : activeRoute("/student") ? (
    <div className={classes.stagedFile}>
      <a className={classes.link} href={file.url} target="_blank" rel="noreferrer">
        <div style={{ display: "flex" }}>
          {switchFileTypeIcon(file.type)}
          <p style={{ lineHeight: "25px" }}>{file.name}</p>{" "}
        </div>
      </a>
      {handleRemoveFileFromStage ? (
        <IconButton
          onClick={() => {
            handleRemoveFileFromStage(index);
          }}
          className={classes.stagedFileDeleteRoot}
        >
          <RemoveIcon className={classes.stagedFileDelete} />
        </IconButton>
      ) : null}
    </div>
  ) : (
    <div className={classes.stagedFile}>
      <a className={classes.link} href={file.url} target="_blank" rel="noreferrer">
        <div style={{ display: "flex" }}>
          {switchFileTypeIcon(file.type)}
          <p style={{ lineHeight: "25px" }}>{file.name}</p>{" "}
        </div>
      </a>
    </div>
  );
}

export default SingleFile;
