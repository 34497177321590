import ReactDOM from "react-dom";
import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { TourProvider } from "@reactour/tour";
import App from "./App";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import AuthorizedApolloProvider from "./auth/authorized-apollo-provider";
import { NotificationProvider } from "./components/Notifications/NotificationContext";
import "./components/Internationalization/i18n";
import CircleSpinner from "./components/CircleSpinner/CircleSpinner";
import { Close } from "./components/UIOnboarding/Components/Components";
import OfflineTracker from "./components/OfflineTracker/OfflineTracker";

ReactDOM.render(
  <Suspense fallback={<CircleSpinner />}>
    <Router>
      <Auth0ProviderWithHistory>
        <AuthorizedApolloProvider>
          <NotificationProvider>
            <TourProvider
              scrollSmooth={true}
              disableFocusLock={false}
              components={{ Close }}
              styles={{
                maskWrapper: (base) => ({
                  ...base,
                  color: "black",
                }),
                badge: (base) => ({
                  ...base,
                  color: "white",
                  background: "#ff5e00",
                }),
                popover: (base) => ({
                  ...base,
                  background: "black",
                  borderRadius: "10px",
                  border: "1px solid #ff5e00",
                  paddingLeft: "20px",
                }),
              }}
            >
              <OfflineTracker />
              <App />
            </TourProvider>
          </NotificationProvider>
        </AuthorizedApolloProvider>
      </Auth0ProviderWithHistory>
    </Router>
  </Suspense>,

  document.getElementById("root")
);
