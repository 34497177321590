import { useMutation } from "@apollo/client";
import { DELETE_CUSTOMER } from "../../utils/graphql";

export default function useDeleteCustomer() {
  // [Mutation]DreateCustomer
  const [deleteCustomer, { loading: deleteCustomerLoading }] =
    useMutation(DELETE_CUSTOMER);

  return {
    deleteCustomer,
    deleteCustomerLoading,
  };
}
