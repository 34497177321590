import React from "react";
import { Button, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconBtn from "../../components/IconBtn/IconBtn";
import { AiOutlineArrowLeft } from "react-icons/ai";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    minHeight: "100vh",
  },
  outerCard: {
    width: "600px",
    padding: "30px",
    margin: "10px",
    textAlign: "center",
    borderRadius: "20px",
  },
  title: {
    justifyContent: "center",
    marginTop: "20px",
    marginBottom: "20px",
    fontSize: "50px",
  },
  subTitle: { color: "grey", marginBottom: "20px" },
  messageCard: {
    background: "#fffbdd",
    padding: "15px",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid #d3cfb7",
    borderLeft: "6px solid rgb(255, 94, 0)",
  },
  button: {
    width: "180px",
    color: "white",
    background: "#9076d1",
    marginLeft: "16px",
    marginTop: "10px",
    marginBottom: "16px",
    textTransform: "none",
    "&:hover": {
      background: "#604a9f",
    },
  },
  goHomeButton: {
    width: "180px",
    color: "white",
    background: "#ff903e",
    marginLeft: "16px",
    marginTop: "10px",
    marginBottom: "16px",
    textTransform: "none",
    "&:hover": {
      background: "#ff5e00",
    },
  },

  dangerButton: {
    width: "180px",
    fontWeight: "900",
    border: "1px solid #d3cfb7",
    color: "#ef5350",
    marginLeft: "16px",
    marginTop: "10px",
    marginBottom: "16px",
    textTransform: "none",
    "&:hover": {
      color: "white",
      background: "#E01815",
    },
  },
}));

function NotFoundPage() {
  const classes = useStyles();

  return (
    <div
      style={{
        backgroundImage: "linear-gradient(to right, #ff5e00, purple)",
        minHeight: "100vh",
        minWidth: "100vw",
      }}
    >
      <div className={classes.root}>
        <Card className={classes.outerCard}>
          <img
            src="https://res.cloudinary.com/teenkevo-cloud/image/upload/v1634712960/vlearned-beta/PngItem_2549756_vvfrf6.png"
            style={{ width: "200px", marginTop: "5px" }}
            alt="404"
          />
          <div className={classes.title}>Oops!</div>
          <p className={classes.subTitle}>
            We can't find the resource you were looking for. It was either
            moved, removed, renamed or it never existed
          </p>
          <IconBtn
            handleClick={() => window.history.go(-2)}
            icon={<AiOutlineArrowLeft style={{ padding: "2px" }} />}
          />
          <p style={{ fontSize: "14px", color: "grey" }}>Go back</p>
        </Card>
      </div>
    </div>
  );
}

export default NotFoundPage;
