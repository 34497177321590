import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Breadcrumbs,
  Button,
  Chip,
  Grid,
  hexToRgb,
  Paper,
} from "@material-ui/core";
import { IoIosArrowRoundForward, IoMdArrowRoundBack } from "react-icons/io";
import { useHistory, useParams } from "react-router-dom";
import { StyledBreadcrumb } from "../../components/StyledBreadCrumb/StyledBreadCrumb";
import { MdRocketLaunch } from "react-icons/md";
import { blackColor, grayColor } from "../../assets/jss/universalStyle";
import ReusableList from "../../components/HumanResource/ReusableList";
import {
  JOB_INFO_FRAGMENT,
  REMOVE_FILE,
  UPDATE_JOB,
  UPLOAD_FILE,
} from "../../utils/graphql";
import { gql, useMutation, useQuery } from "@apollo/client";
import CircleSpinner from "../../components/CircleSpinner/CircleSpinner";
import { NotificationContext } from "../../components/Notifications/NotificationContext";
import { applicantSchema } from "../../utils/Validations/applicantCreateValidation";
import { useFormik } from "formik";
import CustomDialog from "../../components/CustomDialog/CustomDialog";
import ApplicantCreator from "../../components/CreatorCard/ApplicantCreator";
import Navbar from "../../features/Landing/components/Navbar";

const useStyles = makeStyles((theme) => ({
  btn: {
    textTransform: "none",
    fontSize: "14px",
    background: "#ff5e00",
    color: "white",
    marginTop: "20px",
    "&:hover": {
      background: "#c42800",
    },
  },
  actionTxt: { fontSize: "14px", color: "#757575" },
  actionTxtDiv: {
    marginTop: "20px",
    display: "inline-flex",
    alignItems: "center",
  },
  actionTxtIcon: {
    fontSize: "15px",
    color: "grey",
    margin: "5px 5px 5px 0px",
  },
  subtitle: {
    fontSize: "14px",
    color: "#757575",
  },
  link: {
    color: "#ff5e00",
    textDecoration: "none",
  },
  linkText: { display: "inline-flex" },
  linkIcon: {
    fontSize: "15px",
    color: "#ff5e00",
    margin: "5px",
  },
  title: {
    fontSize: "50px",
    fontWeight: "bold",
    color: "white",
  },
  flex: {
    display: "flex",
  },
  borderedChip: {
    fontSize: "11px",
    color: "white",
    marginBottom: "20px",
    background: "black",
    border: "1px solid #ff5e00",
  },
  jobDetails: {
    marginTop: "20px",
  },
  jobDetail: {
    marginLeft: "10px",
    fontSize: "14px",
    color: "white",
    fontWeight: "500",
  },
  jobDescription: {
    fontSize: "14px",
    color: "black",
    lineHeight: "25px",
  },
  applicantsPaper: {
    padding: "10px",
    width: "140px",
    textAlign: "center",
    marginBottom: "20px",
    background: "purple",
    color: "white",
  },
  jobDetailIcon: {
    color: "#ff5e00",
    fontSize: "20px",
  },
  applyBtn: {
    color: "white",
    width: "140px",
    fontSize: "16px",
    padding: "10px",
    marginRight: "20px",
    textTransform: "none",
    background: "black",
    "&:hover": {
      background: "#c42800",
      boxShadow:
        "0 10px 20px -10px rgba(" +
        hexToRgb(grayColor[0]) +
        ", 0.2), 0 4px 10px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.3), 0 8px 10px -5px rgba(" +
        hexToRgb(grayColor[0]) +
        ", 0.3)",
    },
  },
  editBtn: {
    color: "white",
    fontSize: "16px",
    padding: "10px",
    marginRight: "20px",
    textTransform: "none",
    background: "black",
    "&:hover": {
      background: "#c42800",
      boxShadow:
        "0 10px 20px -10px rgba(" +
        hexToRgb(grayColor[0]) +
        ", 0.2), 0 4px 10px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.3), 0 8px 10px -5px rgba(" +
        hexToRgb(grayColor[0]) +
        ", 0.3)",
    },
  },
  divider: {
    color: "grey",
    marginTop: "30px",
    height: "2px",
  },
  mainPaper: {
    margin: "20px 0 0 0 ",
    paddingTop: "1px",
    borderRadius: "40px",
  },
  sectionHeading: {
    marginTop: "40px",
    marginBottom: "30px",
    fontSize: "30px",
    fontWeight: "bold",
  },
  header: {
    transparency: "20%",
    padding: "40px",
    margin: "10px",
    borderRadius: "30px",
    background: "-webkit-linear-gradient(45deg, purple 20%, #ff5e00 80%)",
  },
  content: {
    padding: "40px",
  },
}));

function JobAdPagePublic() {
  const classes = useStyles();
  const { JOB_ID } = useParams();

  const { createNotification } = useContext(NotificationContext);

  // states
  const [updaterDialogOpen, setUpdaterDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const GET_JOBS = gql`
    query Jobs($where: JobWhere, $options: JobOptions) {
      jobs(where: $where, options: $options) {
        ...JobInfo
      }
    }

    ${JOB_INFO_FRAGMENT}
  `;

  // get jobs
  const { loading: getJobsLoading, data: jobsData } = useQuery(GET_JOBS, {
    variables: {
      where: {
        id: JOB_ID,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const job = jobsData?.jobs[0];

  // [Mutation] update job
  const [updateJobs, { loading: updateJobsLoading }] = useMutation(UPDATE_JOB, {
    onCompleted: () => {
      handleUpdaterDialogClose();
      createNotification({
        message: "Your application has been submitted successfully",
        status: "success",
      });
    },
    refetchQueries: [
      {
        query: GET_JOBS,
        variables: {
          where: {
            id: JOB_ID,
          },
        },
      },
    ],
  });

  // [Mutation] upload file to fs
  const [uploadFile, { loading: uploadFileLoading }] = useMutation(UPLOAD_FILE);

  // [Mutation]delete file from google cloud
  const [removeFile, { loading: removeFileLoading }] = useMutation(REMOVE_FILE);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      github: "",
      linkedIn: "",
      website: "",
      location: "",
      whyWorkHere: "",
      whyFitRole: "",
    },
    onSubmit: ({
      firstName,
      middleName,
      lastName,
      email,
      phoneNumber,
      github,
      linkedIn,
      website,
      location,
      whyWorkHere,
      whyFitRole,
    }) => {
      setLoading(true);
      if (files.length === 0) {
        createNotification({
          message: "A Resume/CV is required to continue",
          status: "error",
        });
        setLoading(false);
      } else {
        // first upload the logo file and generate a gc url for neo4j
        Promise.all(
          files.map((file) => {
            return uploadFile({ variables: { file } }).then((res) => {
              return {
                node: {
                  url: res.data.UploadFile.url,
                  name: res.data.UploadFile.name,
                  type: res.data.UploadFile.type,
                  gcName: res.data.UploadFile.gcName,
                },
              };
            });
          })
        ).then((res) => {
          updateJobs({
            variables: {
              where: {
                id: JOB_ID,
              },
              create: {
                applicants: [
                  {
                    node: {
                      createdAt: new Date().toISOString(),
                      firstName,
                      middleName,
                      lastName,
                      email,
                      phoneNumber,
                      github,
                      linkedIn,
                      website,
                      location,
                      whyWorkHere,
                      whyFitRole,
                      files: {
                        create: res,
                      },
                    },
                  },
                ],
              },
            },
          })
            .then(() => {
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              createNotification({
                message: `Oops - Something went wrong`,
                status: "error",
              });
              // remove the organization logo from google cloud coz an error occured
              res
                .map((file) => {
                  return removeFile({
                    variables: {
                      gcName: file.node.gcName,
                    },
                  });
                })
                .then(() => {
                  setLoading(false);
                });
            });
        });
      }
    },
    validationSchema: applicantSchema,
  });

  // ############################################################################
  // Creator Dialog handlers
  // ############################################################################

  const handleUpdaterDialogClose = () => {
    setUpdaterDialogOpen(false);
  };

  const handleUpdaterDialogOpen = () => {
    setUpdaterDialogOpen(true);
    formik.resetForm();
  };

  // ############################################################################
  // File handling (files to be uploaded)
  // ############################################################################

  // Adding a file to the list of files to be uploaded
  const handleAddFilesToStage = (acceptedFiles) => {
    setFiles((prevFiles) => {
      if ([...prevFiles, ...acceptedFiles].length > 4) {
        createNotification({
          message: "A Resume/CV + a maximum of 3 extra files is allowed",
          status: "error",
        });
        return [...prevFiles];
      } else {
        return [...prevFiles, ...acceptedFiles];
      }
    });
    console.log(files);
  };

  // Handler for deleting a lesson file that has been staged(this fn is passed
  // down as a prop to the Lesson creator component)
  const handleRemoveFileFromStage = (selectedFileIdx) => {
    const filtered = files.filter((file, index) => index !== selectedFileIdx);
    setFiles(filtered);
  };

  if (
    loading ||
    getJobsLoading ||
    uploadFileLoading ||
    removeFileLoading ||
    updateJobsLoading
  )
    return <CircleSpinner />;

  return (
    <>
      <Navbar />
      <div style={{ marginTop: "100px", padding: "10px" }}>
        <Paper style={{ padding: "10px", marginBottom: "10px" }}>
          <Breadcrumbs
            separator={<IoIosArrowRoundForward fontSize="large" />}
            aria-label="breadcrumb"
          >
            <StyledBreadcrumb
              component="a"
              label="Go back to careers page"
              style={{ fontSize: "14px", fontWeight: "bold", padding: "10px" }}
              icon={
                <IoMdArrowRoundBack
                  fontSize="large"
                  style={{ color: "purple" }}
                />
              }
              onClick={() => window.history.back()}
            />
          </Breadcrumbs>
        </Paper>
        <Paper className={classes.mainPaper}>
          <div className={classes.header}>
            <Chip
              className={classes.borderedChip}
              label={job?.category.toUpperCase()}
            />
            <h1 className={classes.title}>{job?.title}</h1>
            <div className={classes.jobDetails}>
              <Grid alignItems="center" container spacing={0}>
                <Grid item xs={4} sm={2} md={2} lg={1}>
                  <div className={classes.flex}>
                    {/* <ImLocation2 className={classes.jobDetailIcon} /> */}
                    <p className={classes.jobDetail}>{job?.station}</p>
                  </div>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={1}>
                  <div className={classes.flex}>
                    {/* <BsFillClockFill className={classes.jobDetailIcon} /> */}
                    <p className={classes.jobDetail}>{job?.type}</p>
                  </div>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={1}>
                  <div className={classes.flex}>
                    {/* <ImLocation2 className={classes.jobDetailIcon} /> */}
                    <p className={classes.jobDetail}>{job?.level}</p>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={classes.content}>
            <Grid alignItems="center" container spacing={1}>
              <Grid item xs={6} sm={3} md={3} lg={2}>
                <Button
                  startIcon={
                    <MdRocketLaunch className={classes.jobDetailIcon} />
                  }
                  className={classes.applyBtn}
                  onClick={handleUpdaterDialogOpen}
                >
                  Apply Now
                </Button>
              </Grid>
            </Grid>

            {/* <Divider className={classes.divider} /> */}
            <p className={classes.sectionHeading}>Overview</p>
            <p className={classes.jobDescription}>{job?.description}</p>
            <p className={classes.sectionHeading}>
              What you'll be doing at Vlearned
            </p>
            <ReusableList list={job?.responsibilities} />
            <p className={classes.sectionHeading}>About you</p>
            <ReusableList list={job?.requirements} />
            <p className={classes.sectionHeading}>Benefits</p>
            <ReusableList list={job?.benefits} />
          </div>
        </Paper>
      </div>
      <CustomDialog
        title="Job Application Form"
        dialogOpen={updaterDialogOpen}
        handleDialogClose={handleUpdaterDialogClose}
        actionButtonLabel="Submit Application"
        actionButtonClickHandler={formik.handleSubmit}
        dialogActionBtnLoading={updateJobsLoading}
        actionBtnLoadingMsg="Submitting..."
      >
        <ApplicantCreator
          // typed inputs
          onChangeFirstName={formik.handleChange}
          onChangeMiddleName={formik.handleChange}
          onChangeLastName={formik.handleChange}
          onChangeEmail={formik.handleChange}
          onChangePhoneNumber={formik.handleChange}
          onChangeGithub={formik.handleChange}
          onChangeLinkedIn={formik.values.handleChange}
          onChangeWebsite={formik.handleChange}
          onChangeLocation={formik.handleChange}
          onChangeWhyWorkHere={formik.handleChange}
          onChangeWhyFitRole={formik.handleChange}
          //   values
          firstName={formik.values.firstName}
          middleName={formik.values.middleName}
          lastName={formik.values.lastName}
          email={formik.values.email}
          phoneNumber={formik.values.phoneNumber}
          github={formik.values.github}
          linkedIn={formik.values.linkedIn}
          website={formik.values.website}
          location={formik.values.location}
          whyWorkHere={formik.values.whyWorkHere}
          whyFitRole={formik.values.whyFitRole}
          // file upload stuff
          stagedFilesForUpload={files}
          handleAddFilesToStage={handleAddFilesToStage}
          handleRemoveFileFromStage={handleRemoveFileFromStage}
          // Error handling //
          // ...check if fields have been touched before throwing error
          firstNameError={
            formik.touched.firstName && Boolean(formik.errors.firstName)
          }
          middleNameError={
            formik.touched.middleName && Boolean(formik.errors.middleName)
          }
          lastNameError={
            formik.touched.lastName && Boolean(formik.errors.lastName)
          }
          emailError={formik.touched.email && Boolean(formik.errors.email)}
          phoneNumberError={
            formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
          }
          whyWorkHereError={
            formik.touched.whyWorkHere && Boolean(formik.errors.whyWorkHere)
          }
          whyFitRoleError={
            formik.touched.whyFitRole && Boolean(formik.errors.whyFitRole)
          }
          // ...helperTexts(error messages)
          firstNameHelperText={
            formik.touched.firstName && formik.errors.firstName
          }
          middleNameHelperText={
            formik.touched.middleName && formik.errors.middleName
          }
          lastNameHelperText={formik.touched.lastName && formik.errors.lastName}
          emailHelperText={formik.touched.email && formik.errors.email}
          phoneNumberHelperText={
            formik.touched.phoneNumber && formik.errors.phoneNumber
          }
          whyWorkHereHelperText={
            formik.touched.whyWorkHere && formik.errors.whyWorkHere
          }
          whyFitRoleHelperText={
            formik.touched.whyFitRole && formik.errors.whyFitRole
          }
          // ...onBlur prop to show errors more immediately
          onBlur={formik.handleBlur}
        />
      </CustomDialog>
    </>
  );
}

export default JobAdPagePublic;
