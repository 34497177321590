import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { roleConfig } from "../../utils/roleConfig";
import PropTypes from "prop-types";

import styles from "./styles";

const useStyles = makeStyles(styles);

export default function RoleCardLink({ children, user, role }) {
  const classes = useStyles();
  const roleInfo = roleConfig(user).find((item) => item.role === role);
  return (
    <Link
      style={{ textDecoration: "none" }}
      className={classes.roleCardLink}
      to={roleInfo.roleCardLink}
    >
      {children}
    </Link>
  );
}

RoleCardLink.propTypes = {
  user: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};
