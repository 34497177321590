import React from "react";
import { TextField, withStyles } from "@material-ui/core";

const ValidationTextField = withStyles({
  root: {
    fontFamily: `"Bai Jamjuree", sans-serif`,
  },
})(TextField);

function SharedTextField({
  id,
  type,
  autoFocus,
  disabled,
  autoComplete,
  defaultValue,
  onChange,
  className,
  error,
  onBlur,
  placeholder,
}) {
  return (
    <ValidationTextField
      fullWidth
      variant="outlined"
      id={id}
      disabled={disabled}
      type={type}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      defaultValue={defaultValue}
      onChange={onChange}
      className={className}
      error={error}
      onBlur={onBlur}
      placeholder={placeholder}
      inputProps={{
        style: { fontSize: "14px", fontFamily: `"Bai Jamjuree", sans-serif` },
      }}
    />
  );
}

export default SharedTextField;
