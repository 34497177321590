import { useAuth0 } from "@auth0/auth0-react";

export default function useAuth() {
  const { loginWithRedirect, logout, user } = useAuth0();

  function handleLogin() {
    loginWithRedirect();
  }

  function handleLogout() {
    logout({
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }

  function handleSignup() {
    loginWithRedirect({
      screen_hint: "signup",
    });
  }

  return {
    handleLogin,
    handleLogout,
    handleSignup,
    user,
  };
}
