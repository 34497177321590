// @flow
import * as React from "react";
import { Button, makeStyles } from "@material-ui/core";
import styles from "./styles";

const useStyles = makeStyles(styles);

type Props = {
  actionHandler: () => void,
  actionBtnMessage: string,
  actionBtnMessageLoading: string,
  dialogActionBtnLoading: boolean,
  handleClose: () => void,
  cancelMsg?: string,
};

export default function ActionButtons({
  actionHandler,
  actionBtnMessage,
  actionBtnMessageLoading,
  dialogActionBtnLoading,
  handleClose,
  cancelMsg,
}: Props): React.Node {
  const classes = useStyles();
  return (
    <>
      <Button className={classes.cancelButton} onClick={handleClose}>
        {cancelMsg ? cancelMsg : "Cancel"}
      </Button>
      <Button
        className={
          dialogActionBtnLoading
            ? classes.dangerButtonLoading
            : classes.dangerButton
        }
        onClick={actionHandler}
        disabled={dialogActionBtnLoading}
        autoFocus
      >
        {dialogActionBtnLoading ? actionBtnMessageLoading : actionBtnMessage}
      </Button>
    </>
  );
}
