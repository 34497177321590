import React from "react";
import Base from "./Base";
import { MdOutlineError } from "react-icons/md";

export default function GatewayTimeout() {
  return (
    <Base
      icon={<MdOutlineError style={{ fontSize: "100px" }} />}
      error="504: Gateway timeout"
      description="We're sorry that the server responsible for dealing with your request took too long to respond"
      btnIconMsg="Go back"
      handleClick={() => window.history.go(-2)}
    />
  );
}
