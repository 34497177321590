// @flow
import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";
import WarningCard from "./WarningCard";
import ConfirmMessage from "./ConfirmMessage";
import ConfirmInput from "./ConfirmInput";
import ActionButtons from "./ActionButtons";

type Props = {
  open: boolean,
  handleClose: () => void,
  actionHandler: () => void,
  actionBtnMessage: string,
  actionBtnMessageLoading: string,
  confirmMessage: React.Node,
  confirmMessageTitle: string,
  dialogActionBtnLoading: boolean,
  requireConfirmInputText: boolean,
  handleChangeConfirmInputText?: () => void,
  confirmInputText?: string,
  warningActionIcon: React.Node,
  cancelMsg?: string,
  warningActionMsg?: string,
};

export default function ConfirmDialog({
  open,
  handleClose,
  actionHandler,
  actionBtnMessage,
  actionBtnMessageLoading,
  confirmMessage,
  confirmMessageTitle,
  dialogActionBtnLoading,
  requireConfirmInputText,
  handleChangeConfirmInputText,
  confirmInputText,
  warningActionIcon,
  cancelMsg,
  warningActionMsg,
}: Props): React.Node {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" disableTypography>
        <p style={{ fontSize: "18px", fontWeight: "bold" }}>
          {confirmMessageTitle}
        </p>
      </DialogTitle>
      <DialogContent>
        <WarningCard
          warningActionIcon={warningActionIcon}
          warningActionMsg={warningActionMsg}
        />
        <ConfirmMessage
          confirmMessage={confirmMessage}
          requireConfirmInputText={requireConfirmInputText}
          confirmInputText={confirmInputText}
        />
        {requireConfirmInputText && (
          <ConfirmInput
            handleChangeConfirmInputText={handleChangeConfirmInputText}
          />
        )}
      </DialogContent>
      <DialogActions>
        <ActionButtons
          actionHandler={actionHandler}
          actionBtnMessage={actionBtnMessage}
          actionBtnMessageLoading={actionBtnMessageLoading}
          dialogActionBtnLoading={dialogActionBtnLoading}
          handleClose={handleClose}
          cancelMsg={cancelMsg}
        />
      </DialogActions>
    </Dialog>
  );
}
