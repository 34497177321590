import { Grid, makeStyles, Card } from "@material-ui/core";
import React from "react";
import styles from "./creatorCardStyle";
import { BsInfoSquareFill } from "react-icons/bs";
import SharedTextField from "../SharedTextField/SharedTextField";

const useStyles = makeStyles(styles);

export default function OrganizationCreatorDialogBody3(props) {
  const classes = useStyles();

  return (
    <div>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card
              style={{
                display: "flex",
                borderLeft: "3px solid #ff5e00",
                padding: "10px",
              }}
            >
              <BsInfoSquareFill
                style={{ marginRight: "10px" }}
                fontSize="15px"
                color="#ff5e00"
              />
              <p style={{ fontSize: "12px", color: "grey" }}>
                Required fields are marked with *
              </p>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <label className={classes.inputLabel}>Email *</label>
            <SharedTextField
              disabled
              fullWidth
              variant="outlined"
              id="email"
              type="email"
              autoFocus
              autoComplete="off"
              defaultValue={props.email}
              onChange={props.onChangeEmail}
              className={classes.input}
              error={props.emailError}
              onBlur={props.onBlur}
            />
            <p className={classes.errorMsg}>{props.emailHelperText}</p>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <label className={classes.inputLabel}>First name *</label>
            <SharedTextField
              fullWidth
              variant="outlined"
              id="firstName"
              type="text"
              // autoComplete="off"
              defaultValue={props.firstName}
              onChange={props.onChangeFirstName}
              className={classes.input}
              error={props.firstNameError}
              onBlur={props.onBlur}
            />
            <p className={classes.errorMsg}>{props.firstNameHelperText}</p>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <label className={classes.inputLabel}>Middle name</label>
            <SharedTextField
              fullWidth
              variant="outlined"
              id="middleName"
              type="text"
              defaultValue={props.middleName}
              onChange={props.onChangeMiddleName}
              className={classes.input}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <label className={classes.inputLabel}>Last name *</label>
            <SharedTextField
              fullWidth
              variant="outlined"
              id="lastName"
              type="text"
              defaultValue={props.lastName}
              onChange={props.onChangeLastName}
              className={classes.input}
              error={props.lastNameError}
              onBlur={props.onBlur}
            />
            <p className={classes.errorMsg}>{props.lastNameHelperText}</p>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <p style={{ fontSize: "12px", color: "grey", marginTop: "20px" }}>
              Your profile can be complemented with further information once
              you're in the app. Remember, the richer your profile, the better
              the experience.
            </p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
