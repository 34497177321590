import React, { useState, useEffect } from "react";
import { NotificationContext } from "../Notifications/NotificationContext";

const OfflineTracker = () => {
  const { createNotification } = React.useContext(NotificationContext);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      console.log("online");
      createNotification({
        message: "You are back online",
        status: "success",
      });
    };

    const handleOffline = () => {
      setIsOnline(false);
      console.log("offline");
      createNotification({
        message: "Oops! you went offline",
        status: "error",
      });
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Clean up event listeners
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return <></>;
};

export default OfflineTracker;
