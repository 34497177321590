import React, { useState, useEffect } from "react";
import { Grid, Card, Typography, Button, TextField, CircularProgress } from "@material-ui/core";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { RiDeleteBin3Line } from "react-icons/ri";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import { useTranslation, Trans } from "react-i18next";
import { NotificationContext } from "../../components/Notifications/NotificationContext";

const CourseManagement = () => {
    const api_link = process.env.REACT_APP_API_LINK_VLEARNED;
    const [subjects, setSubjects] = useState([]);
    const [originalSubjects, setOriginalSubjects] = useState([]);
    const { createNotification } = React.useContext(NotificationContext);

    const { USER_ID } = useParams();
    const history = useHistory();

    const getSubjects = async () => {
        await axios.get(`${api_link}/subjects`)
            .then((response) => {
                setSubjects(response.data.data);
                setOriginalSubjects(response.data.data);
                console.log(response.data.data)
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleSearch = (event) => {
        const keyword = event.target.value.toLowerCase();
        if (keyword === "") {
            setSubjects(originalSubjects);
        } else {
            const filtered = originalSubjects.filter((organization) =>
                organization.title.toLowerCase().includes(keyword)
            );
            setSubjects(filtered);
        }
    };

    const [deleteOrganization, setDeleteOrganization] = useState(false);
    const handleCloseDailog = () => {
        setDeleteOrganization(false)
    }

    const [orgName, setOrgName] = useState('');
    const [orgId, setOrgId] = useState('');
    const handleDeleteion = (title, orgid) => {
        setDeleteOrganization(true)
        setOrgName(title);
        setOrgId(orgid);
    }

    const handleDeleteOrganization = async () => {
        await axios.delete(`${api_link}/organizations/delete-organization/${orgId}`).then((response) => {
            createNotification({
                message: `${orgName} deleted successfully`,
                status: "error",
            });
            setDeleteOrganization(false);
            getSubjects();
            console.log(response.data, orgId)
        }).catch((error) => {
            console.log(error)
        });
    }

    useEffect(() => {
        setTimeout(() => <CircularProgress size={24} />, 2000)
        getSubjects();
    }, []);

    return (
        <Grid container xs={12}>
            <Typography variant="h4" style={{ color: 'white' }}>Subjects Management</Typography>

            <Grid container spacing={3} style={{ padding: 2, marginTop: 40 }}>
                <Grid xs={12} style={{ backgroundColor: '#fff', borderRadius: 4, marginBottom: 20 }}>
                    <TextField type='search' id="outlined-basic" variant="outlined" className='w-100' onChange={handleSearch} style={{ width: '100%' }} placeholder="Search subjects"/>
                </Grid>
                {subjects.map((subject, index) => {
                    return (
                        <Grid key={index} item xs={12} sm={6} md={3}>
                            <Card style={{ padding: '40px 0px 30px 0px', textAlign: 'center' }}>
                                <Grid style={{ justifyContent: 'center', display: 'flex', flexWrap: 'wrap'  }}>
                                    {/* <img src={organization?.logoUrl} style={{ borderRadius: '100%', width: 100, height: 100, backgroundColor: 'grey' }} alt={organization?.title[0]} /> */}
                                    <p style={{ borderRadius: '100%', border: '2px double orange', width: 100, height: 100, backgroundColor: 'grey', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 28 }}>{subject?.name[0]}</p>
                                    <Typography variant="h5" style={{ width: '100%', marginTop: 20 }}>{subject?.name}</Typography>
                                    <Typography variant="subtitle2" style={{ width: '100%', marginTop: 4, color: '#fd8520' }}>{subject?.organizationId.title}</Typography>
                                </Grid>
                                <Grid spacing={2} xs={6} sm={12} style={{ marginTop: 10, justifyContent: 'center' }}>
                                    <Button onClick={() => history.push(`/super-admin/${USER_ID}/organization-management/${subject?._id}`)} variant="contained" color="primary" style={{ marginRight: 4 }}>View</Button>
                                    {/* <Button variant="contained" color="info" style={{ marginRight: 4 }}>Suspend</Button> */}
                                    <Button variant="contained" color="secondary" style={{ marginRight: 4 }} onClick={() => handleDeleteion(subject?.name, subject?._id)}>Delete</Button>
                                </Grid>
                            </Card>
                        </Grid>
                    );
                })}

                {subjects.length === 0 && <Grid style={{ backgroundColor: '#e96364', width: '100%', padding: 10, textAlign: 'center', color: 'white', marginTop: 20 }}><Typography variant="h6">No organizations found</Typography></Grid>}
            </Grid>

            <ConfirmDialog
                open={deleteOrganization}
                handleClose={handleCloseDailog}
                actionHandler={handleDeleteOrganization}
                actionBtnMessage={"Confirmation deletion"}
                // handleChangeConfirmInputText={orgName}
                confirmMessage={<Trans i18nKey="Organization details and users will be deleted"> This action <strong>cannot</strong> be undone. This will permanently delete this <b>{orgName}</b> organization</Trans>}
                warningActionIcon={
                <RiDeleteBin3Line fontSize="25" style={{ color: "rgb(255, 94, 0)" }} />
                }
                requireConfirmInputText={false}
                confirmMessageTitle={"Are you sure you want to continue?"}
            />
        </Grid>
    );
}

export default CourseManagement;
