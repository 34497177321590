import React from "react";
import PropTypes from "prop-types";

export default function RoleOrganizations({ participatingOrgs, role }) {
  return (
    <p style={{ marginTop: "10px", fontSize: "14px" }}>
      In {participatingOrgs(role)}{" "}
      {participatingOrgs(role) === 1 ? "organization" : "organizations"}
    </p>
  );
}

RoleOrganizations.propTypes = {
  participatingOrgs: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
};
