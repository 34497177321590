const organizationCardStyle = () => ({
  typeCard: {
    display: "flex",
    padding: "10px",
    textAlign: "center",
    justifyContent: "center",
    background: "#ff5e00",
  },
  bigButton: {
    display: "block",
    width: "100%",
    padding: "1px",
    transition: "200ms",
    marginBottom: "20px",
    "&:hover": {
      transform: "translate(0, -4px)",
      boxShadow: " 0px 0px 10px 2px rgba(0,0,0,0.5)",
    },
    textTransform: "none",
  },
  thingToCreate: {
    // textAlign: "left",
    marginTop: "5px",
    marginBottom: "5px",
  },
  gridContainer: {
    // display: "inline-grid",
    // "grid-template-columns": "2fr 2fr 2fr",
    gridGap: 2,
    alignSelf: "left",
  },
  divider: {
    marginTop: "20px",
    marginBottom: "20px",
    height: "2px",
    background: "#444f62",
  },
  ul: {
    marginLeft: "10px",
    paddingRight: "20px",
  },
  p: {
    fontSize: "17px",
    color: "white",
    fontWeight: "300",
  },
});

export default organizationCardStyle;
