import { useQuery } from "@apollo/client";
import { GET_STUDENTS } from "../../utils/graphql";

export default function useGetStudentsQuery(whereParams, query, onCompleted) {
  const fn = () => {
    return;
  };
  const { loading: getStudentsLoading, data: studentsData } = useQuery(
    query || GET_STUDENTS,
    {
      variables: {
        where: whereParams,
      },
      onCompleted: onCompleted || fn,
    }
  );

  // get the currently logged in student
  const student = studentsData && studentsData.students[0];

  const hasStudentProfile = student?.firstName ? true : false;

  return {
    student,
    hasStudentProfile,
    getStudentsLoading,
  };
}
