const styles = () => ({
  buttonPrimary: {
    color: "white",
    fontSize: "14px",
    background: "#F5811E",
    paddingRight: "20px",
    paddingLeft: "20px",
    borderRadius: "20px",
    textTransform: "none",
    transition: "0.3s", // Add a transition of 0.3 seconds
    "&:hover": {
      background: "#ff5e00",
      // Adjust the endIcon style when hovered
      "& .MuiButton-endIcon": {
        transition: "0.3s",
        transform: "translateX(3px)", // Example: rotate the endIcon
      },
    },
  },
  buttonSecondary: {
    color: "white",
    fontSize: "14px",
    border: "2px solid #F5811E",
    paddingRight: "20px",
    paddingLeft: "20px",
    borderRadius: "20px",
    textTransform: "none",
    transition: "0.3s", // Add a transition of 0.3 seconds
    "&:hover": {
      background: "#ff5e00",
      // Adjust the endIcon style when hovered
      "& .MuiButton-endIcon": {
        transition: "0.3s",
        transform: "translateX(3px)", // Example: rotate the endIcon
      },
    },
  },
  logoButton: {
    textTransform: "none",
    borderRadius: "10px",
    padding: "10px",
    fontSize: "14px",
    marginRight: "10px",
  },
  roundButtonWrapper: {
    margin: "30px",
  },
  roundButtonInfoText: { fontSize: "14px", color: "grey" },
});

export default styles;
