export const navButtons = [
  {
    title: "Corporate",
    href: "https://www.corporate.vlearned.com/",
    action: "link",
  },
  {
    title: "Support",
    href: "https://vlearned.uvdesk.com/apps/form-builder/en/form/html/130326399e0a29119d6399e0a29124a",
    action: "link",
  },
  {
    title: "Documentation",
    href: "https://docs.vlearned.com/",
    action: "link",
  },
  {
    title: "Careers",
    href: "/careers",
    action: "route",
  },
  // {
  //   title: "Login",
  //   href: "",
  //   action: "login",
  // },
  {
    title: "Login",
    href: "/login",
    action: "route",
  },
  {
    title: "Try for free",
    href: "/register",
    action: "signup",
  },
];
