import { useAuth0 } from "@auth0/auth0-react";

export default function useGetAuthUser() {
    const { user, logout, loading, loginWithRedirect } = useAuth0();

    return {
        user,
        logout,
        loading,
        loginWithRedirect
    }
}


