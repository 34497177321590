import React, { useState } from "react";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  from,
} from "@apollo/client";
import { useHistory } from "react-router-dom";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";
import CircleSpinner from "../components/CircleSpinner/CircleSpinner";

const AuthorizedApolloProvider = ({ children }) => {
  const { getAccessTokenSilently, isLoading, isAuthenticated } = useAuth0();
  const history = useHistory();

  const isTokenExpired = () => {
    const expiryDate = sessionStorage.getItem("expiryDate");
    const now = new Date();
    const expiry = new Date(expiryDate);
    return now > expiry;
  };

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions }) => {
        if (
          extensions.code === "GRAPHQL_VALIDATION_FAILED" ||
          extensions.code === "BAD_USER_INPUT"
        ) {
          history.push("/400");
        }
      });
    }
    if (networkError) {
      if (networkError.statusCode === 504) {
        history.push("/504");
      }
    }
  });

  const httpLink = createUploadLink({
    uri: process.env.REACT_APP_GRAPHQL_URI || "/graphql",
  });
  const cache = new InMemoryCache();

  let link = from([errorLink, httpLink]);
  if (isAuthenticated) {
    const authLink = setContext(async (_, { headers }) => {
      const token = sessionStorage.getItem("token");
      if (token && !isTokenExpired()) {
        return {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
            "Apollo-Require-Preflight": "true",
          },
        };
      } else {
        const fetchedToken = await getAccessTokenSilently({
          cacheMode: "off",
        });
        const fetchedExpiryDate = new Date(new Date().getTime() + 3600 * 1000); // 1 hour expiry

        sessionStorage.setItem("token", fetchedToken);
        sessionStorage.setItem("expiryDate", fetchedExpiryDate);

        return {
          headers: {
            ...headers,
            Authorization: `Bearer ${fetchedToken}`,
          },
        };
      }
    });
    link = from([authLink, errorLink, httpLink]);
  }

  const apolloClient = new ApolloClient({
    link,
    connectToDevTools: true,
    cache,
  });

  if (isLoading) return <CircleSpinner />;
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
