const styles = () => ({
  wrapperCardDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    minHeight: "100vh",
  },
  wrapperCard: {
    width: "600px",
    padding: "30px",
    margin: "10px",
    textAlign: "center",
    borderRadius: "20px",
  },
  authStatus: {
    justifyContent: "center",
    marginBottom: "10px",
    fontSize: "24px",
  },
  authStatusDescription: {
    color: "grey",
    margin: "25px 0 40px 0",
    lineHeight: "24px",
    fontSize: "16px",
  },
  authorizedIcon: { color: "green", fontSize: "60px" },
  unauthorizedIcon: { color: "red", fontSize: "60px" },
});

export default styles;
