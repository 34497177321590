import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import { HiOutlineCube } from "react-icons/hi";
import { v4 as uuid } from "uuid";
import { List } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },

  icon: {
    fontSize: "25px",
    color: "#ff5e00",
  },

  itemText: {
    fontSize: "14px",
  },
  itemIconRoot: {
    minWidth: "40px",
  },
}));

export default function ReusableList({ list }) {
  const classes = useStyles();

  const items = list.map((item) => (
    <ListItem key={uuid()} disableGutters>
      <ListItemIcon className={classes.itemIconRoot}>
        <HiOutlineCube className={classes.icon} />
      </ListItemIcon>
      <ListItemText disableTypography>
        <p className={classes.itemText}>{item}</p>
      </ListItemText>
    </ListItem>
  ));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <div className={classes.demo}>
          <List>{items}</List>
        </div>
      </Grid>
    </Grid>
  );
}
