import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, makeStyles } from "@material-ui/core";
import { IoIosArrowDropright } from "react-icons/io";

import { navButtons } from "../../utils/navButtons";
import styles from "./styles";
// import CustomButton from "../../../../components/Buttons/CustomButton";

import Cookies from 'js-cookie';

const useStyles = makeStyles(styles);

function NavButtonGroup({ actions }) {
  // hooks
  const classes = useStyles();
  
  const userDetailsString = Cookies.get('user_details');
  const userDetails = userDetailsString ? JSON.parse(userDetailsString) : null;

  // useEffect(() => {
  //   console.log('Current user data: ', userDetails._id)
  // }, [userDetailsString])

  // actions
  const { handleCloseDrawer, handleLogin, handleSignup } = actions;

  // nav buttons
  const navButtonGroup = navButtons.map((btn, i) =>
    btn.action === "link" ? (
      <Button
        key={i}
        target="_blank"
        href={btn.href}
        className={classes.hoverButtonLink}
        onClick={handleCloseDrawer}
      >
        {btn.title}
      </Button>
    ) : btn.action === "route" ? (
      <Button
        key={i}
        component={Link}
        to={btn.href}
        className={classes.hoverButtonLink}
        onClick={handleCloseDrawer}
      >
        {btn.title}
      </Button>
    ) : btn.action === "login" && (!userDetails || !userDetails.email) ? (
      <Button key={i} onClick={handleLogin} className={classes.hoverButtonLink}>
        {btn.title}
      </Button>
    ) : btn.action === "signup" ? (
      <Button
      key={i}
      endIcon={<IoIosArrowDropright />}
      className={classes.callForActionButtonPrimary2}
      onClick={handleSignup}
    >
      {btn.title}
    </Button>
    ) : userDetails && userDetails.email ? (
      <span key={i}>Logged in</span>
    ) : null
  );

  return navButtonGroup;
}

export default NavButtonGroup;
