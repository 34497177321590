import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import gql from "graphql-tag";
import {
  authorizedElement,
  unauthorizedElement,
} from "../../features/Auth/utils/authConfig";

export default function useGetParticipation() {
  const { user } = useAuth0();

  const roles = user[process.env.REACT_APP_AUTH0_ROLES_NAMESPACE];

  // check if the logged in user has a specific role in Auth0
  const hasTeacherRole = () =>
    roles && roles.some((role) => role === "Teacher");

  const hasAdminRole = () =>
    roles && roles.some((role) => role === "Administrator");

  const hasStudentRole = () =>
    roles && roles.some((role) => role === "Student");

  const GET_PARTICIPATION = gql`
    query getParticipation($userId: String!) {
      getParticipation(userId: $userId) {
        adminOrganizationsLength
        studentOrganizationsLength
        teacherOrganizationsLength
      }
    }
  `;

  // user participation in organizations
  const { loading: getParticipationLoading, data } = useQuery(
    GET_PARTICIPATION,
    {
      variables: {
        userId: user?.sub,
      },
    }
  );

  const adminOrgs = data?.getParticipation?.adminOrganizationsLength;
  const teacherOrgs = data?.getParticipation?.teacherOrganizationsLength;
  const studentOrgs = data?.getParticipation?.studentOrganizationsLength;

  const showAdminRole = hasAdminRole() && adminOrgs !== 0;
  const showTeacherRole = hasTeacherRole() && teacherOrgs !== 0;
  const showStudentRole = hasStudentRole() && studentOrgs !== 0;

  const authorized = showAdminRole || showTeacherRole || showStudentRole;

  const activeRoles = [];

  const showRole = () => {
    showAdminRole && activeRoles.push("Administrator");
    showTeacherRole && activeRoles.push("Teacher");
    showStudentRole && activeRoles.push("Student");
  };

  showRole();

  const organizationsInActiveRole = (role) => {
    if (role === "Administrator") {
      return adminOrgs;
    } else if (role === "Teacher") {
      return teacherOrgs;
    } else if (role === "Student") {
      return studentOrgs;
    }
  };

  const authStatus = authorized
    ? authorizedElement.status
    : unauthorizedElement.status;

  const authStatusDescription = authorized
    ? authorizedElement.description
    : unauthorizedElement.description;

  const icon = authorized ? authorizedElement.icon : unauthorizedElement.icon;

  return {
    authorized,
    activeRoles,
    organizationsInActiveRole,
    getParticipationLoading,
    meta: {
      authStatus,
      authStatusDescription,
      icon,
    },
  };
}
