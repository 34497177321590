import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, Divider, Collapse, Grid } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import SchoolIcon from '@material-ui/icons/School';
import AssessmentIcon from '@material-ui/icons/Assessment';
import BusinessIcon from '@material-ui/icons/Business';
import Cookies from 'js-cookie'

const useStyles = makeStyles((theme) => ({
    sidebar: {
        width: 290,
        backgroundColor: '#111b29',
        height: '100vh',
        position: 'fixed',
        top: 80,
        left: 0,
        zIndex: 1000,
        overflowY: 'auto',
        paddingTop: 30,
        transition: 'transform 0.3s ease',
        [theme.breakpoints.down('sm')]: {
            position: 'fixed',
            width: '82%',
            height: '100vh',
            top: 80,
            left: 0,
            top: 80,
            zIndex: 1001,
        },
    },
    mainPanel: {
        marginLeft: 290,
        transition: 'margin 0.3s ease',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        },
    },
    mainPanelShift: {
        marginLeft: 0,
    },
    sidebarHidden: {
        transform: 'translateX(-100%)',
    },
    link: {
        textDecoration: 'none',
        color: '#fff',
        '&.active': {
            color: '#f07e1d',
            borderRight: '2px solid #f07e1d',
        },
    },
    nested: {
        paddingLeft: theme.spacing(4),
        color: '#f2f2f2',
        fontSize: '14px',
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    floatingButton: {
        position: 'fixed',
        bottom: 80,
        right: 20,
        backgroundColor: '#f07e1d',
        color: '#fff',
        zIndex: 1001,
    },
    marginb: {
        marginBottom: theme.spacing(1),
    },
    logout: {
        color: 'white',
        backgroundColor: 'red',
        borderRadius: 4,
        padding: 10,
        textAlign: 'center',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        fontSize: 14,
    },
    userInfo: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
        backgroundColor: '#1a2530',
        color: '#fff',
        marginBottom: 20
    },
    userImage: {
        borderRadius: '50%',
        width: 50,
        height: 50,
        marginRight: theme.spacing(2),
        backgroundColor: 'grey',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 24,
        fontWeight: 'bold'
    },
}));

const Sidebar = ({showSidebar, USER_ID}) => {
    const classes = useStyles();
    const location = useLocation();

    const [openOrganization, setOpenOrganization] = useState(false);
    const [openCourse, setOpenCourse] = useState(false);
    const [openUsers, setOpenUsers] = useState(false);
    // const [showSidebar, setShowSidebar] = useState(false);
    const [user, setUser] = useState();
    const handleToggleOrganization = () => {
        setOpenOrganization(!openOrganization);
    };

    const handleToggleCourse = () => {
        setOpenCourse(!openCourse);
    };

    const handleToggleUsers = () => {
        setOpenUsers(!openUsers);
    };

    // const toggleSidebar = () => {
    //     setShowSidebar(!showSidebar);
    // };

    useEffect(() => {
        const user_logged_in = JSON.parse(Cookies.get('user_details'));
        setUser(user_logged_in);
    }, []);

    // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // useEffect(() => {
    //     if (!isMobile) {
    //         setShowSidebar(true);
    //     }
    // }, [isMobile]);

    return (
        <Grid container>
            {/* Sidebar */}
            <div className={`${classes.sidebar} ${!showSidebar ? classes.sidebarHidden : ''}`}>
                <div className={classes.userInfo}>
                    <div className={classes.userImage}>{user?.name[0]}</div>
                    <span>{user?.name}</span>
                </div>

                <List>
                    <ListItem button component={Link} to={`/super-admin/${USER_ID}/dashboard`} className={location.pathname === `/super-admin/${USER_ID}/dashboard` ? 'active' : ''}>
                        <span style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                            <DashboardIcon className={classes.link} />{" "}
                            <ListItemText className={[classes.marginb, classes.link]} primary="Dashboard" />
                        </span>
                    </ListItem>

                    <ListItem button onClick={handleToggleOrganization} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                            <BusinessIcon className={classes.link} />{" "}
                            <ListItemText className={classes.link} primary="Organization Management" />
                        </span>
                        {openOrganization ? <ExpandMoreIcon className={[classes.link, classes.icon]} /> : <ChevronRightIcon className={[classes.link, classes.icon]} />}
                    </ListItem>
                    <Collapse in={openOrganization} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button component={Link} to={`/super-admin/${USER_ID}/organization-management`} className={location.pathname === `/super-admin/${USER_ID}/org-list` ? 'active' : ''}>
                                <span className={classes.nested} style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                                    <PeopleIcon className={classes.link} />{" "}
                                    <ListItemText className={classes.link} primary="Organization List" />
                                </span>
                            </ListItem>
                            <ListItem button component={Link} to={`/super-admin/${USER_ID}/org-create`} className={location.pathname === `/super-admin/${USER_ID}/org-create` ? 'active' : ''}>
                                <span className={classes.nested} style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                                    <PeopleIcon className={classes.link} />{" "}
                                    <ListItemText className={classes.link} primary="Create Organization" />
                                </span>
                            </ListItem>
                            <ListItem button component={Link} to={`/super-admin/${USER_ID}/org-create`} className={location.pathname === `/super-admin/${USER_ID}/org-create` ? 'active' : ''}>
                                <span className={classes.nested} style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                                    <PeopleIcon className={classes.link} />{" "}
                                    <ListItemText className={classes.link} primary="Subscribed Organization" />
                                </span>
                            </ListItem>
                        </List>
                    </Collapse>

                    <ListItem button onClick={handleToggleCourse} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                            <SchoolIcon className={classes.link} />{" "}
                            <ListItemText className={[classes.marginb, classes.link]} primary="Course Management" />
                        </span>
                        {openCourse ? <ExpandMoreIcon className={[classes.link, classes.icon]} /> : <ChevronRightIcon className={[classes.link, classes.icon]} />}
                    </ListItem>
                    <Collapse in={openCourse} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button component={Link} to={`/super-admin/${USER_ID}/course-management`} className={location.pathname === `/super-admin/${USER_ID}/course-list` ? 'active' : ''}>
                                <span className={classes.nested} style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                                    <SchoolIcon className={classes.link} />{" "}
                                    <ListItemText className={classes.link} primary="Course List" />
                                </span>
                            </ListItem>
                            <ListItem button component={Link} to={`/super-admin/${USER_ID}/course-create`} className={location.pathname === `/super-admin/${USER_ID}/course-create` ? 'active' : ''}>
                                <span className={classes.nested} style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                                    <SchoolIcon className={classes.link} />{" "}
                                    <ListItemText className={classes.link} primary="Create Course" />
                                </span>
                            </ListItem>
                        </List>
                    </Collapse>

                    <ListItem button onClick={handleToggleUsers} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                            <PeopleIcon className={classes.link} />{" "}
                            <ListItemText className={[classes.marginb, classes.link]} primary="User Management" />
                        </span>
                        {openUsers ? <ExpandMoreIcon className={[classes.link, classes.icon]} /> : <ChevronRightIcon className={[classes.link, classes.icon]} />}
                    </ListItem>
                    <Collapse in={openUsers} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button component={Link} to={`/super-admin/${USER_ID}/users-management`} className={location.pathname === `/super-admin/${USER_ID}/user-list` ? 'active' : ''}>
                                <span className={classes.nested} style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                                    <PeopleIcon className={classes.link} />{" "}
                                    <ListItemText className={classes.link} primary="User List" />
                                </span>
                            </ListItem>
                            <ListItem button component={Link} to={`/super-admin/${USER_ID}/user-create`} className={location.pathname === `/super-admin/${USER_ID}/user-create` ? 'active' : ''}>
                                <span className={classes.nested} style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                                    <PeopleIcon className={classes.link} />{" "}
                                    <ListItemText className={classes.link} style={{ fontSize: 14 }} primary="Create User" />
                                </span>
                            </ListItem>
                            <ListItem button component={Link} to={`/super-admin/${USER_ID}/instructor-management`} className={location.pathname === `/super-admin/${USER_ID}/user-create` ? 'active' : ''}>
                                <span className={classes.nested} style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                                    <PeopleIcon className={classes.link} />{" "}
                                    <ListItemText className={classes.link} style={{ fontSize: 14 }} primary="Teacher" />
                                </span>
                            </ListItem>
                            <ListItem button component={Link} to={`/super-admin/${USER_ID}/students-management`} className={location.pathname === `/super-admin/${USER_ID}/user-create` ? 'active' : ''}>
                                <span className={classes.nested} style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                                    <PeopleIcon className={classes.link} />{" "}
                                    <ListItemText className={classes.link} style={{ fontSize: 14 }} primary="Students" />
                                </span>
                            </ListItem>
                        </List>
                    </Collapse>

                    <ListItem button component={Link} to={`/super-admin/${USER_ID}/reports`} className={location.pathname === `/super-admin/${USER_ID}/reports` ? 'active' : ''}>
                        <span style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                            <AssessmentIcon className={classes.link} />{" "}
                            <ListItemText className={[classes.marginb, classes.link]} primary="Reports" />
                        </span>
                    </ListItem>

                    <Divider />
                    <ListItem button component={Link} to={`/super-admin/${USER_ID}/logout`} style={{ color: 'red' }}>
                        <ListItemText className={[classes.marginb, classes.logout]} primary="Logout" />
                    </ListItem>
                </List>
            </div>
        </Grid>
    );
};

export default Sidebar;