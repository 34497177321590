import { useMutation } from "@apollo/client";
import { ASSIGN_ROLES_TO_USER } from "../../utils/graphql";

export default function useAssignRolesToUser() {
  // [Mutation]remove roles from user
  const [assignRolesToUser, { loading: assignRolesToUserLoading }] =
    useMutation(ASSIGN_ROLES_TO_USER);

  // assignUserRole
  const assignUserRole = (authRoles, userId, roleName) => {
    const roleToBeAssigned = authRoles.find(
      (role) => role.name === roleName
    ).id;
    return assignRolesToUser({
      variables: { roleIds: [roleToBeAssigned], userId },
    });
  };

  return {
    assignUserRole,
    assignRolesToUserLoading,
  };
}
