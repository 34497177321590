import { BsCheckCircle, BsFillExclamationSquareFill } from "react-icons/bs";

const authConfig = [
  {
    status: "Authorized",
    description: "Reason: Your account is associated with these roles",
    icon: <BsCheckCircle style={{ fontSize: "60px", color: "green" }} />,
  },
  {
    status: "Unauthorized",
    description: "Reason: Account is not enrolled into any oganization",
    icon: (
      <BsFillExclamationSquareFill style={{ fontSize: "60px", color: "red" }} />
    ),
  },
];

// Access the element with status "Authorized"
export const authorizedElement = authConfig.find(
  (item) => item.status === "Authorized"
);

// Access the element with status "Unauthorized"
export const unauthorizedElement = authConfig.find(
  (item) => item.status === "Unauthorized"
);
