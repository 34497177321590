import React from "react";
import { Card, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

import styles from "./styles";
import { roleConfig } from "../../utils/roleConfig";
import useAuth from "../../../../hooks/Authentication/useAuth";

const useStyles = makeStyles(styles);

export default function RoleCard({ role }) {
  const classes = useStyles();

  const { user } = useAuth();

  const roleInfo = roleConfig(user).find((item) => item.role === role);

  return (
    <Card elevation={3} className={classes.roleCard}>
      {roleInfo && <roleInfo.icon className={classes.roleIcon} />}
      <p className={classes.roleText}>{roleInfo?.label}</p>
    </Card>
  );
}

RoleCard.propTypes = {
  role: PropTypes.string.isRequired,
};
