import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";
import { BiReset } from "react-icons/bi";
import { BsCheck2Circle } from "react-icons/bs";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "20px",
  },
  nextBtn: {
    background: "#ff5e00",
    borderRadius: "5px",
    textTransform: "none",
    marginRight: "10px",
    "&:hover": {
      background: "#FF5E00",
    },
  },
  backBtn: {
    borderRadius: "5px",
    textTransform: "none",
    marginRight: "10px",
  },
  resetBtn: {
    background: "#051528",
    borderRadius: "5px",
    textTransform: "none",
    marginRight: "10px",
    "&:hover": {
      background: "#051528",
    },
  },
}));

function ButtonGroup({
  handleBack,
  handleSubmit,
  handleNext,
  handleReset,
  steps,
  activeStep,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button
        startIcon={<IoIosArrowRoundBack />}
        disabled={activeStep === 0}
        onClick={handleBack}
        className={classes.backBtn}
      >
        Back
      </Button>
      {activeStep === steps.length - 1 ? (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={handleReset}
            className={classes.resetBtn}
          >
            Reset
          </Button>
          <Button
            startIcon={<BsCheck2Circle />}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className={classes.nextBtn}
          >
            Submit
          </Button>
        </>
      ) : (
        <Button
          startIcon={<IoIosArrowRoundForward />}
          variant="contained"
          color="primary"
          onClick={handleNext}
          className={classes.nextBtn}
        >
          {activeStep === steps?.length - 1 ? "Finish" : "Next"}
        </Button>
      )}
    </div>
  );
}

export default ButtonGroup;
