import React, { useCallback } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import PublishIcon from "@material-ui/icons/Publish";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    background: "#e9e9e9",
    padding: "5px",
    marginTop: "10px",
  },
  styleWhileDragging: {
    textAlign: "center",
    padding: "15px",
    fontSize: "12px",
    letterSpacing: "1px",
    border: "1px dotted orange",
    textTransform: "none",
  },
  uploadIcon: { color: "#bcbcbc", margin: "10px" },
  defaultDropZoneStyle: {
    textAlign: "center",
    padding: "15px",
    fontSize: "12px",
    letterSpacing: "1px",
    textTransform: "none",
    width: "100%",
  },
});

function LogoUpload(props) {
  const classes = useStyles();

  const { t } = useTranslation("common");

  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles);
    props.handleAddFilesToStage(acceptedFiles);
  }, []);

  const getFilesFromEvent = async (event) => {
    const files = event.target.files || event.dataTransfer.files;
    const promises = [];
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const promise = new Promise((resolve, reject) => {
        const image = new Image();
        let url;
        image.onload = function () {
          file.width = image.width;
          file.height = image.height;
          resolve(file);
        };
        url = URL.createObjectURL(file);
        image.src = url;
      });
      promises.push(promise);
    }
    return await Promise.all(promises);
  };

  const validator = (file) => {
    // You can access width/height properties
    if (file.width !== file.height) {
      console.log("not equal", file);
      return {
        code: t("uploads.logo_upload.error_code"),
        message: t("uploads.logo_upload.error_message"),
      };
    }
    console.log(file);
    return null;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    getFilesFromEvent,
    validator,
    maxSize: props.maxSize || 100000000,
    accept: props.accept || "",
    maxFiles: props.maxFiles || 0,
  });

  return (
    <div className={classes.root} {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <Button className={classes.styleWhileDragging}>
          <p>{t("uploads.logo_upload.button")}</p>
          <PublishIcon className={classes.uploadIcon} />
        </Button>
      ) : (
        <Button className={classes.defaultDropZoneStyle}>
          <p>{t("uploads.logo_upload.button", { context: "active" })}</p>
          <PublishIcon className={classes.uploadIcon} />
        </Button>
      )}
    </div>
  );
}

export default LogoUpload;
