const styles = (theme) => ({
  dangerButton: {
    width: "180px",
    fontWeight: "900",
    border: "1px solid #d3cfb7",
    color: "#ef5350",
    textTransform: "none",
    "&:hover": {
      color: "white",
      background: "#E01815",
    },
  },
  dangerButtonLoading: {
    width: "180px",
    color: "#ef5350",
    border: "1px solid #d3cfb7",
    textTransform: "none",
    "&:hover": {
      background: "#E01815",
    },
  },
  cancelButton: {
    textTransform: "none",
    fontWeight: "900",
    color: "#604a9f",
    border: "1px solid #d3cfb7",
  },
  cancelButtonloading: {
    textTransform: "none",
    width: "100px",
    fontWeight: "900",
    color: "#604a9f",
    border: "1px solid #d3cfb7",
  },
  warningCard: {
    background: "#fffbdd",
    padding: "15px",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid #d3cfb7",
    borderLeft: "6px solid rgb(255, 94, 0)",
  },
  warningActionMsg: { marginLeft: "20px", lineHeight: "25px" },
  warningCardDiv: { display: "flex" },
  confirmInput: { width: "100%", marginTop: "20px", height: "40px" },
});

export default styles;
