import React from "react";
import { IconButton } from "@material-ui/core";

export default function IconBtn({ icon, handleClick, dimension, background }) {
  return (
    <IconButton
      onClick={handleClick}
      style={{
        width: dimension || "60px",
        height: dimension || "60px",
        margin: "10px",
        background: background || "white",
        boxShadow: " 0px 0px 10px 2px rgba(0,0,0,0.2)",
      }}
    >
      {icon}
    </IconButton>
  );
}
