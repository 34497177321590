import * as yup from "yup";

export const organizationSchema = yup.object().shape({
  title: yup.string().required("The organization name is required"),
  type: yup.string().required("The organization type is required"),
  email: yup
    .string()
    .email("Must be a valid email")
    .required("The organization's email is required"),
});
