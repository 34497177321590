const styles = (theme) => ({
    btnStartIcon: {
        color: "#ff5e00"
    },
    btn: {
        background: "white",
        textTransform: "none",
        color: "black",
        "&:hover": {
            background: "#E0E0E0",
        },
    },
    link: { textDecoration: "none", marginRight: "10px" }
})

export default styles;