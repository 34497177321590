import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import SplitButton from "../DropdownButton/DropdownButton";
import { Grid, makeStyles, Card } from "@material-ui/core";
import styles from "./creatorCardStyle";
import { BsInfoSquareFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import SharedTextField from "../SharedTextField/SharedTextField";

const useStyles = makeStyles(styles);

function OrganizationCreatorDialogBody1(props) {
  const classes = useStyles();

  const { t } = useTranslation(["organizations_page"]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card
          style={{
            display: "flex",
            borderLeft: "3px solid #ff5e00",
            padding: "10px",
          }}
        >
          <BsInfoSquareFill
            style={{ marginRight: "10px" }}
            fontSize="15px"
            color="#ff5e00"
          />
          <p style={{ fontSize: "12px", color: "grey" }}>
            {t("organization_creator_dialog.required_fields")}
          </p>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <label className={classes.inputLabel}>
          {t("organization_creator_dialog.input_label_organization")}
        </label>
        <SharedTextField
          fullWidth
          variant="outlined"
          id="title"
          type="text"
          autoFocus
          autoComplete="off"
          defaultValue={props.title} //default title is shown on subject update modal
          onChange={props.onChangeTitle}
          className={classes.input}
          error={props.titleError}
          onBlur={props.onBlur}
        />
        <p className={classes.errorMsg}>{props.titleHelperText}</p>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <label className={classes.inputLabel}>
          {" "}
          {t("organization_creator_dialog.input_label_email")}{" "}
        </label>
        <SharedTextField
          fullWidth
          variant="outlined"
          id="email"
          type="text"
          autoComplete="off"
          defaultValue={props.email} //default title is shown on subject update modal
          onChange={props.onChangeEmail}
          className={classes.input}
          error={props.emailError}
          onBlur={props.onBlur}
        />
        <p className={classes.errorMsg}>{props.emailHelperText}</p>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <label className={classes.inputLabel}>Phone Number</label>
        <label className={classes.inputLabelSecondary}>
          We use this number to send you major in-app notifications from your
          organization.
        </label>
        <div style={{ marginTop: "20px" }}>
          <PhoneInput
            country={"ug"}
            value={props.phoneNumber}
            onChange={props.onChangePhoneNumber}
          />
        </div>
      </Grid>
      <Grid
        style={{ justifyContent: "center" }}
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <label className={classes.inputLabel}>
            {t("organization_creator_dialog.input_label_type")}
          </label>
          {props.isEditingOrganization && !props.isSuperAdmin && (
            <label className={classes.inputLabelSecondary}>
              {t("organization_creator_dialog.input_label_type_secondary")}
            </label>
          )}
          <SplitButton
            id="type"
            options={props.typeOptions}
            value={props.type}
            onBlur={props.onBlur}
            onChange={props.onChangeType}
            isDisabled={props.isEditingOrganization && !props.isSuperAdmin}
          />
          <p className={classes.errorMsg}>{props.typeHelperText}</p>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default OrganizationCreatorDialogBody1;
