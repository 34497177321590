import React, { useRef } from "react";
import { Button, Grid, makeStyles, Paper } from "@material-ui/core";
import {
  GiIncomingRocket,
  GiMagnifyingGlass,
  GiSelfLove,
} from "react-icons/gi";
import { BiAnalyse } from "react-icons/bi";
import { gql, useQuery } from "@apollo/client";
import { JOB_INFO_FRAGMENT } from "../../utils/graphql";
import CircleSpinner from "../../components/CircleSpinner/CircleSpinner";
import JobCard from "../../components/HumanResource/JobCard";
import Navbar from "../../features/Landing/components/Navbar";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "80px",
    height: "80px",
    padding: "5px",
    textAlign: "center",
    background: "#111b29",
  },
  h1: {
    fontSize: "60px",
    color: "white",
    fontWeight: "300",
  },
  h2: {
    fontSize: "30px",
    color: "white",
    marginTop: "20px",
    fontWeight: "200",
  },
  item1: {
    backgroundImage: "linear-gradient(to right, #ff5e00, purple)",
    padding: "100px",
  },
  item2: {
    background: "#051728",
    padding: "100px",
  },
  item3: {
    background: "white",
    padding: "100px",
  },
  item4: {
    background: "#051728",
    padding: "100px",
  },
  valuesPaper: {
    background: "#e5e5e5",
    color: "black",
    padding: "100px",
    textAlign: "center",
  },
  value: {
    fontSize: "30px",
    color: "black",
    fontWeight: "400",
    marginTop: "10px",
  },
  valueDescription: {
    fontSize: "16px",
    color: "black",
    fontWeight: "400",
    marginTop: "10px",
  },
  openPositions: {
    fontSize: "40px",
    fontWeight: "bold",
    color: "white",
    marginLeft: "5px",
    marginBottom: "50px",
    textAlign: "center",
  },
  btn: {
    marginTop: "30px",
    padding: "10px",
    fontSize: "16px",
    background: "white",
    textTransform: "none",
    "&:hover": {
      background: "white",
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    },
  },
  "@media screen and (max-width: 900px)": {
    h1: {
      fontSize: "40px",
    },
    h2: {
      fontSize: "20px",
    },
    item1: {
      padding: "50px 20px 50px 20px",
    },
    item2: {
      padding: "50px 20px 50px 20px",
    },
    item3: {
      padding: "50px 20px 50px 20px",
    },
    item4: {
      background: "#051728",
      padding: "20px",
    },
    value: {
      fontSize: "24px",
    },
    valuesPaper: {
      padding: "20px",
    },
    openPositions: {
      fontSize: "40px",
      fontWeight: "bold",
      color: "white",
      marginLeft: "5px",
      marginBottom: "50px",
      marginTop: "50px",
      textAlign: "center",
    },
  },
}));

export default function CareersPage() {
  const classes = useStyles();

  const myRef = useRef(null);

  const GET_JOBS = gql`
    query Jobs($where: JobWhere, $options: JobOptions) {
      jobs(where: $where, options: $options) {
        ...JobInfo
      }
    }

    ${JOB_INFO_FRAGMENT}
  `;

  // get jobs
  const { loading: getJobsLoading, data: jobsData } = useQuery(GET_JOBS, {
    fetchPolicy: "cache-and-network",
  });

  const jobs = jobsData?.jobs;

  const jobCards = jobs?.map(
    ({ id, title, description, category, station, type, level }) => (
      <Grid key={id} item xs={12} sm={12} md={12} lg={12}>
        <JobCard
          job={{
            title,
            description,
            category,
            station,
            type,
            level,
            url: `/careers/${id}`,
            urlMsg: "Learn more",
          }}
        />
      </Grid>
    )
  );

  if (getJobsLoading) return <CircleSpinner />;

  return (
    <>
      <Navbar />
      <Grid style={{ marginTop: "80px" }} container spacing={0}>
        <Grid className={classes.item1} item xs={12} sm={12} md={6} lg={6}>
          <h1 className={classes.h1}>
            Join the team shaping the future of education
          </h1>
          <Button
            onClick={() =>
              myRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              })
            }
            className={classes.btn}
          >
            See Open Positions
          </Button>
        </Grid>
        <Grid className={classes.item2} item xs={12} sm={12} md={6} lg={6}>
          <h2 className={classes.h2}>
            We are on a mission to transform education while making it
            accessible for everyone. It won't be easy, but we're building the
            team to make it possible. Will you join us?
          </h2>
        </Grid>
        <Grid className={classes.item3} item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} className={classes.valuesPaper}>
            <h2
              style={{
                fontSize: "40px",
                color: "black",
                marginTop: "20px",
                marginBottom: "60px",
                fontWeight: "800",
              }}
            >
              Our Values
            </h2>
            <Grid style={{ textAlign: "left" }} container spacing={5}>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <GiSelfLove style={{ fontSize: "50px", color: "#F5811F" }} />
                <p className={classes.value}>We love our customers</p>
                <p className={classes.valueDescription}>
                  Our customers are our number one priority, and we know if
                  they’re successful, so are we.
                </p>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <GiIncomingRocket
                  style={{ fontSize: "50px", color: "#F5811F" }}
                />
                <p className={classes.value}>Never stop innovating</p>
                <p className={classes.valueDescription}>
                  We are a company of builders, and we are driven to confront
                  the hard problems and solve them.
                </p>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <BiAnalyse style={{ fontSize: "50px", color: "#F5811F" }} />
                <p className={classes.value}>Act with integrity</p>
                <p className={classes.valueDescription}>
                  We always treat each other, our customers, our partners, and
                  our extended community with honesty, decency, and respect.
                </p>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <GiMagnifyingGlass
                  style={{ fontSize: "50px", color: "#F5811F" }}
                />
                <p className={classes.value}>Be transparent</p>
                <p className={classes.valueDescription}>
                  We operate with transparency. We know that maintaining an open
                  flow of information across teams, among individuals, up and
                  down and around the company, is crucial to how we work.
                </p>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {jobCards?.length === 0 ? (
          <Grid className={classes.item4} item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={2}>
              <p ref={myRef} className={classes.openPositions}>
                Open Positions
              </p>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <p style={{ color: "white" }}>No open positions available</p>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid className={classes.item4} item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={2}>
              <p ref={myRef} className={classes.openPositions}>
                Open Positions
              </p>
              {jobCards}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}
