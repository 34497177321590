import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import gql from "graphql-tag";
import React from "react";
import { Switch, Redirect } from "react-router-dom";
import CircleSpinner from "../../components/CircleSpinner/CircleSpinner";

function LMSCallbackPage() {
  const { user } = useAuth0();

  // GET ADMIN AND THEIR PARTICIPATING ORGANIZATIONS
  const PARTICIPATES_IN_SOME_ORGANIZATIONS = gql`
    query participatesInSomeOrganizations($userId: String!) {
      participatesInSomeOrganizations(userId: $userId)
    }
  `;

  // get admins query
  const { loading: participatesInSomeOrganizationsLoading, data } = useQuery(
    PARTICIPATES_IN_SOME_ORGANIZATIONS,
    {
      variables: {
        userId: user?.sub,
      },
    }
  );

  console.log(data?.participatesInSomeOrganizations);

  const switchRoutes = (
    <Switch>
      <Redirect
        from="/callback"
        to={
          data?.participatesInSomeOrganizations
            ? "role-auth"
            : !data?.participatesInSomeOrganizations
            ? "/self-create-organization"
            : "/504"
        }
      />
    </Switch>
  );

  if (participatesInSomeOrganizationsLoading) return <CircleSpinner />;

  return <div>{switchRoutes}</div>;
}

export default LMSCallbackPage;
