import React from "react";
import PropTypes from "prop-types";
import RoleCard from "./RoleCard";
import RoleCardLink from "./RoleCardLink";
import useAuth from "../../../../hooks/Authentication/useAuth";
import RoleOrganizations from "./RoleOrganizations";

export default function AccountRole({ role, participatingOrgs }) {
  const { user } = useAuth();

  return (
    <RoleCardLink user={user} role={role}>
      <RoleCard role={role} />
      <RoleOrganizations role={role} participatingOrgs={participatingOrgs} />
    </RoleCardLink>
  );
}

AccountRole.propTypes = {
  role: PropTypes.string.isRequired,
  participatingOrgs: PropTypes.func.isRequired,
};
