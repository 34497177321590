import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

import styles from "./styles";

const useStyles = makeStyles(styles);

function NavLogo() {
  // hooks
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Link to="/">
        <img
          src="https://res.cloudinary.com/teenkevo-cloud/image/upload/v1658287281/vlearned-beta/vlearned-final-logo_pazz2u_oypnii.webp"
          alt="logo"
          className={classes.logo}
        />
      </Link>
    </div>
  );
}

export default NavLogo;
