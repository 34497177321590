import { emphasize, withStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";

export const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[90],
    height: "auto",
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
    transition: "all 0.3s ease-out",
    margin: "2px 0 2px 0px",
  },
  label: {
    whiteSpace: "normal",
    padding: "7px",
    marginLeft: "5px",
  },
}))(Chip);
