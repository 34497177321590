const styles = () => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: "#142132",
    borderBottom: "2px solid #2C3847",
  },
  logo: {
    width: "140px",
    verticalAlign: "middle",
    border: "0",
  },
  // TODO: make reusable
  hoverButtonLink: {
    borderRadius: "4px",
    color: "white",
    fontSize: "16px",
    letterSpacing: "1px",
    transition: "all 0.3s ease",
    cursor: "pointer",
    fontWeight: "bold",
    textTransform: "none",
    margin: "5px",
    "&:hover": {
      color: "#F5811F",
    },
  },
  // TODO: make reusable
  hoverButtonLinkPrimary: {
    borderRadius: "4px",
    color: "#F5811F",
    fontSize: "16px",
    letterSpacing: "1px",
    transition: "all 0.3s ease",
    cursor: "pointer",
    fontWeight: "bold",
    textTransform: "none",
    margin: "5px",
    "&:hover": {
      color: "#ff5e00",
    },
  },
  // TODO: make reusable
  callForActionButtonPrimary: {
    textTransform: "none",
    fontSize: "16px",
    background: "#ff5e00",
    color: "white",
    borderRadius: 0,
    margin: "5px",
    "&:hover": {
      background: "#ff5e00",
    },
    transition: "none",
  },
  callForActionButtonPrimary2: {
    textTransform: "none",
    fontSize: "16px",
    background: "#ff5e00",
    color: "white",
    borderRadius: 4,
    margin: "5px",
    padding: "5px 16px",
    cursor: "pointer",
    "&:hover": {
      background: "##c94a00",
    },
    transition: "none",
  },
  mobileMenuButton: {
    textTransform: "none",
    color: "white",
    border: "1px solid #F5811F",
    borderRadius: "5px",
  },
  mobileMenuCloseIcon: { color: "grey", fontSize: "40px" },
  mobileMenuCloseIconButton: {
    borderRadius: "5px",
    marginBottom: "30px",
  },
  drawerPaper: {
    width: "300px",
    background: "#051728",
    padding: "40px",
  },
});

export default styles;
