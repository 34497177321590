import React from "react";
import SplitButton from "../DropdownButton/DropdownButton";
import { Grid, makeStyles } from "@material-ui/core";
import styles from "./creatorCardStyle";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

function OrganizationCreatorDialogBody4(props) {
  const classes = useStyles();
  const { t } = useTranslation(["organizations_page"]);

  return (
    <Grid container spacing={2}>
      <Grid
        style={{ justifyContent: "center" }}
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <label className={classes.inputLabel}>Referral Sales Agent</label>
          <label className={classes.inputLabelSecondary}>
            Please choose the sales agent who assisted you in your sign-up
            journey. We sincerely appreciate your participation in this process,
            as it will enable us to recognize and appreciate our team members
            for their outstanding efforts.
          </label>
          <SplitButton
            id="salesAgent"
            options={props.salesAgentOptions}
            value={props.salesAgent}
            onBlur={props.onBlur}
            onChange={props.onChangeSalesAgent}
          />
          <p className={classes.errorMsg}>{props.salesAgentHelperText}</p>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default OrganizationCreatorDialogBody4;
