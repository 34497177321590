import React, { useState } from 'react'
import { TextField, Button, Container, Box, InputAdornment, IconButton, Snackbar, CircularProgress } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import logo from '../../assets/vlearned-final-logo.webp'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Alert } from '@material-ui/lab';

import Cookies from 'js-cookie';

import axios from 'axios';

const Register = () => {
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const api_link = process.env.REACT_APP_API_LINK_VLEARNED

  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');

  const handleRegistration = (e) => {
    e.preventDefault();
    setLoading(true)

    if (password1 !== password2) {
      setErrorMessage('Error: passwords do not match');
      handleClick();
    } else if (name === '' || email === '' || phone === '' || password1 === '' || password2 === '') {
      setErrorMessage('Error: Please fill in all the required fields');
      handleClick();
    } else {
      var options = {
        method: 'POST',
        url: `${api_link}/users/register`,
        data: {
          name: name,
          phone: phone,
          email: email,
          password: password1
        }
      };

      axios.request(options)
        .then(function (response) {
          if (response.data.status === 200) {
            // setErrorMessage('Registration successful! Check your email for the verification code.');
            setErrorMessage('Success! You can now login.');
            // setErrorMessage('Success! Check your email for the verification code.');

            Cookies.set('email', response.data.data.email, { expires: 7 });
            Cookies.set('user_token', response.data.data.user_token, { expires: 7 });

            localStorage.setItem('email', response.data.data.email);
            localStorage.setItem('user_tokens', response.data.data.token);
            localStorage.setItem('user_token', response.data.data.user_token);

            setOpen(true);
            setLoading(false);
            setTimeout(() => window.location.href = '/login', 2000);
            // setTimeout(() => window.location.href = '/activate-account', 2000);
          } else {
            setErrorMessage(`Error: ${response.data.meesage}`);
            setOpen(true);
            setLoading(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
          setErrorMessage('Error: Registering user');
          setOpen(true);
        });
    }
  };

  return (
    <Container maxWidth="sm" className='container-flex'>
      <Box height={500} width={'100%'} my={4} display="flex" alignItems="center" gap={4} p={2} alignSelf="center">
        <form className='account-bg-white'>
          <center><Link to='/'><img src={logo} alt="logo" width={240} /></Link></center>

          <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={errorMessage.startsWith('Error:') ? 'error' : 'success'} id='errorMessage'>
              {errorMessage}
            </Alert>
          </Snackbar>

          <TextField type='text' id="outlined-basic" label="Name" variant="outlined" className='w-100' onChange={(e) => setName(e.target.value)} required />

          <TextField type='tel' id="outlined-basic" label="Phone" variant="outlined" className='w-100' onChange={(e) => setPhone(e.target.value)} required />

          <TextField type='email' id="outlined-basic" label="Email" variant="outlined" className='w-100' onChange={(e) => setEmail(e.target.value)} required />

          <TextField
            type={showPassword1 ? 'text' : 'password'}
            id="outlined-basic"
            label="Password"
            variant="outlined"
            className='w-100'
            required
            onChange={(e) => setPassword1(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword1}
                    onMouseDown={handleMouseDownPassword1}
                  >
                    {showPassword1 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <TextField
            type={showPassword2 ? 'text' : 'password'}
            id="outlined-basic"
            label="Confirm Password"
            variant="outlined"
            required
            className='w-100'
            onChange={(e) => setPassword2(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword2}
                    onMouseDown={handleMouseDownPassword2}
                  >
                    {showPassword2 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          {loading ? (
            <Button variant="contained" color="primary" className='mt-7' disabled>
              <CircularProgress size={24} />
            </Button>
          ) : (
            <Button variant="contained" color="primary" className='mt-7' onClick={handleRegistration}>Register</Button>
          )}

          <p className='option'>I have an account? <Link to='/login' className='link text-danger'>Login</Link></p>
        </form>
      </Box>
    </Container>
  )
}

export default Register;
