import gql from "graphql-tag";

export const JOB_INFO_FRAGMENT = gql`
  fragment JobInfo on Job {
    id
    title
    description
    responsibilities
    requirements
    benefits
    category
    station
    type
    level
    createdAt
  }
`;

export const APPLICANT_INFO_FRAGMENT = gql`
  fragment ApplicantInfo on Applicant {
    id
    userId
    firstName
    middleName
    lastName
    email
    phoneNumber
    github
    linkedIn
    website
    location
    whyWorkHere
    whyFitRole
    createdAt
  }
`;

export const ORGANIZATION_INFO_FRAGMENT = gql`
  fragment OrganizationInfo on Organization {
    organizationId
    stripeCustomerId
    createdAt
    salesAgent
    title
    email
    type
    gcLogoName
    logoUrl
    trainingCategories
  }
`;

export const PAYMENT_METHOD_INFO_FRAGMENT = gql`
  fragment PaymentMethodInfo on PaymentMethod {
    methodId
    stripeMethodId
    type
  }
`;

export const CARD_INFO_FRAGMENT = gql`
  fragment CardInfo on Card {
    cardId
    brand
    country
    expMonth
    expYear
    last4
  }
`;
export const BILLING_DETAILS_OBJECT_INFO_FRAGMENT = gql`
  fragment BillingDetailsObjectInfo on BillingDetailsObject {
    billingDetailsObjectId
    email
    name
    phone
  }
`;

export const ADDRESS_INFO_FRAGMENT = gql`
  fragment AddressInfo on Address {
    createdAt
    addressId
    city
    country
    addressLine1
    addressLine2
    postalCode
    state
  }
`;

export const VLEARNED_PRODUCT_INFO_FRAGMENT = gql`
  fragment VlearnedProductInfo on VlearnedProduct {
    productId
    stripeProductId
    createdAt
    title
    helperMessage
    storage
    users
    liveSessionsSupported
    liveSessionRecordingSupported
    liveSessionSimultaneousUsers
    discount
    active
    isFree
    planType
  }
`;

export const PRICE_INFO_FRAGMENT = gql`
  fragment PriceInfo on Price {
    priceId
    stripePriceId
    createdAt
    active
    amount
    currency
    type
    isDefault
    interval
    intervalCount
  }
`;

const VLEARNED_PRODUCT_SUBSCRIPTION_INFO_FRAGMENT = gql`
  fragment VlearnedProductSubscriptionInfo on VlearnedProductSubscription {
    subscriptionId
    stripeSubscriptionId
    createdAt
    title
    cancelAt
    canceledAt
    endedAt
    cancelAtPeriodEnd
    collectionMethod
    currentPeriodStart
    currentPeriodEnd
    subscribedPriceId
  }
`;

const INVOICE_INFO_FRAGMENT = gql`
  fragment InvoiceInfo on Invoice {
    invoiceId
    createdAt
    serviceStart
    serviceEnd
    billingCountry
    amount
    tax
    subscriptionPlanTitle
    billingCycle
  }
`;

const BULK_IMPORT_INFO_FRAGMENT = gql`
  fragment BulkImportInfo on BulkImport {
    bulkImportId
    createdAt
    jobId
    jobStatus
    jobErrors
  }
`;

export const SUBJECT_INFO_FRAGMENT = gql`
  fragment SubjectInfo on Subject {
    subjectId
    title
    year
    curriculum
    trainingCategory
    description
    level
    levelType
    # startDate
    # endDate
    enrollmentCode
    visible
    averageRating
    parentOrganizationId
    totalHours
    mandatory
    teachingMethod
    costPerParticipant
    currency
    oneTimeDate
    oneTimeStartTime
    oneTimeEndTime
    recurring
    recurrenceStartTime
    recurrenceEndTime
    recurrenceFrequency
    recurrenceFrequencyInterval
    recurrenceMonthlyOption
    recurrenceYearlyOption
    recurrenceDays
    recurrenceStartDate
    recurrenceEndDate
    occurrencesToEnd
  }
`;

export const LESSON_INFO_FRAGMENT = gql`
  fragment LessonInfo on Lesson {
    lessonId
    title
    description
    learningOutcomes
    date
    startTime
    endTime
    visible
  }
`;

export const LIVE_SESSION_INFO_FRAGMENT = gql`
  fragment LiveSessionInfo on LiveSession {
    createdAt
    sessionDbId
    sessionName
    sessionId
    sessionDuration
    welcomeMessage
    moderatorPassword
    attendeePassword
    moderatorName
    moderatorUrl
    expired
    startingTime
    endingTime
  }
`;

export const RECORDING_INFO_FRAGMENT = gql`
  fragment RecordingInfo on Recording {
    recordingDbId
    recordingId
    recordingName
    recordingDuration
    url
    videoUrl
  }
`;

export const ASSESSMENT_INFO_FRAGMENT = gql`
  fragment AssessmentInfo on Assessment {
    assessmentId
    title
    date
    startTime
    endTime
    duration
    weightage
    marks
    description
    visible
  }
`;

export const ASSIGNMENT_INFO_FRAGMENT = gql`
  fragment AssignmentInfo on Assignment {
    assignmentId
    title
    startDate
    dueDate
    startTime
    endTime
    weightage
    type
    difficulty
    marks
    description
    visible
  }
`;

export const TEXT_BLOCK_INFO_FRAGMENT = gql`
  fragment TextBlockInfo on TextBlock {
    blockId
    sectionId
    index
    type
    title
    subtitle
    text
  }
`;

export const MEDIA_BLOCK_INFO_FRAGMENT = gql`
  fragment MediaBlockInfo on MediaBlock {
    blockId
    sectionId
    index
    type
    caption
    link
  }
`;

export const QUESTION_BLOCK_INFO_FRAGMENT = gql`
  fragment QuestionBlockInfo on QuestionBlock {
    blockId
    sectionId
    index
    questionNumber
    type
    createdAt
    question
    choices
    difficulty
    duration
    marks
    answer
  }
`;

export const SUBMISSION_INFO_FRAGMENT = gql`
  fragment SubmissionInfo on Submission {
    submissionId
    submittedAt
    grade
    comment
  }
`;

export const FILE_INFO_FRAGMENT = gql`
  fragment FileInfo on File {
    fileId
    url
    name
    type
    gcName
  }
`;

export const TEACHER_INFO_FRAGMENT = gql`
  fragment TeacherInfo on Teacher {
    teacherId
    userId
    authName
    authPicture
    firstName
    middleName
    lastName
    birthDate
    email
    username
    identificationNumber
    external
    organizationIdInSession
    photo
    gender
    designationTitle
    gradeLevel
    dateJoined
    dateLeft
    active
    department
  }
`;

export const STUDENT_INFO_FRAGMENT = gql`
  fragment StudentInfo on Student {
    studentId
    userId
    authName
    authPicture
    firstName
    middleName
    lastName
    birthDate
    email
    username
    identificationNumber
    organizationIdInSession
    photo
    gender
    designationTitle
    gradeLevel
    mandatoryTrainingHours
    electiveTrainingHours
    dateJoined
    dateLeft
    active
    department
    completedTraining
  }
`;

export const PARENT_INFO_FRAGMENT = gql`
  fragment ParentInfo on Parent {
    parentId
    userId
    authName
    authPicture
    firstName
    middleName
    lastName
    birthDate
    email
    username
    identificationNumber
    organizationIdInSession
  }
`;

export const ADMIN_INFO_FRAGMENT = gql`
  fragment AdminInfo on Admin {
    adminId
    userId
    isSuperAdmin
    authName
    authPicture
    firstName
    middleName
    lastName
    birthDate
    email
    username
    identificationNumber
    isTrainingManager
    organizationIdInSession
    productPaymentSchedule
    photo
    gender
    designationTitle
    gradeLevel
    dateJoined
    dateLeft
    active
    department
  }
`;

export const UNASSIGNED_USER_INFO_FRAGMENT = gql`
  fragment UnassignedUserInfo on UnassignedUser {
    unassignedUserId
    userId
    authName
    authPicture
    firstName
    middleName
    lastName
    birthDate
    email
    username
    identificationNumber
  }
`;

export const RATING_INFO_FRAGMENT = gql`
  fragment RatingInfo on Rating {
    ratingId
    stars
    text
  }
`;

export const CREATE_SUBJECT_AND_CONNECT_TO_TEACHER = gql`
  mutation createSubjects($input: [SubjectCreateInput!]!) {
    createSubjects(input: $input) {
      subjects {
        ...SubjectInfo
        ratings {
          ...RatingInfo
          student {
            ...StudentInfo
          }
        }
        teacher {
          ...TeacherInfo
          organizations {
            ...OrganizationInfo
          }
          subjects {
            ...SubjectInfo
            teacher {
              ...TeacherInfo
            }
            ratings {
              ...RatingInfo
              student {
                ...StudentInfo
              }
            }
            units {
              unitId
              title
            }
            lessons {
              ...LessonInfo
              files {
                ...FileInfo
              }
              liveSessions {
                ...LiveSessionInfo
                recording {
                  ...RecordingInfo
                }
              }
            }
            assessments {
              ...AssessmentInfo
              files {
                ...FileInfo
              }
              document {
                documentId
                released
                marked
                textBlocks {
                  ...TextBlockInfo
                }
                mediaBlocks {
                  ...MediaBlockInfo
                  file {
                    ...FileInfo
                  }
                }
                questionBlocks {
                  ...QuestionBlockInfo
                }
              }
              submissions {
                ...SubmissionInfo
                student {
                  ...StudentInfo
                }
                files {
                  ...FileInfo
                }
                feedbackFiles {
                  ...FileInfo
                }
              }
            }
            assignments {
              ...AssignmentInfo
              files {
                ...FileInfo
              }
              document {
                documentId
                released
                marked
                textBlocks {
                  ...TextBlockInfo
                }
                mediaBlocks {
                  ...MediaBlockInfo
                  file {
                    ...FileInfo
                  }
                }
                questionBlocks {
                  ...QuestionBlockInfo
                }
              }
              submissions {
                ...SubmissionInfo
                student {
                  ...StudentInfo
                }
                files {
                  ...FileInfo
                }
                feedbackFiles {
                  ...FileInfo
                }
              }
            }
            students {
              ...StudentInfo
              subjects {
                ...SubjectInfo
                assessments {
                  ...AssessmentInfo
                  submissions {
                    ...SubmissionInfo
                  }
                }
                assignments {
                  ...AssignmentInfo
                  submissions {
                    ...SubmissionInfo
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${SUBJECT_INFO_FRAGMENT}
  ${TEACHER_INFO_FRAGMENT}
  ${ORGANIZATION_INFO_FRAGMENT}
  ${STUDENT_INFO_FRAGMENT}
  ${RATING_INFO_FRAGMENT}
  ${LESSON_INFO_FRAGMENT}
  ${FILE_INFO_FRAGMENT}
  ${LIVE_SESSION_INFO_FRAGMENT}
  ${RECORDING_INFO_FRAGMENT}
  ${ASSESSMENT_INFO_FRAGMENT}
  ${TEXT_BLOCK_INFO_FRAGMENT}
  ${MEDIA_BLOCK_INFO_FRAGMENT}
  ${QUESTION_BLOCK_INFO_FRAGMENT}
  ${SUBMISSION_INFO_FRAGMENT}
  ${ASSIGNMENT_INFO_FRAGMENT}
`;

export const UPDATE_STUDENT = gql`
  mutation updateStudents(
    $where: StudentWhere
    $update: StudentUpdateInput
    $connect: StudentConnectInput
    $disconnect: StudentDisconnectInput
  ) {
    updateStudents(
      where: $where
      update: $update
      connect: $connect
      disconnect: $disconnect
    ) {
      students {
        studentId
        organizationIdInSession
      }
    }
  }
`;

export const UPDATE_TEACHER = gql`
  mutation updateTeachers(
    $where: TeacherWhere
    $update: TeacherUpdateInput
    $connect: TeacherConnectInput
    $disconnect: TeacherDisconnectInput
  ) {
    updateTeachers(
      where: $where
      update: $update
      connect: $connect
      disconnect: $disconnect
    ) {
      teachers {
        teacherId
        organizationIdInSession
      }
    }
  }
`;

export const UPDATE_ADMIN = gql`
  mutation updateAdmins(
    $where: AdminWhere
    $update: AdminUpdateInput
    $connect: AdminConnectInput
    $disconnect: AdminDisconnectInput
    $create: AdminRelationInput
  ) {
    updateAdmins(
      where: $where
      update: $update
      connect: $connect
      disconnect: $disconnect
      create: $create
    ) {
      admins {
        ...AdminInfo
        address {
          ...AddressInfo
        }
        vlearnedProducts {
          ...VlearnedProductInfo
        }
      }
    }
  }
  ${VLEARNED_PRODUCT_INFO_FRAGMENT}
  ${ADMIN_INFO_FRAGMENT}
  ${ADDRESS_INFO_FRAGMENT}
`;

export const UPDATE_PARENT = gql`
  mutation updateParents(
    $where: ParentWhere
    $update: ParentUpdateInput
    $connect: ParentConnectInput
    $disconnect: ParentDisconnectInput
  ) {
    updateParents(
      where: $where
      update: $update
      connect: $connect
      disconnect: $disconnect
    ) {
      parents {
        parentId
      }
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation updateOrganizations(
    $where: OrganizationWhere
    $update: OrganizationUpdateInput
    $connect: OrganizationConnectInput
    $disconnect: OrganizationDisconnectInput
    $create: OrganizationRelationInput
  ) {
    updateOrganizations(
      where: $where
      update: $update
      connect: $connect
      disconnect: $disconnect
      create: $create
    ) {
      organizations {
        ...OrganizationInfo
        subjects {
          ...SubjectInfo
        }
        adminsAggregate {
          count
        }
        parentsAggregate {
          count
        }
        teachersAggregate {
          count
        }
        studentsAggregate {
          count
        }
        unassignedUsersAggregate {
          count
        }
      }
    }
  }
  ${ORGANIZATION_INFO_FRAGMENT}
  ${SUBJECT_INFO_FRAGMENT}
`;

export const UPDATE_VLEARNED_PRODUCT = gql`
  mutation UpdateVlearnedProducts(
    $where: VlearnedProductWhere
    $update: VlearnedProductUpdateInput
    $connect: VlearnedProductConnectInput
    $disconnect: VlearnedProductDisconnectInput
  ) {
    updateVlearnedProducts(
      where: $where
      update: $update
      connect: $connect
      disconnect: $disconnect
    ) {
      vlearnedProducts {
        ...VlearnedProductInfo
        prices(options: { sort: [{ amount: ASC }] }) {
          ...PriceInfo
        }
      }
    }
  }
  ${VLEARNED_PRODUCT_INFO_FRAGMENT}
  ${PRICE_INFO_FRAGMENT}
`;

export const UPDATE_VLEARNED_PRODUCT_SUBSCRIPTION = gql`
  mutation UpdateVlearnedProductSubscriptions(
    $where: VlearnedProductSubscriptionWhere
    $update: VlearnedProductSubscriptionUpdateInput
    $connect: VlearnedProductSubscriptionConnectInput
    $disconnect: VlearnedProductSubscriptionDisconnectInput
  ) {
    updateVlearnedProductSubscriptions(
      where: $where
      update: $update
      connect: $connect
      disconnect: $disconnect
    ) {
      vlearnedProductSubscriptions {
        ...VlearnedProductSubscriptionInfo
      }
    }
  }
  ${VLEARNED_PRODUCT_SUBSCRIPTION_INFO_FRAGMENT}
`;

export const UPDATE_PRICE = gql`
  mutation UpdatePrices($where: PriceWhere, $update: PriceUpdateInput) {
    updatePrices(where: $where, update: $update) {
      prices {
        ...PriceInfo
      }
    }
  }
  ${PRICE_INFO_FRAGMENT}
`;

export const UPDATE_INVOICE = gql`
  mutation updateInvoices($where: InvoiceWhere, $update: InvoiceUpdateInput) {
    updateInvoices(where: $where, update: $update) {
      invoices {
        ...InvoiceInfo
      }
    }
  }
  ${INVOICE_INFO_FRAGMENT}
`;

export const UPDATE_SUBJECT = gql`
  mutation updateSubjects(
    $where: SubjectWhere
    $update: SubjectUpdateInput
    $connect: SubjectConnectInput
    $disconnect: SubjectDisconnectInput
    $create: SubjectRelationInput
  ) {
    updateSubjects(
      where: $where
      update: $update
      connect: $connect
      disconnect: $disconnect
      create: $create
    ) {
      subjects {
        ...SubjectInfo
        teacher {
          ...TeacherInfo
        }
        ratings {
          ...RatingInfo
          student {
            ...StudentInfo
          }
        }
      }
    }
  }
  ${SUBJECT_INFO_FRAGMENT}
  ${TEACHER_INFO_FRAGMENT}
  ${STUDENT_INFO_FRAGMENT}
  ${RATING_INFO_FRAGMENT}
`;

export const UPDATE_LESSON = gql`
  mutation updateLessons(
    $where: LessonWhere
    $connect: LessonConnectInput
    $disconnect: LessonDisconnectInput
    $create: LessonRelationInput
    $update: LessonUpdateInput
  ) {
    updateLessons(
      where: $where
      connect: $connect
      disconnect: $disconnect
      create: $create
      update: $update
    ) {
      lessons {
        ...LessonInfo
        files {
          ...FileInfo
        }
        liveSessions {
          ...LiveSessionInfo
          recording {
            ...RecordingInfo
          }
        }
      }
    }
  }
  ${LESSON_INFO_FRAGMENT}
  ${FILE_INFO_FRAGMENT}
  ${LIVE_SESSION_INFO_FRAGMENT}
  ${RECORDING_INFO_FRAGMENT}
`;

export const UPDATE_SUBMISSION = gql`
  mutation updateSubmissions(
    $where: SubmissionWhere
    $connect: SubmissionConnectInput
    $disconnect: SubmissionDisconnectInput
    $create: SubmissionRelationInput
    $update: SubmissionUpdateInput
  ) {
    updateSubmissions(
      where: $where
      connect: $connect
      disconnect: $disconnect
      create: $create
      update: $update
    ) {
      submissions {
        ...SubmissionInfo
        student {
          ...StudentInfo
        }
        files {
          ...FileInfo
        }
        feedbackFiles {
          ...FileInfo
        }
      }
    }
  }
  ${STUDENT_INFO_FRAGMENT}
  ${FILE_INFO_FRAGMENT}
  ${SUBMISSION_INFO_FRAGMENT}
`;

export const UPDATE_ASSIGNMENT = gql`
  mutation updateAssignments(
    $where: AssignmentWhere
    $connect: AssignmentConnectInput
    $disconnect: AssignmentDisconnectInput
    $create: AssignmentRelationInput
    $update: AssignmentUpdateInput
  ) {
    updateAssignments(
      where: $where
      connect: $connect
      disconnect: $disconnect
      create: $create
      update: $update
    ) {
      assignments {
        ...AssignmentInfo
        files {
          ...FileInfo
        }
        document {
          documentId
          released
          marked
          textBlocks {
            ...TextBlockInfo
          }
          mediaBlocks {
            ...MediaBlockInfo
            file {
              ...FileInfo
            }
          }
          questionBlocks {
            ...QuestionBlockInfo
          }
        }
        submissions {
          ...SubmissionInfo
          student {
            ...StudentInfo
          }
          files {
            ...FileInfo
          }
          feedbackFiles {
            ...FileInfo
          }
        }
      }
    }
  }
  ${STUDENT_INFO_FRAGMENT}
  ${FILE_INFO_FRAGMENT}
  ${TEXT_BLOCK_INFO_FRAGMENT}
  ${MEDIA_BLOCK_INFO_FRAGMENT}
  ${QUESTION_BLOCK_INFO_FRAGMENT}
  ${SUBMISSION_INFO_FRAGMENT}
  ${ASSIGNMENT_INFO_FRAGMENT}
`;

export const UPDATE_ASSESSMENT = gql`
  mutation updateAssessments(
    $where: AssessmentWhere
    $connect: AssessmentConnectInput
    $disconnect: AssessmentDisconnectInput
    $create: AssessmentRelationInput
    $update: AssessmentUpdateInput
  ) {
    updateAssessments(
      where: $where
      connect: $connect
      disconnect: $disconnect
      create: $create
      update: $update
    ) {
      assessments {
        ...AssessmentInfo
        files {
          ...FileInfo
        }
        document {
          documentId
          released
          marked
          textBlocks {
            ...TextBlockInfo
          }
          mediaBlocks {
            ...MediaBlockInfo
            file {
              ...FileInfo
            }
          }
          questionBlocks {
            ...QuestionBlockInfo
          }
        }
        submissions {
          ...SubmissionInfo
          student {
            ...StudentInfo
          }
          files {
            ...FileInfo
          }
          feedbackFiles {
            ...FileInfo
          }
        }
      }
    }
  }
  ${STUDENT_INFO_FRAGMENT}
  ${FILE_INFO_FRAGMENT}
  ${ASSESSMENT_INFO_FRAGMENT}
  ${TEXT_BLOCK_INFO_FRAGMENT}
  ${MEDIA_BLOCK_INFO_FRAGMENT}
  ${QUESTION_BLOCK_INFO_FRAGMENT}
  ${SUBMISSION_INFO_FRAGMENT}
`;

export const DELETE_ORGANIZATION = gql`
  mutation deleteOrganizations(
    $where: OrganizationWhere
    $delete: OrganizationDeleteInput
  ) {
    deleteOrganizations(where: $where, delete: $delete) {
      nodesDeleted
      relationshipsDeleted
    }
  }
`;

export const DELETE_SUBSCRIPTION_PLAN = gql`
  mutation deleteSubscriptionPlans($where: SubscriptionPlanWhere) {
    deleteSubscriptionPlans(where: $where) {
      nodesDeleted
    }
  }
`;

export const DELETE_INVOICE = gql`
  mutation DeleteInvoices($where: InvoiceWhere) {
    deleteInvoices(where: $where) {
      nodesDeleted
    }
  }
`;

export const DELETE_SUBJECT = gql`
  mutation deleteSubjects($where: SubjectWhere, $delete: SubjectDeleteInput) {
    deleteSubjects(where: $where, delete: $delete) {
      nodesDeleted
      relationshipsDeleted
    }
  }
`;

export const DELETE_SUBMISSION = gql`
  mutation deleteSubmissions(
    $where: SubmissionWhere
    $delete: SubmissionDeleteInput
  ) {
    deleteSubmissions(where: $where, delete: $delete) {
      nodesDeleted
      relationshipsDeleted
    }
  }
`;

export const DELETE_LIVE_SESSION = gql`
  mutation deleteLiveSessions(
    $where: LiveSessionWhere
    $delete: LiveSessionDeleteInput
  ) {
    deleteLiveSessions(where: $where, delete: $delete) {
      nodesDeleted
      relationshipsDeleted
    }
  }
`;

export const DELETE_LESSON = gql`
  mutation deleteLessons($where: LessonWhere, $delete: LessonDeleteInput) {
    deleteLessons(where: $where, delete: $delete) {
      nodesDeleted
      relationshipsDeleted
    }
  }
`;

export const DELETE_ASSIGNMENT = gql`
  mutation deleteAssignments(
    $where: AssignmentWhere
    $delete: AssignmentDeleteInput
  ) {
    deleteAssignments(where: $where, delete: $delete) {
      nodesDeleted
      relationshipsDeleted
    }
  }
`;

export const DELETE_ASSESSMENT = gql`
  mutation deleteAssessments(
    $where: AssessmentWhere
    $delete: AssessmentDeleteInput
  ) {
    deleteAssessments(where: $where, delete: $delete) {
      nodesDeleted
      relationshipsDeleted
    }
  }
`;

export const DELETE_ADMIN = gql`
  mutation deleteAdmins($where: AdminWhere, $delete: AdminDeleteInput) {
    deleteAdmins(where: $where, delete: $delete) {
      nodesDeleted
      relationshipsDeleted
    }
  }
`;

export const DELETE_STUDENT = gql`
  mutation deleteStudents($where: StudentWhere, $delete: StudentDeleteInput) {
    deleteStudents(where: $where, delete: $delete) {
      nodesDeleted
      relationshipsDeleted
    }
  }
`;

export const DELETE_TEACHER = gql`
  mutation deleteTeachers($where: TeacherWhere, $delete: TeacherDeleteInput) {
    deleteTeachers(where: $where, delete: $delete) {
      nodesDeleted
      relationshipsDeleted
    }
  }
`;

export const DELETE_PARENT = gql`
  mutation deleteParents($where: ParentWhere, $delete: ParentDeleteInput) {
    deleteParents(where: $where, delete: $delete) {
      nodesDeleted
      relationshipsDeleted
    }
  }
`;

export const DELETE_UNASSIGNED_USERS = gql`
  mutation deleteUnassignedUsers(
    $where: UnassignedUserWhere
    $delete: UnassignedUserDeleteInput
  ) {
    deleteUnassignedUsers(where: $where, delete: $delete) {
      nodesDeleted
      relationshipsDeleted
    }
  }
`;

// FIXME: Migrate logic to conform to new neo4j/graphql
export const DELETE_DOCUMENT = gql`
  mutation deleteDocument($documentId: ID!) {
    DeleteDocument(documentId: $documentId) {
      documentId
    }
  }
`;

// FIXME: Migrate logic to conform to new neo4j/graphql
export const DELETE_TEXT_BLOCK = gql`
  mutation deleteTextBlock($blockId: ID!) {
    DeleteTextBlock(blockId: $blockId) {
      blockId
    }
  }
`;

// FIXME: Migrate logic to conform to new neo4j/graphql
export const DELETE_QUESTION_BLOCK = gql`
  mutation deleteQuestionBlock($blockId: ID!) {
    DeleteQuestionBlock(blockId: $blockId) {
      blockId
      question
    }
  }
`;

// FIXME: Migrate logic to conform to new neo4j/graphql
export const DELETE_MEDIA_BLOCK = gql`
  mutation deleteMediaBlock($blockId: ID!) {
    DeleteMediaBlock(blockId: $blockId) {
      blockId
    }
  }
`;

export const CREATE_STUDENT_RATING_AND_CONNECT_TO_SUBJECT = gql`
  mutation createRatings($input: [RatingCreateInput!]!) {
    createRatings(input: $input) {
      ratings {
        ...RatingInfo
        student {
          ...StudentInfo
        }
      }
    }
  }
  ${STUDENT_INFO_FRAGMENT}
  ${RATING_INFO_FRAGMENT}
`;

export const CREATE_MEDIA_BLOCKS = gql`
  mutation createMediaBlocks($input: [MediaBlockCreateInput!]!) {
    createMediaBlocks(input: $input) {
      ...MediaBlockInfo
      file {
        ...FileInfo
      }
    }
  }
  ${FILE_INFO_FRAGMENT}
  ${MEDIA_BLOCK_INFO_FRAGMENT}
`;

export const CREATE_LIVE_SESSION = gql`
  mutation createLiveSessions($input: [LiveSessionCreateInput!]!) {
    createLiveSessions(input: $input) {
      liveSessions {
        ...LiveSessionInfo
        recording {
          ...RecordingInfo
        }
      }
    }
  }
  ${LIVE_SESSION_INFO_FRAGMENT}
  ${RECORDING_INFO_FRAGMENT}
`;

export const UPDATE_LIVE_SESSION = gql`
  mutation updateLiveSessions(
    $where: LiveSessionWhere
    $connect: LiveSessionConnectInput
    $disconnect: LiveSessionDisconnectInput
    $create: LiveSessionRelationInput
    $update: LiveSessionUpdateInput
  ) {
    updateLiveSessions(
      where: $where
      update: $update
      connect: $connect
      disconnect: $disconnect
      create: $create
    ) {
      liveSessions {
        ...LiveSessionInfo
        recording {
          ...RecordingInfo
        }
      }
    }
  }
  ${LIVE_SESSION_INFO_FRAGMENT}
  ${RECORDING_INFO_FRAGMENT}
`;

export const CREATE_RECORDING = gql`
  mutation createRecordings($input: [RecordingCreateInput!]!) {
    createRecordings(input: $input) {
      recordings {
        ...RecordingInfo
      }
    }
  }
  ${RECORDING_INFO_FRAGMENT}
`;

export const CREATE_LESSON = gql`
  mutation createLessons($input: [LessonCreateInput!]!) {
    createLessons(input: $input) {
      lessons {
        ...LessonInfo
        files {
          ...FileInfo
        }
        liveSessions {
          ...LiveSessionInfo
          recording {
            ...RecordingInfo
          }
        }
      }
    }
  }
  ${LESSON_INFO_FRAGMENT}
  ${LIVE_SESSION_INFO_FRAGMENT}
  ${RECORDING_INFO_FRAGMENT}
  ${FILE_INFO_FRAGMENT}
`;

export const CREATE_ASSIGNMENT = gql`
  mutation createAssignments($input: [AssignmentCreateInput!]!) {
    createAssignments(input: $input) {
      assignments {
        ...AssignmentInfo
        files {
          ...FileInfo
        }
        document {
          documentId
          released
          marked
          textBlocks {
            ...TextBlockInfo
          }
          mediaBlocks {
            ...MediaBlockInfo
            file {
              ...FileInfo
            }
          }
          questionBlocks {
            ...QuestionBlockInfo
          }
        }
        submissions {
          ...SubmissionInfo
          student {
            ...StudentInfo
          }
          files {
            ...FileInfo
          }
          feedbackFiles {
            ...FileInfo
          }
        }
      }
    }
  }
  ${STUDENT_INFO_FRAGMENT}
  ${FILE_INFO_FRAGMENT}
  ${TEXT_BLOCK_INFO_FRAGMENT}
  ${MEDIA_BLOCK_INFO_FRAGMENT}
  ${QUESTION_BLOCK_INFO_FRAGMENT}
  ${SUBMISSION_INFO_FRAGMENT}
  ${ASSIGNMENT_INFO_FRAGMENT}
`;

export const CREATE_ASSESSMENT = gql`
  mutation createAssessments($input: [AssessmentCreateInput!]!) {
    createAssessments(input: $input) {
      assessments {
        ...AssessmentInfo
        files {
          ...FileInfo
        }
        document {
          documentId
          released
          marked
          textBlocks {
            ...TextBlockInfo
          }
          mediaBlocks {
            ...MediaBlockInfo
            file {
              ...FileInfo
            }
          }
          questionBlocks {
            ...QuestionBlockInfo
          }
        }
        submissions {
          ...SubmissionInfo
          student {
            ...StudentInfo
          }
          files {
            ...FileInfo
          }
          feedbackFiles {
            ...FileInfo
          }
        }
      }
    }
  }
  ${STUDENT_INFO_FRAGMENT}
  ${FILE_INFO_FRAGMENT}
  ${ASSESSMENT_INFO_FRAGMENT}
  ${TEXT_BLOCK_INFO_FRAGMENT}
  ${MEDIA_BLOCK_INFO_FRAGMENT}
  ${QUESTION_BLOCK_INFO_FRAGMENT}
  ${SUBMISSION_INFO_FRAGMENT}
`;

// FIXME: Migrate logic to conform to new neo4j/graphql (FORUM SIDE)
export const CREATE_ANNOUNCEMENT = gql`
  mutation createAnnouncement(
    $createdAt: DateTime!
    $title: String!
    $body: String!
    $labels: [String]
  ) {
    CreateAnnouncement(
      createdAt: $createdAt
      title: $title
      body: $body
      labels: $labels
    ) {
      announcementId
      title
      body
      labels
      createdAt
    }
  }
`;

// FIXME: Migrate logic to conform to new neo4j/graphql (FORUM SIDE)
export const ADD_TO_MY_ANNOUNCEMENTS = gql`
  mutation AddToMyAnnouncements($announcementId: ID!) {
    AddToMyAnnouncements(announcementId: $announcementId) {
      announcementId
    }
  }
`;

// FIXME: Migrate logic to conform to new neo4j/graphql (FORUM SIDE)
export const GET_MY_ANNOUNCEMENTS = gql`
  {
    getMyAnnouncements {
      announcementId
      title
      body
      labels
      createdAt
    }
  }
`;

export const CREATE_TEACHER = gql`
  mutation createTeachers($input: [TeacherCreateInput!]!) {
    createTeachers(input: $input) {
      teachers {
        ...TeacherInfo
        organizations {
          ...OrganizationInfo
        }
        subjects {
          ...SubjectInfo
          teacher {
            ...TeacherInfo
          }
          ratings {
            ...RatingInfo
            student {
              ...StudentInfo
            }
          }
          units {
            unitId
            title
          }
          lessons {
            ...LessonInfo
            files {
              ...FileInfo
            }
            liveSessions {
              ...LiveSessionInfo
              recording {
                ...RecordingInfo
              }
            }
          }
          assessments {
            ...AssessmentInfo
            files {
              ...FileInfo
            }
            document {
              documentId
              released
              marked
              textBlocks {
                ...TextBlockInfo
              }
              mediaBlocks {
                ...MediaBlockInfo
                file {
                  ...FileInfo
                }
              }
              questionBlocks {
                ...QuestionBlockInfo
              }
            }
            submissions {
              ...SubmissionInfo
              student {
                ...StudentInfo
              }
              files {
                ...FileInfo
              }
              feedbackFiles {
                ...FileInfo
              }
            }
          }
          assignments {
            ...AssignmentInfo
            files {
              ...FileInfo
            }
            document {
              documentId
              released
              marked
              textBlocks {
                ...TextBlockInfo
              }
              mediaBlocks {
                ...MediaBlockInfo
                file {
                  ...FileInfo
                }
              }
              questionBlocks {
                ...QuestionBlockInfo
              }
            }
            submissions {
              ...SubmissionInfo
              student {
                ...StudentInfo
              }
              files {
                ...FileInfo
              }
              feedbackFiles {
                ...FileInfo
              }
            }
          }
          students {
            ...StudentInfo
            subjects {
              ...SubjectInfo
              assessments {
                ...AssessmentInfo
                submissions {
                  ...SubmissionInfo
                }
              }
              assignments {
                ...AssignmentInfo
                submissions {
                  ...SubmissionInfo
                }
              }
            }
          }
        }
      }
    }
  }
  ${SUBJECT_INFO_FRAGMENT}
  ${TEACHER_INFO_FRAGMENT}
  ${STUDENT_INFO_FRAGMENT}
  ${RATING_INFO_FRAGMENT}
  ${LESSON_INFO_FRAGMENT}
  ${FILE_INFO_FRAGMENT}
  ${LIVE_SESSION_INFO_FRAGMENT}
  ${RECORDING_INFO_FRAGMENT}
  ${ASSESSMENT_INFO_FRAGMENT}
  ${TEXT_BLOCK_INFO_FRAGMENT}
  ${MEDIA_BLOCK_INFO_FRAGMENT}
  ${QUESTION_BLOCK_INFO_FRAGMENT}
  ${SUBMISSION_INFO_FRAGMENT}
  ${ASSIGNMENT_INFO_FRAGMENT}
  ${ORGANIZATION_INFO_FRAGMENT}
`;

export const GET_SUBJECTS = gql`
  query subjects($where: SubjectWhere) {
    subjects(where: $where) {
      ...SubjectInfo
      teacher {
        ...TeacherInfo
      }
      ratings {
        ...RatingInfo
        student {
          ...StudentInfo
        }
      }
    }
  }
  ${SUBJECT_INFO_FRAGMENT}
  ${TEACHER_INFO_FRAGMENT}
  ${STUDENT_INFO_FRAGMENT}
  ${RATING_INFO_FRAGMENT}
`;

export const GET_TEACHERS = gql`
  query teachers($where: TeacherWhere) {
    teachers(where: $where) {
      ...TeacherInfo
      address {
        ...AddressInfo
      }
      organizations {
        ...OrganizationInfo
      }
      subjects {
        lessonsAggregate {
          count
        }
        assignmentsAggregate {
          count
        }
        assessmentsAggregate {
          count
        }
        ...SubjectInfo
        teacher {
          ...TeacherInfo
        }
        ratings {
          ...RatingInfo
          student {
            studentId
            firstName
            lastName
            userId
            authPicture
          }
        }
        units {
          unitId
          title
        }
        lessons {
          ...LessonInfo
          files {
            ...FileInfo
          }
          liveSessions {
            ...LiveSessionInfo
            recording {
              ...RecordingInfo
            }
          }
          assessments {
            ...AssessmentInfo
          }
          assignments {
            ...AssignmentInfo
          }
        }
        assessments {
          ...AssessmentInfo
          files {
            ...FileInfo
          }
          document {
            documentId
            released
            marked
            textBlocks {
              ...TextBlockInfo
            }
            mediaBlocks {
              ...MediaBlockInfo
              file {
                ...FileInfo
              }
            }
            questionBlocks {
              ...QuestionBlockInfo
            }
          }
          submissions {
            ...SubmissionInfo
            student {
              ...StudentInfo
            }
            files {
              ...FileInfo
            }
            feedbackFiles {
              ...FileInfo
            }
          }
        }
        assignments {
          ...AssignmentInfo
          files {
            ...FileInfo
          }
          document {
            documentId
            released
            marked
            textBlocks {
              ...TextBlockInfo
            }
            mediaBlocks {
              ...MediaBlockInfo
              file {
                ...FileInfo
              }
            }
            questionBlocks {
              ...QuestionBlockInfo
            }
          }
          submissions {
            ...SubmissionInfo
            student {
              ...StudentInfo
            }
            files {
              ...FileInfo
            }
            feedbackFiles {
              ...FileInfo
            }
          }
        }
        students {
          ...StudentInfo
          subjects {
            ...SubjectInfo
            assessments {
              ...AssessmentInfo
              submissions {
                ...SubmissionInfo
              }
            }
            assignments {
              ...AssignmentInfo
              submissions {
                ...SubmissionInfo
              }
            }
          }
        }
      }
    }
  }
  ${ADDRESS_INFO_FRAGMENT}
  ${SUBJECT_INFO_FRAGMENT}
  ${TEACHER_INFO_FRAGMENT}
  ${STUDENT_INFO_FRAGMENT}
  ${RATING_INFO_FRAGMENT}
  ${LESSON_INFO_FRAGMENT}
  ${FILE_INFO_FRAGMENT}
  ${LIVE_SESSION_INFO_FRAGMENT}
  ${RECORDING_INFO_FRAGMENT}
  ${ASSESSMENT_INFO_FRAGMENT}
  ${TEXT_BLOCK_INFO_FRAGMENT}
  ${MEDIA_BLOCK_INFO_FRAGMENT}
  ${QUESTION_BLOCK_INFO_FRAGMENT}
  ${SUBMISSION_INFO_FRAGMENT}
  ${ASSIGNMENT_INFO_FRAGMENT}
  ${ORGANIZATION_INFO_FRAGMENT}
`;

export const CREATE_PARENT = gql`
  mutation createParents($input: [ParentCreateInput!]!) {
    createParents(input: $input) {
      parents {
        ...ParentInfo
        organizations {
          ...OrganizationInfo
        }
        students {
          ...StudentInfo
          subjects {
            ...SubjectInfo
            teacher {
              ...TeacherInfo
            }
            ratings {
              ...RatingInfo
              student {
                ...StudentInfo
              }
            }
            units {
              unitId
              title
            }
            lessons {
              ...LessonInfo
              files {
                ...FileInfo
              }
              liveSessions {
                ...LiveSessionInfo
                recording {
                  ...RecordingInfo
                }
              }
            }
            assessments {
              ...AssessmentInfo
              files {
                ...FileInfo
              }
              document {
                documentId
                released
                marked
                textBlocks {
                  ...TextBlockInfo
                }
                mediaBlocks {
                  ...MediaBlockInfo
                  file {
                    ...FileInfo
                  }
                }
                questionBlocks {
                  ...QuestionBlockInfo
                }
              }
              submissions {
                ...SubmissionInfo
                student {
                  ...StudentInfo
                }
                files {
                  ...FileInfo
                }
                feedbackFiles {
                  ...FileInfo
                }
              }
            }
            assignments {
              ...AssignmentInfo
              files {
                ...FileInfo
              }
              document {
                documentId
                released
                marked
                textBlocks {
                  ...TextBlockInfo
                }
                mediaBlocks {
                  ...MediaBlockInfo
                  file {
                    ...FileInfo
                  }
                }
                questionBlocks {
                  ...QuestionBlockInfo
                }
              }
              submissions {
                ...SubmissionInfo
                student {
                  ...StudentInfo
                }
                files {
                  ...FileInfo
                }
                feedbackFiles {
                  ...FileInfo
                }
              }
            }
            students {
              ...StudentInfo
            }
          }
        }
      }
    }
  }
  ${SUBJECT_INFO_FRAGMENT}
  ${TEACHER_INFO_FRAGMENT}
  ${STUDENT_INFO_FRAGMENT}
  ${PARENT_INFO_FRAGMENT}
  ${RATING_INFO_FRAGMENT}
  ${LESSON_INFO_FRAGMENT}
  ${FILE_INFO_FRAGMENT}
  ${LIVE_SESSION_INFO_FRAGMENT}
  ${RECORDING_INFO_FRAGMENT}
  ${ASSESSMENT_INFO_FRAGMENT}
  ${TEXT_BLOCK_INFO_FRAGMENT}
  ${MEDIA_BLOCK_INFO_FRAGMENT}
  ${QUESTION_BLOCK_INFO_FRAGMENT}
  ${SUBMISSION_INFO_FRAGMENT}
  ${ASSIGNMENT_INFO_FRAGMENT}
  ${ORGANIZATION_INFO_FRAGMENT}
`;

export const GET_PARENTS = gql`
  query parents($where: ParentWhere) {
    parents(where: $where) {
      ...ParentInfo
      organizations {
        ...OrganizationInfo
      }
      students {
        ...StudentInfo
        subjects {
          ...SubjectInfo
          teacher {
            ...TeacherInfo
          }
          ratings {
            ...RatingInfo
            student {
              ...StudentInfo
            }
          }
          units {
            unitId
            title
          }
          lessons {
            ...LessonInfo
            files {
              ...FileInfo
            }
            liveSessions {
              ...LiveSessionInfo
              recording {
                ...RecordingInfo
              }
            }
          }
          assessments {
            ...AssessmentInfo
            files {
              ...FileInfo
            }
            document {
              documentId
              released
              marked
              textBlocks {
                ...TextBlockInfo
              }
              mediaBlocks {
                ...MediaBlockInfo
                file {
                  ...FileInfo
                }
              }
              questionBlocks {
                ...QuestionBlockInfo
              }
            }
            submissions {
              ...SubmissionInfo
              student {
                ...StudentInfo
              }
              files {
                ...FileInfo
              }
              feedbackFiles {
                ...FileInfo
              }
            }
          }
          assignments {
            ...AssignmentInfo
            files {
              ...FileInfo
            }
            document {
              documentId
              released
              marked
              textBlocks {
                ...TextBlockInfo
              }
              mediaBlocks {
                ...MediaBlockInfo
                file {
                  ...FileInfo
                }
              }
              questionBlocks {
                ...QuestionBlockInfo
              }
            }
            submissions {
              ...SubmissionInfo
              student {
                ...StudentInfo
              }
              files {
                ...FileInfo
              }
              feedbackFiles {
                ...FileInfo
              }
            }
          }
          students {
            ...StudentInfo
          }
        }
      }
    }
  }
  ${SUBJECT_INFO_FRAGMENT}
  ${STUDENT_INFO_FRAGMENT}
  ${PARENT_INFO_FRAGMENT}
  ${TEACHER_INFO_FRAGMENT}
  ${RATING_INFO_FRAGMENT}
  ${LESSON_INFO_FRAGMENT}
  ${FILE_INFO_FRAGMENT}
  ${LIVE_SESSION_INFO_FRAGMENT}
  ${RECORDING_INFO_FRAGMENT}
  ${ASSESSMENT_INFO_FRAGMENT}
  ${TEXT_BLOCK_INFO_FRAGMENT}
  ${MEDIA_BLOCK_INFO_FRAGMENT}
  ${QUESTION_BLOCK_INFO_FRAGMENT}
  ${SUBMISSION_INFO_FRAGMENT}
  ${ASSIGNMENT_INFO_FRAGMENT}
  ${ORGANIZATION_INFO_FRAGMENT}
`;

export const CREATE_UNASSIGNED_USERS = gql`
  mutation createUnassignedUsers($input: [UnassignedUserCreateInput!]!) {
    createUnassignedUsers(input: $input) {
      unassignedUsers {
        ...UnassignedUserInfo
        organizations {
          ...OrganizationInfo
        }
      }
    }
  }
  ${ORGANIZATION_INFO_FRAGMENT}
  ${UNASSIGNED_USER_INFO_FRAGMENT}
`;

export const GET_UNASSIGNED_USERS = gql`
  query unassignedUsers($where: UnassignedUserWhere) {
    unassignedUsers(where: $where) {
      ...UnassignedUserInfo
      organizations {
        ...OrganizationInfo
      }
    }
  }
  ${ORGANIZATION_INFO_FRAGMENT}
  ${UNASSIGNED_USER_INFO_FRAGMENT}
`;

export const CREATE_STUDENT = gql`
  mutation createStudents($input: [StudentCreateInput!]!) {
    createStudents(input: $input) {
      students {
        ...StudentInfo
        organizations {
          ...OrganizationInfo
        }
        subjects {
          ...SubjectInfo
          teacher {
            ...TeacherInfo
          }
          ratings {
            ...RatingInfo
            student {
              ...StudentInfo
            }
          }
          units {
            unitId
            title
          }
          lessons {
            ...LessonInfo
            files {
              ...FileInfo
            }
            liveSessions {
              ...LiveSessionInfo
              recording {
                ...RecordingInfo
              }
            }
          }
          assessments {
            ...AssessmentInfo
            files {
              ...FileInfo
            }
            document {
              documentId
              released
              marked
              textBlocks {
                ...TextBlockInfo
              }
              mediaBlocks {
                ...MediaBlockInfo
                file {
                  ...FileInfo
                }
              }
              questionBlocks {
                ...QuestionBlockInfo
              }
            }
            submissions {
              ...SubmissionInfo
              student {
                ...StudentInfo
              }
              files {
                ...FileInfo
              }
              feedbackFiles {
                ...FileInfo
              }
            }
          }
          assignments {
            ...AssignmentInfo
            files {
              ...FileInfo
            }
            document {
              documentId
              released
              marked
              textBlocks {
                ...TextBlockInfo
              }
              mediaBlocks {
                ...MediaBlockInfo
                file {
                  ...FileInfo
                }
              }
              questionBlocks {
                ...QuestionBlockInfo
              }
            }
            submissions {
              ...SubmissionInfo
              student {
                ...StudentInfo
              }
              files {
                ...FileInfo
              }
              feedbackFiles {
                ...FileInfo
              }
            }
          }
          students {
            ...StudentInfo
          }
        }
      }
    }
  }
  ${SUBJECT_INFO_FRAGMENT}
  ${TEACHER_INFO_FRAGMENT}
  ${STUDENT_INFO_FRAGMENT}
  ${RATING_INFO_FRAGMENT}
  ${LESSON_INFO_FRAGMENT}
  ${FILE_INFO_FRAGMENT}
  ${LIVE_SESSION_INFO_FRAGMENT}
  ${RECORDING_INFO_FRAGMENT}
  ${ASSESSMENT_INFO_FRAGMENT}
  ${TEXT_BLOCK_INFO_FRAGMENT}
  ${MEDIA_BLOCK_INFO_FRAGMENT}
  ${QUESTION_BLOCK_INFO_FRAGMENT}
  ${SUBMISSION_INFO_FRAGMENT}
  ${ASSIGNMENT_INFO_FRAGMENT}
  ${ORGANIZATION_INFO_FRAGMENT}
`;

export const GET_STUDENTS = gql`
  query students($where: StudentWhere) {
    students(where: $where) {
      ...StudentInfo
      address {
        ...AddressInfo
      }
      organizations {
        ...OrganizationInfo
      }
      subjects {
        ...SubjectInfo
        teacher {
          ...TeacherInfo
        }
        ratings {
          ...RatingInfo
          student {
            studentId
            firstName
            lastName
            userId
            authPicture
          }
        }
        units {
          unitId
          title
        }
        lessons {
          ...LessonInfo
          files {
            ...FileInfo
          }
          liveSessions {
            ...LiveSessionInfo
            recording {
              ...RecordingInfo
            }
          }
          assessments {
            ...AssessmentInfo
          }
          assignments {
            ...AssignmentInfo
          }
        }
        assessments {
          ...AssessmentInfo
          files {
            ...FileInfo
          }
          document {
            documentId
            released
            marked
            textBlocks {
              ...TextBlockInfo
            }
            mediaBlocks {
              ...MediaBlockInfo
              file {
                ...FileInfo
              }
            }
            questionBlocks {
              ...QuestionBlockInfo
            }
          }
          submissions {
            ...SubmissionInfo
            student {
              ...StudentInfo
            }
            files {
              ...FileInfo
            }
            feedbackFiles {
              ...FileInfo
            }
          }
        }
        assignments {
          ...AssignmentInfo
          files {
            ...FileInfo
          }
          document {
            documentId
            released
            marked
            textBlocks {
              ...TextBlockInfo
            }
            mediaBlocks {
              ...MediaBlockInfo
              file {
                ...FileInfo
              }
            }
            questionBlocks {
              ...QuestionBlockInfo
            }
          }
          submissions {
            ...SubmissionInfo
            student {
              ...StudentInfo
            }
            files {
              ...FileInfo
            }
            feedbackFiles {
              ...FileInfo
            }
          }
        }
        students {
          ...StudentInfo
        }
      }
    }
  }
  ${ADDRESS_INFO_FRAGMENT}
  ${SUBJECT_INFO_FRAGMENT}
  ${TEACHER_INFO_FRAGMENT}
  ${STUDENT_INFO_FRAGMENT}
  ${RATING_INFO_FRAGMENT}
  ${LESSON_INFO_FRAGMENT}
  ${FILE_INFO_FRAGMENT}
  ${LIVE_SESSION_INFO_FRAGMENT}
  ${RECORDING_INFO_FRAGMENT}
  ${ASSESSMENT_INFO_FRAGMENT}
  ${TEXT_BLOCK_INFO_FRAGMENT}
  ${MEDIA_BLOCK_INFO_FRAGMENT}
  ${QUESTION_BLOCK_INFO_FRAGMENT}
  ${SUBMISSION_INFO_FRAGMENT}
  ${ASSIGNMENT_INFO_FRAGMENT}
  ${ORGANIZATION_INFO_FRAGMENT}
`;

export const CREATE_ORGANIZATION = gql`
  mutation createOrganizations($input: [OrganizationCreateInput!]!) {
    createOrganizations(input: $input) {
      organizations {
        organizationId
        title
        type
        logoUrl
      }
    }
  }
`;

export const GET_ORGANIZATIONS = gql`
  query organizations($where: OrganizationWhere) {
    organizations(where: $where) {
      organizationId
      admins {
        userId
      }
      students {
        userId
      }
      teachers {
        userId
      }
      parents {
        userId
      }
    }
  }
`;

// ###########################################
//
// VLEARNED PRODUCT CREATE, READ
//
// ###########################################
export const CREATE_VLEARNED_PRODUCTS = gql`
  mutation CreateVlearnedProducts($input: [VlearnedProductCreateInput!]!) {
    createVlearnedProducts(input: $input) {
      vlearnedProducts {
        ...VlearnedProductInfo
        prices(options: { sort: [{ amount: ASC }] }) {
          ...PriceInfo
        }
      }
    }
  }
  ${VLEARNED_PRODUCT_INFO_FRAGMENT}
  ${PRICE_INFO_FRAGMENT}
`;

export const GET_VLEARNED_PRODUCTS = gql`
  query VlearnedProducts($where: VlearnedProductWhere) {
    vlearnedProducts(where: $where) {
      ...VlearnedProductInfo
    }
  }
  ${VLEARNED_PRODUCT_INFO_FRAGMENT}
`;

// ###########################################
//
// VLEARNED PRODUCT SUBSCRIPTION CREATE, READ
//
// ###########################################
export const CREATE_VLEARNED_PRODUCT_SUBSCRIPTION = gql`
  mutation CreateVlearnedProductSubscriptions(
    $input: [VlearnedProductSubscriptionCreateInput!]!
  ) {
    createVlearnedProductSubscriptions(input: $input) {
      vlearnedProductSubscriptions {
        ...VlearnedProductSubscriptionInfo
      }
    }
  }
  ${VLEARNED_PRODUCT_SUBSCRIPTION_INFO_FRAGMENT}
`;

export const GET_VLEARNED_PRODUCT_SUBSCRIPTIONS = gql`
  query VlearnedProductSubscriptions($where: VlearnedProductSubscriptionWhere) {
    vlearnedProductSubscriptions(where: $where) {
      ...VlearnedProductSubscriptionInfo
    }
  }
  ${VLEARNED_PRODUCT_SUBSCRIPTION_INFO_FRAGMENT}
`;

// ###########################################
//
// PRICE CREATE, READ
//
// ###########################################
export const CREATE_PRICE = gql`
  mutation CreatePrices($input: [PriceCreateInput!]!) {
    createPrices(input: $input) {
      prices {
        ...PriceInfo
      }
    }
  }
  ${PRICE_INFO_FRAGMENT}
`;

export const GET_PRICES = gql`
  query Prices($where: PriceWhere) {
    prices(where: $where) {
      ...PriceInfo
    }
  }
  ${PRICE_INFO_FRAGMENT}
`;

// ###########################################
//
// INVOICE CREATE, READ
//
// ###########################################
export const CREATE_INVOICE = gql`
  mutation createInvoices($input: [InvoiceCreateInput!]!) {
    createInvoices(input: $input) {
      invoices {
        ...InvoiceInfo
      }
    }
  }
  ${INVOICE_INFO_FRAGMENT}
`;

export const GET_INVOICES = gql`
  query invoices($where: InvoiceWhere) {
    invoices(where: $where) {
      ...InvoiceInfo
    }
  }
  ${INVOICE_INFO_FRAGMENT}
`;

// ###########################################
//
// BULK IMPORT CREATE
//
// ###########################################

export const CREATE_BULK_IMPORT = gql`
  mutation CreateBulkImports($input: [BulkImportCreateInput!]!) {
    createBulkImports(input: $input) {
      ...BulkImportInfo
    }
  }
  ${BULK_IMPORT_INFO_FRAGMENT}
`;

// ###########################################
//
// ADMIN CREATE, READ
//
// ###########################################
export const CREATE_ADMIN = gql`
  mutation createAdmins($input: [AdminCreateInput!]!) {
    createAdmins(input: $input) {
      admins {
        ...AdminInfo
        organizations {
          ...OrganizationInfo
        }
      }
    }
  }
  ${ADMIN_INFO_FRAGMENT}
  ${ORGANIZATION_INFO_FRAGMENT}
`;

export const GET_ADMINS = gql`
  query admins($where: AdminWhere) {
    admins(where: $where) {
      ...AdminInfo
      organizations {
        ...OrganizationInfo
        subjects {
          ...SubjectInfo
          teacher {
            ...TeacherInfo
          }
          ratings {
            ...RatingInfo
            student {
              ...StudentInfo
            }
          }
          units {
            unitId
            title
          }
          lessons {
            ...LessonInfo
            files {
              ...FileInfo
            }
            liveSessions {
              ...LiveSessionInfo
              recording {
                ...RecordingInfo
              }
            }
            assessments {
              ...AssessmentInfo
            }
            assignments {
              ...AssignmentInfo
            }
          }
          assessments {
            ...AssessmentInfo
            files {
              ...FileInfo
            }
            document {
              documentId
              released
              marked
              textBlocks {
                ...TextBlockInfo
              }
              mediaBlocks {
                ...MediaBlockInfo
                file {
                  ...FileInfo
                }
              }
              questionBlocks {
                ...QuestionBlockInfo
              }
            }
            submissions {
              ...SubmissionInfo
              student {
                ...StudentInfo
              }
              files {
                ...FileInfo
              }
              feedbackFiles {
                ...FileInfo
              }
            }
          }
          assignments {
            ...AssignmentInfo
            files {
              ...FileInfo
            }
            document {
              documentId
              released
              marked
              textBlocks {
                ...TextBlockInfo
              }
              mediaBlocks {
                ...MediaBlockInfo
                file {
                  ...FileInfo
                }
              }
              questionBlocks {
                ...QuestionBlockInfo
              }
            }
            submissions {
              ...SubmissionInfo
              student {
                ...StudentInfo
              }
              files {
                ...FileInfo
              }
              feedbackFiles {
                ...FileInfo
              }
            }
          }
          students {
            ...StudentInfo
            subjects {
              ...SubjectInfo
              assessments {
                ...AssessmentInfo
                submissions {
                  ...SubmissionInfo
                }
              }
              assignments {
                ...AssignmentInfo
                submissions {
                  ...SubmissionInfo
                }
              }
            }
          }
        }
        admins {
          ...AdminInfo
          organizations {
            ...OrganizationInfo
          }
        }
        students {
          ...StudentInfo
          organizations {
            ...OrganizationInfo
          }
        }
        teachers {
          ...TeacherInfo
          organizations {
            ...OrganizationInfo
          }
        }
        parents {
          ...ParentInfo
          organizations {
            ...OrganizationInfo
          }
        }
        unassignedUsers {
          ...UnassignedUserInfo
          organizations {
            ...OrganizationInfo
          }
        }
      }
    }
  }
  ${ADMIN_INFO_FRAGMENT}
  ${PARENT_INFO_FRAGMENT}
  ${UNASSIGNED_USER_INFO_FRAGMENT}
  ${SUBJECT_INFO_FRAGMENT}
  ${TEACHER_INFO_FRAGMENT}
  ${STUDENT_INFO_FRAGMENT}
  ${RATING_INFO_FRAGMENT}
  ${LESSON_INFO_FRAGMENT}
  ${FILE_INFO_FRAGMENT}
  ${LIVE_SESSION_INFO_FRAGMENT}
  ${RECORDING_INFO_FRAGMENT}
  ${ASSESSMENT_INFO_FRAGMENT}
  ${TEXT_BLOCK_INFO_FRAGMENT}
  ${MEDIA_BLOCK_INFO_FRAGMENT}
  ${QUESTION_BLOCK_INFO_FRAGMENT}
  ${SUBMISSION_INFO_FRAGMENT}
  ${ASSIGNMENT_INFO_FRAGMENT}
  ${ORGANIZATION_INFO_FRAGMENT}
`;

// ###########################################
//
// GET UNIQUE USERS
//
// ###########################################
export const GET_UNIQUE_USERS = gql`
  query GetAllUniqueUsers {
    getAllUniqueUsers {
      email
      userId
    }
  }
`;

// ###########################################
//
// FILE UPLOAD TO GCP
//
// ###########################################
export const UPLOAD_FILE = gql`
  mutation uploadFile($file: Upload!) {
    UploadFile(file: $file) {
      ...FileInfo
    }
  }
  ${FILE_INFO_FRAGMENT}
`;

// ###########################################
//
// FILE DELETION FROM GCP
//
// ###########################################
// Removing the file from the google cloud bucket
export const REMOVE_FILE = gql`
  mutation removeFile($gcName: String) {
    RemoveFile(gcName: $gcName) {
      gcName
    }
  }
`;

// ###########################################
//
// FILE META DATA DELETION FROM NEO4J
//
// ###########################################
// Remove the deleted file's metadata from neo-db
export const DELETE_FILE = gql`
  mutation deleteFiles($where: FileWhere) {
    deleteFiles(where: $where) {
      nodesDeleted
      relationshipsDeleted
    }
  }
`;

// ###########################################
//
// AUTH0 - ASSIGN ROLES TO USER
//
// ###########################################
export const ASSIGN_ROLES_TO_USER = gql`
  mutation assignRolesToUser($roleIds: [String!]!, $userId: String!) {
    assignRolesToUser(roleIds: $roleIds, userId: $userId)
  }
`;

// ###########################################
//
// AUTH0 - GET ALL ROLES
//
// ###########################################
export const GET_ALL_ROLES = gql`
  query getAllRoles {
    getAllRoles {
      description
      id
      name
    }
  }
`;

// ###########################################
//
// AUTH0 - GET USER ROLES
//
// ###########################################
export const GET_USER_ROLES = gql`
  query getUserRoles($userId: String!) {
    getUserRoles(userId: $userId) {
      description
      id
      name
    }
  }
`;

// ###########################################
//
// AUTH0 - CREATE NEW USER
//
// ###########################################
export const CREATE_USER = gql`
  mutation createUser(
    $email: String!
    $password: String!
    $connection: String!
  ) {
    createUser(email: $email, password: $password, connection: $connection) {
      message
      user {
        created_at
        email
        email_verified
        identities {
          connection
          isSocial
          provider
          user_id
        }
        name
        nickname
        picture
        updated_at
        user_id
      }
    }
  }
`;

// ###########################################
//
// AUTH0 - CREATE USER IMPORT
//
// ###########################################
export const CREATE_USER_IMPORT = gql`
  mutation createUserImport($input: [UserInput]) {
    createUserImport(input: $input) {
      connection
      connection_id
      created_at
      id
      status
      type
    }
  }
`;

// ###########################################
//
// AUTH0 - GET_USER
//
// ###########################################
export const GET_USER = gql`
  query getUser($userId: String!) {
    getUser(userId: $userId) {
      created_at
      email
      email_verified
      last_ip
      last_login
      logins_count
      name
      nickname
      picture
      updated_at
      user_id
      identities {
        connection
        isSocial
        provider
        user_id
      }
    }
  }
`;

// ###########################################
//
// AUTH0 - SEND_EMAIL_VERIFICATION
//
// ###########################################
export const SEND_EMAIL_VERIFICATION = gql`
  mutation SendEmailVerification($userId: String!) {
    sendEmailVerification(userId: $userId)
  }
`;

// ###########################################
//
// BBB - CREATE SESSION
//
// ###########################################
export const CREATE_SESSION = gql`
  mutation createSession($input: SessionInput!) {
    createSession(input: $input) {
      attendeeLink
      meetingInfo
      moderatorLink
    }
  }
`;

// ###########################################
//
// BBB - GET MEETING INFO
//
// ###########################################
export const GET_MEETING_INFO = gql`
  query getMeetingInfo($meetingId: String!) {
    getMeetingInfo(meetingId: $meetingId) {
      message
      messageKey
      returncode
    }
  }
`;

// ###########################################
//
// BBB - GET REST RECORDINGS
//
// ###########################################
export const GET_REST_RECORDINGS = gql`
  query getRestRecordings($meetingId: String!) {
    getRestRecordings(meetingId: $meetingId) {
      recordings {
        data
        endTime
        internalMeetingID
        isBreakout
        meetingID
        name
        participants
        published
        rawSize
        recordID
        size
        startTime
        state
        playback {
          format {
            length
            type
            url
          }
        }
      }
      returncode
    }
  }
`;

// ###########################################
//
// BBB - GET REST RECORDINGS
//
// ###########################################
export const JOIN_SESSION = gql`
  mutation JoinSession($input: SessionJoinInput!) {
    joinSession(input: $input) {
      attendeeLink
    }
  }
`;

// ###########################################
//
// BBB - DELETE REST RECORDING
//
// ###########################################
export const DELETE_REST_RECORDING = gql`
  mutation DeleteRestRecording($recordingId: String) {
    deleteRestRecording(recordingId: $recordingId)
  }
`;

// ###########################################
//
// STRIPE - SETUP INTENT
//
// ###########################################
export const SETUP_INTENT = gql`
  mutation SetupIntent($customerId: String!) {
    setupIntent(customerId: $customerId) {
      client_secret
    }
  }
`;

// ###########################################
//
// STRIPE - UPDATE CUSTOMER
//
// ###########################################
export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($customerId: String!, $paymentMethod: String) {
    updateCustomer(customerId: $customerId, paymentMethod: $paymentMethod) {
      id
      invoice_settings {
        custom_fields
        default_payment_method
        footer
        rendering_options
      }
    }
  }
`;

// ###########################################
//
// STRIPE - CREATE SUBSCRIPTION
//
// ###########################################
export const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription($input: CreateSubscriptionInput!) {
    createSubscription(input: $input) {
      cancel_at_period_end
      current_period_end
      customer
      latest_invoice
      status
      metadata {
        policy_id
        title
      }
      items {
        data {
          id
          plan {
            active
            amount
            currency
            interval
            interval_count
          }
        }
        has_more
        object
        url
      }
    }
  }
`;

// ###########################################
//
// STRIPE - FINALIZE INVOICE
//
// ###########################################
export const FINALIZE_INVOICE = gql`
  mutation FinalizeInvoice($invoiceId: String!) {
    finalizeInvoice(invoiceId: $invoiceId) {
      data {
        id
        status
        invoice_pdf
        hosted_invoice_url
        number
        amount_due
        due_date
        lines {
          data {
            period {
              end
              start
            }
          }
        }
      }
    }
  }
`;

// ###########################################
//
// STRIPE - UPDATE SUBSCRIPTION
//
// ###########################################
export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription($input: UpdateSubscriptionInput!) {
    updateSubscription(input: $input) {
      cancel_at_period_end
      current_period_end
      customer
      items {
        has_more
        object
        url
        data {
          id
          plan {
            active
            amount
            currency
            interval
            interval_count
          }
        }
      }
      latest_invoice
      metadata {
        policy_id
        title
      }
    }
  }
`;

// ###########################################
//
// STRIPE - CANCEL SUBSCRIPTION
//
// ###########################################
export const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription($subscriptionId: String!) {
    cancelSubscription(subscriptionId: $subscriptionId) {
      cancel_at_period_end
      current_period_end
      customer
      items {
        has_more
        object
        url
        data {
          id
          plan {
            active
            amount
            currency
            interval
            interval_count
          }
        }
      }
      latest_invoice
      metadata {
        policy_id
        title
      }
    }
  }
`;

// ###########################################
//
// STRIPE - UPDATE PRODUCT
//
// ###########################################
export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      id
      active
      default_price
    }
  }
`;

// ###########################################
//
// STRIPE - CREATE PRODUCT
//
// ###########################################
export const CREATE_PRODUCT = gql`
  mutation CreateProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      active
      default_price
      id
    }
  }
`;

// ###########################################
//
// STRIPE - CREATE PRICE
//
// ###########################################
export const CREATE_REST_PRICE = gql`
  mutation CreateRestPrice($input: CreatePriceInput!) {
    createRestPrice(input: $input) {
      id
      active
      unit_amount_decimal
      currency
      type
      recurring {
        interval
        interval_count
      }
    }
  }
`;

// ###########################################
//
// STRIPE - GET SUBSCRIPTIONS
//
// ###########################################
export const GET_SUBSCRIPTIONS = gql`
  query GetSubscriptions($input: GetSubscriptionsInput) {
    getSubscriptions(input: $input) {
      data {
        id
        cancel_at_period_end
        current_period_end
        customer
        latest_invoice
        status
        items {
          has_more
          object
          url
          data {
            id
            plan {
              id
              active
              amount
              currency
              interval
              interval_count
            }
          }
        }
        metadata {
          policy_id
          title
          quantity
        }
      }
    }
  }
`;

// ###########################################
//
// STRIPE - CREATE_CUSTOMER
//
// ###########################################
export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($name: String, $email: String, $type: String) {
    createCustomer(name: $name, email: $email, type: $type) {
      id
      invoice_settings {
        custom_fields
        default_payment_method
        footer
        rendering_options
      }
    }
  }
`;

// ###########################################
//
// STRIPE - DELETE_CUSTOMER
//
// ###########################################
export const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($customerId: String) {
    deleteCustomer(customerId: $customerId) {
      id
      invoice_settings {
        custom_fields
        default_payment_method
        rendering_options
        footer
      }
    }
  }
`;

// ###########################################
//
// STRIPE - GET_CUSTOMER
//
// ###########################################
export const GET_CUSTOMER = gql`
  query GetCustomer($customerId: String) {
    getCustomer(customerId: $customerId) {
      id
      invoice_settings {
        custom_fields
        default_payment_method
        footer
        rendering_options
      }
    }
  }
`;

// ###########################################
//
// STRIPE - GET_INVOICES
//
// ###########################################
export const GET_REST_INVOICES = gql`
  query GetRestInvoices($customerId: String) {
    getRestInvoices(customerId: $customerId) {
      data {
        id
        status
        invoice_pdf
        hosted_invoice_url
        number
        amount_due
        due_date
        lines {
          data {
            period {
              end
              start
            }
          }
        }
      }
    }
  }
`;

// ###########################################
//
// STRIPE - GET_CHARGES
//
// ###########################################
export const GET_CHARGES = gql`
  query GetCharges($customerId: String) {
    getCharges(customerId: $customerId) {
      data {
        invoice
        receipt_url
      }
    }
  }
`;

// ###########################################
//
// STRIPE - DETACH PAYMENT METHOD
//
// ###########################################
export const DETACH_PAYMENT_METHOD = gql`
  mutation DetachPaymentMethod($paymentMethod: String) {
    detachPaymentMethod(paymentMethod: $paymentMethod) {
      id
    }
  }
`;

// ###########################################
//
// CAREERS - CREATE JOB
//
// ###########################################
export const CREATE_JOB = gql`
  mutation CreateJobs($input: [JobCreateInput!]!) {
    createJobs(input: $input) {
      jobs {
        ...JobInfo
        applicants {
          ...ApplicantInfo
        }
      }
    }
  }
  ${JOB_INFO_FRAGMENT}
  ${APPLICANT_INFO_FRAGMENT}
`;

// ###########################################
//
// CAREERS - GET JOBS
//
// ###########################################
export const GET_JOBS = gql`
  query Jobs($where: JobWhere, $options: JobOptions) {
    jobs(where: $where, options: $options) {
      ...JobInfo
      applicants {
        ...ApplicantInfo
        files {
          ...FileInfo
        }
      }
    }
  }
  ${FILE_INFO_FRAGMENT}
  ${JOB_INFO_FRAGMENT}
  ${APPLICANT_INFO_FRAGMENT}
`;

// ###########################################
//
// CAREERS - UPDATE JOB
//
// ###########################################
export const UPDATE_JOB = gql`
  mutation UpdateJobs(
    $where: JobWhere
    $update: JobUpdateInput
    $create: JobRelationInput
  ) {
    updateJobs(where: $where, update: $update, create: $create) {
      jobs {
        ...JobInfo
      }
    }
  }
  ${JOB_INFO_FRAGMENT}
`;

// ###########################################
//
// CAREERS - DELETE JOB
//
// ###########################################
export const DELETE_JOB = gql`
  mutation DeleteJobs($where: JobWhere) {
    deleteJobs(where: $where) {
      nodesDeleted
      relationshipsDeleted
    }
  }
`;
