//  @flow
import * as React from "react";
import { makeStyles } from "@material-ui/core";
import styles from "./styles";

const useStyles = makeStyles(styles);

type Props = {
  confirmMessage: React.Node,
  requireConfirmInputText: boolean,
  confirmInputText?: string,
};

export default function ConfirmMessage({
  confirmMessage,
  requireConfirmInputText,
  confirmInputText,
}: Props): React.Node {
  const classes = useStyles();
  return (
    <p className={classes.confirmMessage}>
      {confirmMessage}
      {requireConfirmInputText && (
        <>
          <br />
          <br />
          Please type <b>{confirmInputText}</b> to confirm.
        </>
      )}
    </p>
  );
}
