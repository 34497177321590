import * as yup from "yup";

export const userSchema = yup.object().shape({
  email: yup
    .string()
    .email("This must be a valid email")
    .required("The user's email is required"),
  firstName: yup.string().required("Required"),
  middleName: yup.string(),
  lastName: yup.string().required("Required"),
  birthDate: yup.date(),
  gender: yup.string(),
});
