import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

export default function UIOnboardingStarterDialog({
  openStatus,
  handleClose,
  handleOpenTour,
}) {
  return (
    <Dialog
      open={openStatus}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <img
          src="https://res.cloudinary.com/teenkevo-cloud/image/upload/v1658287281/vlearned-beta/vlearned-final-logo_pazz2u_oypnii.webp"
          alt="logo"
          style={{
            width: "140px",
            verticalAlign: "middle",
            border: "0",
            marginBottom: "30px",
          }}
        />
        <h1
          style={{
            fontSize: "20px",
            marginBottom: "20px",
            fontWeight: "bold",
          }}
        >
          Let Vlearned Buddy show you around
        </h1>
        <DialogContentText
          style={{ fontSize: "13px" }}
          id="alert-dialog-description"
        >
          Ready to take a trip around this page? There's a lot to show you.
          We'll have you up and running in no time!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          style={{
            textTransform: "none",
            color: "white",
            background: "black",
          }}
          onClick={handleClose}
          color="primary"
        >
          Not now
        </Button>
        <Button
          style={{
            textTransform: "none",
            color: "white",
            background: "#ff5e00",
          }}
          onClick={handleOpenTour}
          color="primary"
        >
          Let's go
        </Button>
      </DialogActions>
    </Dialog>
  );
}
