// @flow
import * as React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { BiSupport } from "react-icons/bi";
import { Link } from "react-router-dom";
import styles from "./styles";

const useStyles = makeStyles(styles);

type Props = {
  link: string,
  message: string,
  className: string,
};

export default function ButtonWithExternalLink({
  link,
  message,
}: Props): React.Node {
  const classes = useStyles();
  return (
    <Link className={classes.link} to={{ pathname: link }} target="_blank">
      <Button
        startIcon={<BiSupport className={classes.btnStartIcon} />}
        className={`${classes.btn} sixth-step`}
      >
        {message}
      </Button>
    </Link>
  );
}
