import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { TbListDetails } from "react-icons/tb";
import { BsRecordCircle } from "react-icons/bs";
import { StepConnector } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "30px 30px 0px 30px",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  icon: {
    color: "grey",
    fontSize: "25px",
    "&$activeIcon": {
      color: "black",
    },
    "&$completedIcon": {
      color: "#ff5e00",
    },
  },
  activeIcon: {},
  completedIcon: {},

  connector: {
    "&$activeConnector": {
      backgroundColor: "black", // Change to your desired color
    },
    "&$completedConnector": {
      backgroundColor: "#ff5e00", // Change to your desired color
    },
  },
  activeConnector: {},
  completedConnector: {},
}));

export default function SignUpStepper({ steps, getStepContent, activeStep }) {
  const classes = useStyles();

  const LabelIcon = () => {
    if (activeStep === 0) {
      return <BsRecordCircle className={classes.icon} />;
    }
  };

  return (
    <div className={classes.root}>
      <Stepper
        style={{ background: "transparent" }}
        activeStep={activeStep}
        orientation="vertical"
      >
        {steps.map((label, index) => (
          <Step
            key={label}
            connector={<StepConnector style={{ color: "black" }} />}
          >
            <StepLabel
              StepIconProps={{
                classes: {
                  root: classes.icon,
                  active: classes.activeIcon,
                  completed: classes.completedIcon,
                },
              }}
            >
              <p style={{ color: "black", fontSize: "16px" }}>{label}</p>
            </StepLabel>
            <StepContent>
              <p style={{ color: "grey", fontSize: "14px" }}>
                {getStepContent(index)}
              </p>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
