import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Navbar/Sidebar";
import DashboardManagement from "../../pages/SuperAdmin/Dashboard";
import OrganizationManagement from "../../pages/SuperAdmin/OrganizationManagement";
import CourseManagement from "../../pages/SuperAdmin/CourseManagement";
import StudentManagement from "../../pages/SuperAdmin/StudentsManagement";
import InstructorManagement from "../../pages/SuperAdmin/InstructorManagement";
import { useTheme, useMediaQuery, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Cookies from 'js-cookie'
import SingleOrganization from "../../pages/Superadmin pages/singleOrganization";
import UserManagement from "../../pages/SuperAdmin/UserManagement";
import SingleUserComponent from "../../pages/Superadmin pages/single_user";

const styles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        height: '100%',
    },
    sidebar: {
        width: 290,
        backgroundColor: '#111b29',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1000,
        overflowY: 'auto',
        paddingTop: 30,
    },
    // mainPanel: {
    //   marginLeft: 290,
    //   marginTop: 70,
    //   flexGrow: 1,
    //   padding: 20,
    //   transition: 'margin 0.3s ease',
    // },
    mainPanel: {
        marginLeft: 305,
        transition: 'margin 0.3s ease, width 0.3s ease',
        width: 'calc(100% - 320px)',
        alignSelf: 'center',
        marginTop: 90,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            width: '100%',
        },
    },
    mainPanelShift: {
        marginLeft: 0,
    },
    content: {
        padding: 24,
        backgroundColor: '#111b29',
        borderRadius: '0px 8px 8px 0px',
        borderLeft: '2px solid #f4801e',
        height: '100%',
        overflowY: 'auto',
    },
    floatingButton: {
        position: 'fixed',
        bottom: 80,
        right: 20,
        backgroundColor: '#f07e1d',
        color: '#fff',
        zIndex: 1001,
    },
}));

const useStyles = styles;

export default function SuperAdmin() {
    const classes = useStyles();
    const theme = useTheme();
    const [user, setUser] = useState()

    useEffect(() => {
        const user_logged_in = JSON.parse(Cookies.get('user_details'));
        setUser(user_logged_in);
    }, []);

    const [mobileOpen, setMobileOpen] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    const switchRoutes = (
        <Switch>
            <Route exact path={"/super-admin/:USER_ID/dashboard"} component={DashboardManagement} />
            <Route exact path={"/super-admin/:USER_ID/organization-management"} component={OrganizationManagement} />
            <Route exact path={"/super-admin/:USER_ID/organization-management/:ORGANIZATION_ID"} component={SingleOrganization} />

            <Route exact path={"/super-admin/:USER_ID/course-management"} component={CourseManagement} />
            <Route exact path={"/super-admin/:USER_ID/students-management"} component={StudentManagement} />
            <Route exact path={"/super-admin/:USER_ID/instructor-management"} component={InstructorManagement} />

            <Route exact path={"/super-admin/:USER_ID/users-management"} component={UserManagement} />
            <Route exact path={"/super-admin/:USER_ID/users-management/:MANAGED_ID"} component={SingleUserComponent} />
        </Switch>
    );

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(!drawerOpen);
    };

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (!isMobile) {
            setShowSidebar(true);
        }
    }, [isMobile]);

    return (
        <div>
            <ScrollToTop />
            <Navbar open={drawerOpen} handleDrawerToggle={handleDrawerToggle} handleDrawerClose={handleDrawerClose} />
            <Sidebar showSidebar={showSidebar} USER_ID={user?._id} />

            <div className={clsx(classes.mainPanel, { [classes.mainPanelShift]: drawerOpen })}>
                <div className={classes.content}>
                    <div className={classes.container}>{switchRoutes}</div>
                </div>
            </div>

            {/* Floating Button for Mobile */}
            {isMobile && (
                <IconButton className={classes.floatingButton} onClick={toggleSidebar}>
                    {!showSidebar ? <MenuIcon /> : <CloseIcon />}
                    {/* <MenuIcon /> */}
                </IconButton>
            )}
        </div>
    );
}