import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px 10px",
    "& > *": {
      margin: theme.spacing(0),
    },
  },
}));

export default function SharedAvatar({ alt, src, width, height }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Avatar style={{ width, height }} alt={alt} src={src} />
    </div>
  );
}
