const styles = () => ({
  roleCard: {
    width: "160px",
    height: "80px",
    padding: "5px",
    textAlign: "center",
    background: "#111b29",
    "&:hover": {
      boxShadow: " 0px 0px 10px 4px rgba(163,163,163,0.9)",
    },
  },
  roleIcon: {
    marginBottom: "10px",
    fontSize: "30px",
    color: "#ff5e00",
  },
  roleText: {
    fontSize: "15px",
    fontWeight: "300",
    color: "white",
    textTransform: "none",
  },
  roleCardLink: {
    textDecoration: "none",
  },
  accountRoleOrganizations: { marginTop: "10px", fontSize: "14px" },
});

export default styles;
