import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Chip, Grid, Paper } from "@material-ui/core";
import { IoMdArrowForward } from "react-icons/io";
import { Link, useParams } from "react-router-dom";
import { BiCurrentLocation } from "react-icons/bi";
import { SiOpslevel } from "react-icons/si";
import { activeRoute } from "../../utils/activeRoute";
import { AiOutlineSchedule } from "react-icons/ai";

const useStyles = makeStyles((theme) => ({
  btn: {
    textTransform: "none",
    fontSize: "14px",
    background: "#ff5e00",
    color: "white",
    marginTop: "20px",
    "&:hover": {
      background: "#c42800",
    },
  },
  actionTxt: { fontSize: "14px", color: "#757575" },
  actionTxtDiv: {
    marginTop: "20px",
    display: "inline-flex",
    alignItems: "center",
  },
  actionTxtIcon: {
    fontSize: "15px",
    color: "grey",
    margin: "5px 5px 5px 0px",
  },
  subtitle: {
    fontSize: "14px",
    color: "#757575",
  },
  link: {
    color: "#ff5e00",
    textDecoration: "none",
  },
  linkText: { display: "inline-flex" },
  linkIcon: {
    fontSize: "15px",
    color: "#ff5e00",
    margin: "5px",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  flex: {
    display: "flex",
  },
  borderedChip: {
    fontSize: "11px",
    color: "black",
    marginLeft: "10px",
    background: "white",
    border: "1px solid #ff5e00",
  },
  jobDetails: {
    marginTop: "20px",
  },
  jobDetail: {
    marginLeft: "10px",
    fontSize: "14px",
    color: "black",
    fontWeight: "500",
  },
  jobDescription: {
    marginTop: "20px",
    fontSize: "14px",
    color: "black",
    lineHeight: "23px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  applicantsBtn: {
    padding: "10px",
    textTransform: "none",
    width: "140px",
    textAlign: "center",
    marginBottom: "20px",
    background: "purple",
    color: "white",
    "&:hover": {
      background: "purple",
      boxShadow: "2px 7px 15px 0px rgba(128, 128, 128, 0.4)",
    },
  },
  jobDetailIcon: {
    color: "#ff5e00",
    fontSize: "20px",
  },
}));

function JobCard({ job }) {
  const classes = useStyles();

  const { USER_ID } = useParams();

  const {
    id,
    title,
    category,
    station,
    type,
    level,
    description,
    numberOfApplicants,
    url,
    urlMsg,
    target,
    className,
  } = job;

  console.log(job);

  return (
    <Paper
      className={className}
      style={{ padding: "40px", margin: "20px 0 0 0 " }}
    >
      <Grid alignItems="center" container spacing={2}>
        <Grid item xs={12} sm={9} md={9} lg={9}>
          {!activeRoute("/careers") && (
            <Button
              component={Link}
              to={`/admin/${USER_ID}/internal-human-resource/${id}/applicants`}
              className={classes.applicantsBtn}
            >
              {numberOfApplicants}{" "}
              {numberOfApplicants === 1 ? "applicant" : "applicants"}
            </Button>
          )}
          <div className={classes.flex}>
            <p className={classes.title}>{title}</p>
            <Chip
              className={classes.borderedChip}
              label={category.toUpperCase()}
            />
          </div>
          <p className={classes.jobDescription}>
            {description?.substring(0, 600)} . . .
          </p>
          <div className={classes.jobDetails}>
            <Grid alignItems="center" container spacing={2}>
              <Grid item xs={6} sm={3} md={3} lg={2}>
                <div className={classes.flex}>
                  <BiCurrentLocation className={classes.jobDetailIcon} />
                  <p className={classes.jobDetail}>{station}</p>
                </div>
              </Grid>
              <Grid item xs={6} sm={3} md={3} lg={2}>
                <div className={classes.flex}>
                  <AiOutlineSchedule className={classes.jobDetailIcon} />
                  <p className={classes.jobDetail}>{type}</p>
                </div>
              </Grid>
              <Grid item xs={6} sm={3} md={3} lg={2}>
                <div className={classes.flex}>
                  <SiOpslevel className={classes.jobDetailIcon} />
                  <p className={classes.jobDetail}>{level}</p>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid style={{ textAlign: "right" }} item xs={12} sm={3} md={3} lg={3}>
          {target ? (
            <Link
              className={classes.link}
              to={{ pathname: url }}
              target={target}
            >
              <div className={classes.linkText}>
                <p>{urlMsg}</p>
                <IoMdArrowForward className={classes.linkIcon} />
              </div>
            </Link>
          ) : (
            <Link className={classes.link} to={url}>
              <div className={classes.linkText}>
                <p>{urlMsg}</p>
                <IoMdArrowForward className={classes.linkIcon} />
              </div>
            </Link>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default JobCard;
