import React, { useRef, useEffect } from "react";
import { makeStyles, IconButton } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  AiOutlineFileImage,
  AiOutlineFileWord,
  AiOutlineFileExcel,
  AiFillFile,
  AiOutlineFileText,
} from "react-icons/ai";
import { FaRegFilePowerpoint } from "react-icons/fa";
import { VscFilePdf } from "react-icons/vsc";
// import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles({
  root: {
    background: "#e9e9e9",
    padding: "5px",
    marginTop: "10px",
  },
  stagedFile: {
    display: "flex",
    justifyContent: "space-between",
    borderLeft: "3px solid #ff5e00",
    margin: "10px",
    padding: "10px",
    background: "#FFFFFF",
    fontSize: "12px",
    letterSpacing: "1px",
  },
  stagedFileTypeIcon: {
    fontSize: "25px",
    marginRight: "10px",
    color: "grey",
  },
  stagedFileDeleteRoot: {
    width: "20px",
    height: "20px",
  },
  stagedFileDelete: { color: "#ff5e00", fontSize: "20px" },
  dbFileDelete: { color: "#ff5e00", fontSize: "15px" },
  warning: {
    background: "#fffbdd",
    padding: "10px",
    textAlign: "center",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid #d3cfb7",
  },
  link: {
    textDecoration: "none",
    color: "black",
    "&:hover": {
      color: "#ff5e00",
    },
  },
});

function LogoFile({ file, index, handleRemoveFileFromStage }) {
  const classes = useStyles();

  // We have to check if the component is still mounted
  // before setting states in async fns
  const isMountedRef = useRef(true);
  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    []
  );

  const switchFileTypeIcon = (type) => {
    switch (type) {
      case "application/pdf":
        return <VscFilePdf className={classes.stagedFileTypeIcon} />;
      case "text/plain":
        return <AiOutlineFileText className={classes.stagedFileTypeIcon} />;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return <AiOutlineFileWord className={classes.stagedFileTypeIcon} />;
      case "application/vnd.ms-excel":
        return <AiOutlineFileExcel className={classes.stagedFileTypeIcon} />;
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return <FaRegFilePowerpoint className={classes.stagedFileTypeIcon} />;
      case "image/png":
        return <AiOutlineFileImage className={classes.stagedFileTypeIcon} />;
      case "image/jpeg":
        return <AiOutlineFileImage className={classes.stagedFileTypeIcon} />;
      default:
        return <AiFillFile className={classes.stagedFileTypeIcon} />;
    }
  };

  return (
    <div className={classes.stagedFile}>
      <a className={classes.link} href={file.url} target="_blank" rel="noreferrer">
        <div style={{ display: "flex" }}>
          {switchFileTypeIcon(file.type)}
          <p style={{ lineHeight: "25px" }}>{file.name}</p>{" "}
        </div>
      </a>
      {handleRemoveFileFromStage && (
        <IconButton
          onClick={() => {
            handleRemoveFileFromStage(index);
          }}
          className={classes.stagedFileDeleteRoot}
        >
          <RemoveIcon className={classes.stagedFileDelete} />
        </IconButton>
      )}
    </div>
  );
}

export default LogoFile;
