import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { Grid } from "@material-ui/core";

import AccountRole from "./AccountRole";

function AccountRoles({ shownRoles, participatingOrgs }) {
  const accountRoles = shownRoles.map((role) => (
    <Grid key={uuidv4()} item>
      <AccountRole role={role} participatingOrgs={participatingOrgs} />
    </Grid>
  ));

  return (
    <Grid justifyContent="center" alignItems="center" container spacing={4}>
      {accountRoles}
    </Grid>
  );
}

export default AccountRoles;

AccountRoles.propTypes = {
  shownRoles: PropTypes.array.isRequired,
  participatingOrgs: PropTypes.func.isRequired,
};
