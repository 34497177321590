import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { BsPower } from "react-icons/bs";

import useAuth from "../../../../hooks/Authentication/useAuth";
import WrapperCard from "../shared/WrapperCard";
import styles from "./styles";
import CustomRoundButton from "../../../../components/Buttons/CustomRoundButton";
import AccountRoles from "../AccountRoles";

const useStyles = makeStyles(styles);

function AuthCard({
  authStatus,
  authStatusDescription,
  participatingOrgs,
  shownRoles,
  icon,
}) {
  const classes = useStyles();
  const { handleLogout } = useAuth();

  const showAccountRoles = authStatus === "Authorized";

  return (
    <WrapperCard>
      {icon}
      <p className={classes.authStatus}>{authStatus}</p>
      <p className={classes.authStatusDescription}>{authStatusDescription}</p>
      {showAccountRoles && (
        <AccountRoles
          participatingOrgs={participatingOrgs}
          shownRoles={shownRoles}
        />
      )}
      <CustomRoundButton
        icon={<BsPower color="red" />}
        infoText="Logout"
        handleClick={handleLogout}
      />
    </WrapperCard>
  );
}

AuthCard.propTypes = {
  authStatus: PropTypes.string.isRequired,
  authStatusDescription: PropTypes.string.isRequired,
  participatingOrgs: PropTypes.func.isRequired,
  shownRoles: PropTypes.array.isRequired,
};

export default AuthCard;
