import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import styles from "./organizationCardStyle";
import { Avatar, Card, CardHeader, Chip, Grid } from "@material-ui/core";
import { IoIosSchool } from "react-icons/io";
import { AiOutlineAppstore } from "react-icons/ai";

const useStyles = makeStyles(styles);

export default function OrganizationCard({ organization, linkTo }) {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Button component={Link} to={linkTo} className={classes.bigButton}>
        <Card style={{ background: "#111B29", color: "white" }} elevation={3}>
          <CardHeader
            style={{ textAlign: "left" }}
            avatar={
              <Avatar
                style={{
                  width: "60px",
                  height: "60px",
                  boxShadow: " 0px 0px 10px 2px rgba(0,0,0,0.25)",
                }}
                alt={organization?.logoUrl}
                src={
                  organization?.logoUrl ||
                  "https://storage.googleapis.com/welearned-bucket/logoPlaceholder.png"
                }
              />
            }
            title={organization?.title}
            titleTypographyProps={{ variant: "subtitle" }}
            subheader={
              <Chip
                style={{
                  fontSize: "11px",
                  color: "#ff5e00",
                  marginTop: "10px",
                  marginRight: "10px",
                  background: "white",
                }}
                icon={
                  organization?.type === "Corporate" ? (
                    <AiOutlineAppstore
                      fontSize="large"
                      style={{ color: "#ff5e00" }}
                    />
                  ) : (
                    <IoIosSchool
                      fontSize="large"
                      style={{ color: "#ff5e00" }}
                    />
                  )
                }
                label={organization?.type}
              />
            }
          />
        </Card>
      </Button>
    </Grid>
  );
}
