import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
// core components
import ProfilePopper from "../ProfilePopper/ProfilePopper";
import styles from "./navbarStyle";
import ButtonWithExternalLink from "../ButtonWithExternalLink/ButtonWithExternalLink";

const useStyles = makeStyles(styles);

export default function Navbar(props) {
  const classes = useStyles();

  const { logoUrl, title, type } = props.organization || {};

  return (
    <AppBar
      className={clsx(classes.appBar, {
        [classes.appBarShift]: props.open,
      })}
    >
      <Toolbar className={classes.container}>
        <div style={{ display: "flex", flexGrow: 1, marginLeft: "10px" }}>
          {!props.organization && (
            <img
              src="https://res.cloudinary.com/teenkevo-cloud/image/upload/v1658287281/vlearned-beta/vlearned-final-logo_pazz2u_oypnii.webp"
              alt="logo"
              className={classes.img}
            />
          )}
          {logoUrl && (
            <Hidden xsDown implementation="css">
              <img
                src={
                  logoUrl ||
                  "https://storage.googleapis.com/welearned-bucket/logoPlaceholder.png"
                }
                style={{
                  justifyContent: "center",
                  border: "1px grey solid",
                  maxHeight: "70px",
                  maxWidth: "70px",
                  borderRadius: "70px",
                }}
              />
            </Hidden>
          )}

          <div
            style={{
              padding: "10px",
              display: "table",
            }}
          >
            <div
              style={{
                display: "table-cell",
                verticalAlign: "middle",
              }}
            >
              <p
                style={{
                  color: "#FFFFFF",
                  marginBottom: "5px",
                  fontWeight: "800",
                  fontSize: "15px",
                  color: "#ff903e",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {title}
              </p>
              <p style={{ fontSize: "12px" }}>{type}</p>
            </div>
          </div>
        </div>

        <Hidden xsDown implementation="css">
          <ButtonWithExternalLink
            link="https://vlearned.uvdesk.com/apps/form-builder/en/form/html/130326399e0a29119d6399e0a29124a"
            message="Contact Support"
          />
        </Hidden>

        <ProfilePopper organization={props.organization} />
      </Toolbar>
    </AppBar>
  );
}

Navbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};
