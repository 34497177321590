import cc from "currency-codes";

export const levelOptions = [
  { value: "Primary", label: "Primary" },
  { value: "Secondary", label: "Secondary" },
  { value: "University", label: "University" },
];
export const curriculumOptions = [
  { value: "IGCSE", label: "IGCSE" },
  { value: "SPM", label: "SPM" },
  { value: "IB", label: "IB" },
  { value: "SAT", label: "SAT" },
  { value: "UNEB", label: "UNEB" },
];

export const trainingTypeOptions = [
  { value: "Onboarding and orientation", label: "Onboarding and orientation" },
  { value: "Compliance training", label: "Compliance training" },
  { value: "Hard skills training", label: "Hard skills training" },
  { value: "Soft skills training", label: "Soft skills training" },
  { value: "Product knowledge training", label: "Product knowledge training" },
];

export const levelTypeOptions = [
  { value: "Grade", label: "Grade" },
  { value: "Form", label: "Form" },
  { value: "Year", label: "Year" },
];

export const organizationTypeOptions = [
  { value: "Corporate", label: "Corporate" },
  { value: "School", label: "School" },
];

export const yearOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
];

export const difficultyOptions = [
  { value: "Easy", label: "Easy" },
  { value: "Medium", label: "Medium" },
  { value: "Hard", label: "Hard" },
];
export const questionTypeOptions = [
  { value: "Multiple Choice", label: "Multiple-choice" },
  { value: "Essay", label: "Essay" },
  { value: "True/False", label: "True/false" },
];
export const typeOptions = [
  { value: "Individual", label: "Individual" },
  { value: "Group", label: "Group" },
];
export const specializationOptions = [
  { value: "Sciences", label: "Sciences" },
  { value: "Arts", label: "Arts" },
];
export const forumCategoryOptions = [
  {
    categoryType: { value: "All categories", label: "All categories" },
  },
  {
    categoryType: {
      value: "weLEARNed community",
      label: "weLEARNed community",
    },
    indicatorColor: "violet",
    description:
      "General discussion about weLEARNed, this community forum (what it is, how we can improve it), news, product announcements,upcoming changes, and more.",
    subCategories: [
      {
        categoryType: {
          value: "Getting Started videos",
          label: "Getting Started videos",
        },
        description:
          "We shall be continuously providing useful videos help you solve some of the common issues. Give us your feedback to keep improving them.",
        indicatorColor: "violet",
        accessPath: `/student/forum/welearned-community/getting-started-videos`,
      },
      {
        categoryType: { value: "Events", label: "Events" },
        description:
          "Want to take part in exciting educational competitions, hackathons and many more organized throughtout the weLEARNed community?",
        indicatorColor: "violet",
        accessPath: `/student/forum/welearned-community/events`,
      },
      {
        categoryType: {
          value: "Dev-team announcements",
          label: "Dev-team announcements",
        },
        description:
          "We use this category to announce new features, bug fixes and upcoming releases. ",
        indicatorColor: "violet",
        accessPath: `/student/forum/welearned-community/dev-team-announcements`,
      },
    ],
  },
  {
    categoryType: { value: "General", label: "General" },
    indicatorColor: "blue",
    description:
      "General discussion around using weLEARNed and problems you may encounter while using it",
    subCategories: [],
  },
  {
    categoryType: { value: "Feedback", label: "Feedback" },
    indicatorColor: "orange",
    description: "Submit and vote on product feedback and feature requests.",
    subCategories: [],
  },
  {
    categoryType: { value: "Instructor Announcements", label: "Announcements" },
    indicatorColor: "green",
    description:
      "Teachers this category to broadcast messages to their enrolled parties (students and parents)",
    subCategories: [
      {
        categoryType: { value: "Content Errata", label: "Content Errata" },
        description:
          "Here, teachers publish annoucements related to errors with the teaching material and any updates regarding the corrections.",
        indicatorColor: "green",
        accessPath: `/student/forum/announcements/content-errata`,
      },
      {
        categoryType: {
          value: "Assignments or Assessments",
          label: "Assignments or Assessments",
        },
        description:
          "Teachers use this cateogry to provide updates to upcoming assignments or assessments",
        indicatorColor: "green",
        accessPath: `/student/forum/announcements/assignments-or-assessments`,
      },
      {
        categoryType: { value: "Resources", label: "Resources" },
        description:
          "This is where useful resources (books, publications etc) will be shared both by students and teachers.",
        indicatorColor: "green",
        accessPath: `/student/forum/announcements/resources`,
      },
    ],
  },
  {
    categoryType: { value: "Issues", label: "Issues" },
    indicatorColor: "red",
    description:
      "Students use this category to publish any issues related to their class content.",
    subCategories: [
      {
        categoryType: { value: "Content Errata", label: "Content Errata" },
        description:
          "Students use this category to raise issues related to errors with the learning material and request for updates regarding the corrections.",
        indicatorColor: "red",
        accessPath: `/student/forum/issues/content-errata`,
      },
      {
        categoryType: {
          value: "Assignments or Assessments",
          label: "Assignments or Assessments",
        },
        description:
          "Students can make inquiries about their upcoming or past assessments and assignments.",
        indicatorColor: "red",
        accessPath: `/student/forum/issues/assignments-or-assessments`,
      },
      {
        categoryType: {
          value: "General Issues",
          label: "General Issues",
        },
        description:
          "This is where students and teachers and students can have discussions regarding any general issues faced during the learning process.",
        indicatorColor: "red",
        accessPath: `/student/forum/issues/getting-started-videos`,
      },
    ],
  },
  {
    categoryType: { value: "FAQs", label: "FAQs" },
    indicatorColor: "yellow",
    description:
      "A list of commonly created topics in the community, curated with the best response available along with resources for further reading.",
    subCategories: [],
  },
];

export const forumCategoryTagOptions = [
  { value: "all-tags", label: "all-tags" },
  { value: "no-tags", label: "no-tags" },
  { value: "maths", label: "maths" },
  { value: "algebra", label: "algebra" },
  { value: "trigonometry", label: "trigonometry" },
  { value: "matrices", label: "matrices" },
];

export const connectionOptions = [
  {
    value: "Username-Password-Authentication",
    label: "Username-Password-Authentication",
  },
];

export const currencyOptions = [
  {
    value: "USD",
    label: "USD",
  },
  {
    value: "MYR",
    label: "MYR",
  },
];

export const billingCycleOptions = [
  {
    value: "Every month",
    label: "Every month",
  },
  {
    value: "Every 6 months",
    label: "Every 6 months",
  },
  {
    value: "Every year",
    label: "Every year",
  },
];

export const intervalOptions = [
  {
    value: "days",
    label: "days",
  },
  {
    value: "weeks",
    label: "weeks",
  },
  {
    value: "months",
    label: "months",
  },
];

export const cycleOptions = [
  {
    value: "Recurring",
    label: "Recurring",
  },
  {
    value: "One-time",
    label: "One-time",
  },
];

export const genderOptions = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
  {
    value: "Binary",
    label: "Binary",
  },
  {
    value: "Non-binary",
    label: "Non-binary",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const activationStatus = [
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Deactivated",
    label: "Deactivated",
  },
];

export const trainingStatus = [
  {
    value: "Complete",
    label: "Complete",
  },
  {
    value: "Incomplete",
    label: "Incomplete",
  },
];

export const teachingMethodOptions = [
  {
    value: "Hybrid",
    label: "Hybrid",
  },
  {
    value: "Physical",
    label: "Physical",
  },
  {
    value: "Online",
    label: "Online",
  },
];

export const frequencyOptions = [
  {
    value: "Daily",
    label: "Daily",
  },
  {
    value: "Weekly",
    label: "Weekly",
  },
  {
    value: "Monthly",
    label: "Monthly",
  },
  {
    value: "Yearly",
    label: "Yearly",
  },
];

export const frequencyIntervalOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
  { value: "32", label: "32" },
  { value: "33", label: "33" },
  { value: "34", label: "34" },
  { value: "35", label: "35" },
  { value: "36", label: "36" },
  { value: "37", label: "37" },
  { value: "38", label: "38" },
  { value: "39", label: "39" },
  { value: "40", label: "40" },
  { value: "41", label: "41" },
  { value: "42", label: "42" },
  { value: "43", label: "43" },
  { value: "44", label: "44" },
  { value: "45", label: "45" },
  { value: "46", label: "46" },
  { value: "47", label: "47" },
  { value: "48", label: "48" },
  { value: "49", label: "49" },
  { value: "50", label: "50" },
  { value: "51", label: "51" },
  { value: "52", label: "52" },
  { value: "53", label: "53" },
  { value: "54", label: "54" },
  { value: "55", label: "55" },
  { value: "56", label: "56" },
  { value: "57", label: "57" },
  { value: "58", label: "58" },
  { value: "59", label: "59" },
  { value: "60", label: "60" },
  { value: "61", label: "61" },
  { value: "62", label: "62" },
  { value: "63", label: "63" },
  { value: "64", label: "64" },
  { value: "65", label: "65" },
  { value: "66", label: "66" },
  { value: "67", label: "67" },
  { value: "68", label: "68" },
  { value: "69", label: "69" },
  { value: "70", label: "70" },
  { value: "71", label: "71" },
  { value: "72", label: "72" },
  { value: "73", label: "73" },
  { value: "74", label: "74" },
  { value: "75", label: "75" },
  { value: "76", label: "76" },
  { value: "77", label: "77" },
  { value: "78", label: "78" },
  { value: "79", label: "79" },
  { value: "80", label: "80" },
  { value: "81", label: "81" },
  { value: "82", label: "82" },
  { value: "83", label: "83" },
  { value: "84", label: "84" },
  { value: "85", label: "85" },
  { value: "86", label: "86" },
  { value: "87", label: "87" },
  { value: "88", label: "88" },
  { value: "89", label: "89" },
  { value: "90", label: "90" },
  { value: "91", label: "91" },
  { value: "92", label: "92" },
  { value: "93", label: "93" },
  { value: "94", label: "94" },
  { value: "95", label: "95" },
  { value: "96", label: "96" },
  { value: "97", label: "97" },
  { value: "98", label: "98" },
  { value: "99", label: "99" },
];

export const allCurrencyOptions = cc.codes().map((code) => {
  return { value: code, label: code };
});

export const salesAgentOptions = [
  { value: "Self Signup", label: "Self Signup" },
  { value: "Sales Agent 01", label: "Sales Agent 01" },
  { value: "Sales Agent 02", label: "Sales Agent 02" },
  { value: "Sales Agent 03", label: "Sales Agent 03" },
  { value: "Sales Agent 04", label: "Sales Agent 04" },
  { value: "Sales Agent 05", label: "Sales Agent 05" },
  { value: "Sales Agent 06", label: "Sales Agent 06" },
  { value: "Sales Agent 07", label: "Sales Agent 07" },
  { value: "Sales Agent 08", label: "Sales Agent 08" },
  { value: "Sales Agent 09", label: "Sales Agent 09" },
  { value: "Sales Agent 10", label: "Sales Agent 10" },
  { value: "Sales Agent 11", label: "Sales Agent 11" },
  { value: "Sales Agent 12", label: "Sales Agent 12" },
  { value: "Sales Agent 13", label: "Sales Agent 13" },
  { value: "Sales Agent 14", label: "Sales Agent 14" },
  { value: "Sales Agent 15", label: "Sales Agent 15" },
  { value: "Sales Agent 16", label: "Sales Agent 16" },
  { value: "Sales Agent 17", label: "Sales Agent 17" },
  { value: "Sales Agent 18", label: "Sales Agent 18" },
  { value: "Sales Agent 19", label: "Sales Agent 19" },
  { value: "Sales Agent 20", label: "Sales Agent 20" },
  { value: "Sales Agent 21", label: "Sales Agent 21" },
  { value: "Sales Agent 22", label: "Sales Agent 22" },
  { value: "Sales Agent 23", label: "Sales Agent 23" },
  { value: "Sales Agent 24", label: "Sales Agent 24" },
  { value: "Sales Agent 25", label: "Sales Agent 25" },
  { value: "Sales Agent 26", label: "Sales Agent 26" },
  { value: "Sales Agent 27", label: "Sales Agent 27" },
  { value: "Sales Agent 28", label: "Sales Agent 28" },
  { value: "Sales Agent 29", label: "Sales Agent 29" },
  { value: "Sales Agent 30", label: "Sales Agent 30" },
  { value: "Sales Agent 31", label: "Sales Agent 31" },
  { value: "Sales Agent 32", label: "Sales Agent 32" },
  { value: "Sales Agent 33", label: "Sales Agent 33" },
  { value: "Sales Agent 34", label: "Sales Agent 34" },
  { value: "Sales Agent 35", label: "Sales Agent 35" },
  { value: "Sales Agent 36", label: "Sales Agent 36" },
  { value: "Sales Agent 37", label: "Sales Agent 37" },
  { value: "Sales Agent 38", label: "Sales Agent 38" },
  { value: "Sales Agent 39", label: "Sales Agent 39" },
  { value: "Sales Agent 40", label: "Sales Agent 40" },
];

export const jobCategoryOptions = [
  { value: "General", label: "General" },
  { value: "Engineering", label: "Engineering" },
  { value: "Sales", label: "Sales" },
  { value: "Marketing", label: "Marketing" },
  { value: "Design", label: "Design" },
  { value: "Customer Success", label: "Customer Success" },
];

export const jobStationOptions = [
  { value: "On-Site", label: "On-Site" },
  { value: "Remote", label: "Remote" },
  { value: "Hybrid", label: "Hybrid" },
];

export const jobTypeOptions = [
  { value: "Full Time", label: "Full Time" },
  { value: "Part Time", label: "Part Time" },
];

export const jobLevelOptions = [
  { value: "Entry-Level", label: "Entry-Level" },
  { value: "Mid-Level", label: "Mid-Level" },
  { value: "Senior-Level", label: "Senior-Level" },
];
