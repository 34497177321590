import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import CircleSpinner from "../../components/CircleSpinner/CircleSpinner";
import AuthCard from "../../features/Auth/components/AuthCard";
import useGetParticipation from "../../hooks/Authentication/useGetParticipation";

const useStyles = makeStyles(() => ({
  root: {
    backgroundImage: "linear-gradient(to right, #ff5e00, purple)",
    minHeight: "100vh",
    minWidth: "100vw",
  },
}));

function AuthRolesPage() {
  const classes = useStyles();

  const {
    activeRoles,
    getParticipationLoading,
    meta,
    organizationsInActiveRole,
  } = useGetParticipation();

  if (getParticipationLoading) return <CircleSpinner />;

  return (
    <div className={classes.root}>
      <AuthCard
        authStatus={meta.authStatus}
        authStatusDescription={meta.authStatusDescription}
        icon={meta.icon}
        participatingOrgs={organizationsInActiveRole}
        shownRoles={activeRoles}
      />
    </div>
  );
}

export default AuthRolesPage;
