import React from "react";
import { Grid, makeStyles, Backdrop, Card } from "@material-ui/core";
import CircleSpinner from "../CircleSpinner/CircleSpinner";
import styles from "./creatorCardStyle";
import { BsInfoSquareFill } from "react-icons/bs";
import Upload from "../FileUpload/Upload";
import StagedFileList from "../FileUpload/StagedFileList";
import SharedTextField from "../SharedTextField/SharedTextField";

const useStyles = makeStyles(styles);

function ApplicantCreator(props) {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card
          style={{
            display: "flex",
            borderLeft: "3px solid #ff5e00",
            padding: "10px",
          }}
        >
          <BsInfoSquareFill
            style={{ marginRight: "10px" }}
            fontSize="15px"
            color="#ff5e00"
          />
          <p style={{ fontSize: "12px", color: "grey" }}>
            Required fields are marked with *
          </p>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <label className={classes.inputLabel}>First name *</label>
        <SharedTextField
          fullWidth
          variant="outlined"
          id="firstName"
          type="text"
          autoFocus
          defaultValue={props.firstName}
          onChange={props.onChangeFirstName}
          className={classes.input}
          error={props.firstNameError}
          onBlur={props.onBlur}
        />
        <p className={classes.errorMsg}>{props.firstNameHelperText}</p>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <label className={classes.inputLabel}>Middle name</label>
        <SharedTextField
          fullWidth
          variant="outlined"
          id="middleName"
          type="text"
          defaultValue={props.middleName}
          onChange={props.onChangeMiddleName}
          className={classes.input}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <label className={classes.inputLabel}>Last name *</label>
        <SharedTextField
          fullWidth
          variant="outlined"
          id="lastName"
          type="text"
          defaultValue={props.lastName}
          onChange={props.onChangeLastName}
          className={classes.input}
          error={props.lastNameError}
          onBlur={props.onBlur}
        />
        <p className={classes.errorMsg}>{props.lastNameHelperText}</p>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <label className={classes.inputLabel}>Email *</label>
        <SharedTextField
          fullWidth
          variant="outlined"
          id="email"
          type="email"
          autoComplete="off"
          defaultValue={props.email}
          onChange={props.onChangeEmail}
          className={classes.input}
          error={props.emailError}
          onBlur={props.onBlur}
        />
        <p className={classes.errorMsg}>{props.emailHelperText}</p>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <label className={classes.inputLabel}>Phone Number *</label>
        <SharedTextField
          fullWidth
          variant="outlined"
          id="phoneNumber"
          type="text"
          autoComplete="off"
          defaultValue={props.phoneNumber}
          onChange={props.onChangePhoneNumber}
          className={classes.input}
          error={props.phoneNumberError}
          onBlur={props.onBlur}
        />
        <p className={classes.errorMsg}>{props.phoneNumberHelperText}</p>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <label className={classes.inputLabel}>Location (City) *</label>
        <SharedTextField
          fullWidth
          variant="outlined"
          id="location"
          type="text"
          autoComplete="off"
          defaultValue={props.city}
          onChange={props.onChangeCity}
          className={classes.input}
          error={props.cityError}
          onBlur={props.onBlur}
        />
        <p className={classes.errorMsg}>{props.cityHelperText}</p>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <label className={classes.inputLabel}>Github URL</label>
        <SharedTextField
          fullWidth
          variant="outlined"
          id="github"
          type="text"
          autoComplete="off"
          defaultValue={props.github}
          onChange={props.onChangeGithub}
          className={classes.input}
          error={props.githubError}
          onBlur={props.onBlur}
        />
        <p className={classes.errorMsg}>{props.githubHelperText}</p>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <label className={classes.inputLabel}>LinkedIn URL</label>
        <SharedTextField
          fullWidth
          variant="outlined"
          id="linkedIn"
          type="text"
          autoComplete="off"
          defaultValue={props.linkedIn}
          onChange={props.onChangeLinkedIn}
          className={classes.input}
          error={props.linkedInError}
          onBlur={props.onBlur}
        />
        <p className={classes.errorMsg}>{props.linkedInHelperText}</p>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <label className={classes.inputLabel}>Website</label>
        <SharedTextField
          fullWidth
          variant="outlined"
          id="website"
          type="text"
          autoComplete="off"
          defaultValue={props.website}
          onChange={props.onChangeWebsite}
          className={classes.input}
          error={props.websiteError}
          onBlur={props.onBlur}
        />
        <p className={classes.errorMsg}>{props.websiteHelperText}</p>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <label className={classes.inputLabel}>
          Why do you want to work at Vlearned? *
        </label>
        <textarea
          id="whyWorkHere"
          value={props.whyWorkHere}
          placeholder="Add your text here"
          onChange={props.onChangeWhyWorkHere}
          className={classes.largeInput}
          onBlur={props.onBlur}
        />
        <p className={classes.errorMsg}>{props.whyWorkHereHelperText}</p>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <label className={classes.inputLabel}>
          Why do you feel you would be a fit for this role? *
        </label>
        <textarea
          id="whyFitRole"
          value={props.whyFitRole}
          placeholder="Add your text here"
          onChange={props.onChangeWhyFitRole}
          className={classes.largeInput}
          onBlur={props.onBlur}
        />
        <p className={classes.errorMsg}>{props.whyFitRoleHelperText}</p>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <label className={classes.inputLabel}>
          Resume/CV and any other important documents *
        </label>
        <label className={classes.inputLabelSecondary}>
          Only pdf formats are supported. A maximium of 3 extra documents is
          allowed. The resume/cv should be named resume.pdf or cv.pdf. Other
          supporting documents should be given self-descriptive names such as
          academic_documents.pdf, certifications.pdf
        </label>
        <Upload
          accept=".pdf"
          maxFiles={3}
          handleAddFilesToStage={props.handleAddFilesToStage}
        />
        <StagedFileList
          stagedFilesForUpload={props.stagedFilesForUpload}
          handleRemoveFileFromStage={props.handleRemoveFileFromStage}
        />
      </Grid>
      {props.backdropOpen && (
        <Backdrop className={classes.backdrop} open={props.backdropOpen}>
          <CircleSpinner />
        </Backdrop>
      )}
    </Grid>
  );
}

export default ApplicantCreator;
