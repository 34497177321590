import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

import IconBtn from "../IconBtn/IconBtn";
import styles from "./styles";

const useStyles = makeStyles(styles);

function CustomRoundButton({ handleClick, infoText, icon }) {
  const classes = useStyles();

  return (
    <div className={classes.roundButtonWrapper}>
      <div>
        <IconBtn handleClick={handleClick} icon={icon} />
      </div>
      <p className={classes.roundButtonInfoText}>{infoText}</p>
    </div>
  );
}

CustomRoundButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  infoText: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
};

export default CustomRoundButton;
