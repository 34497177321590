import { TextField, Button, Container, Box, Snackbar, CircularProgress } from '@material-ui/core';
import logo from '../../assets/vlearned-final-logo.webp'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import { useState } from 'react';
import axios from 'axios';
import { Alert } from '@material-ui/lab';

const Forgotpassword = () => {
  const api_link = process.env.REACT_APP_API_LINK_VLEARNED
  const [email, setEmail] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setLoading(true);

    var options = {
      method: 'POST',
      url: `${api_link}/users/forgot-password`,
      data: { email: email }
    };

    axios.request(options).then(function (response) {
      if (response.data.status === 200) {
        console.log(response.data);
        setLoading(false);
        handleClick();

        localStorage.setItem('new_password_email', email);
        localStorage.setItem('new_password_user_id', response.data.data.user_id);
        setErrorMessage('Success! check your email for the token.');
        setTimeout(() => {
          window.location.href = "/reset-password";
        }, 2000);
      } else {
        console.log(response);
        setErrorMessage(`Error: ${response.data.error}`);
        setOpen(true);
        setLoading(false);
      }
    }).catch(function (error) {
      setLoading(false);
      setErrorMessage('Error: ' + error.response.data.error);
      setOpen(true);
    });
  }

  return (
    <Container maxWidth="sm" className='container-flex'>
      <Box height={500} width={'100%'} my={4} display="flex" alignItems="center" gap={4} p={2} alignSelf="center">
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={errorMessage.startsWith('Error:') ? 'error' : 'success'} id='errorMessage'>
            {errorMessage}
          </Alert>
        </Snackbar>

        <form className='account-bg-white'>
          <Link to='/'><center><img src={logo} alt="logo" width={240} /></center></Link>

          <p className='subhead'>Enter email to get token.</p>

          <TextField type='email' id="outlined-basic" label="Email" required variant="outlined" className='w-100' onChange={(e) => setEmail(e.target.value)} />

          {loading ? (
            <Button variant="contained" color="primary" className='mt-7' disabled>
              <CircularProgress size={24} />
            </Button>
          ) : (
            <Button variant="contained" onClick={handleForgotPassword} color="primary" className='mt-7'>Get token</Button>
          )}

          <p className='option'>I have an account? <Link to='/login' className='link text-danger'>Login</Link></p>
        </form>
      </Box>
    </Container>
  )
}

export default Forgotpassword