import React from "react";
import { Button, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { BsExclamationOctagon } from "react-icons/bs";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    minHeight: "100vh",
  },
  outerCard: {
    width: "600px",
    padding: "30px",
    margin: "10px",
    textAlign: "center",
    borderRadius: "20px",
  },
  title: {
    justifyContent: "center",
    marginTop: "20px",
    marginBottom: "20px",
    fontSize: "50px",
  },
  subTitle: { color: "grey", marginBottom: "20px" },
  messageCard: {
    background: "#fffbdd",
    padding: "15px",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid #d3cfb7",
    borderLeft: "6px solid rgb(255, 94, 0)",
  },
  button: {
    width: "180px",
    color: "white",
    background: "#9076d1",
    marginLeft: "16px",
    marginTop: "10px",
    marginBottom: "16px",
    textTransform: "none",
    "&:hover": {
      background: "#604a9f",
    },
  },
  goHomeButton: {
    width: "180px",
    color: "white",
    background: "#ff903e",
    marginLeft: "16px",
    marginTop: "10px",
    marginBottom: "16px",
    textTransform: "none",
    "&:hover": {
      background: "#ff5e00",
    },
  },

  dangerButton: {
    width: "180px",
    fontWeight: "900",
    border: "1px solid #d3cfb7",
    color: "#ef5350",
    marginLeft: "16px",
    marginTop: "10px",
    marginBottom: "16px",
    textTransform: "none",
    "&:hover": {
      color: "white",
      background: "#E01815",
    },
  },
}));

function RequestErrorPage() {
  const classes = useStyles();

  return (
    <div
      style={{
        backgroundImage: "linear-gradient(to right, #ff5e00, purple)",
        minHeight: "100vh",
        minWidth: "100vw",
      }}
    >
      <div className={classes.root}>
        <Card className={classes.outerCard}>
          <BsExclamationOctagon style={{ color: "red", fontSize: "60px" }} />
          <div className={classes.title}>Oops!</div>
          <p className={classes.subTitle}>
            There was a problem with your request
          </p>
          <Button
            aria-label="info-button"
            className={classes.goHomeButton}
            // TODO: substitute with real support email later
            href={window.location.origin}
          >
            Take me home
          </Button>
        </Card>
      </div>
    </div>
  );
}

export default RequestErrorPage;
