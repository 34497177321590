import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import styles from "./creatorCardStyle";
import { useTranslation } from "react-i18next";
import LogoFileList from "../FileUpload/LogoFileList";
import LogoUpload from "../FileUpload/LogoUpload";

const useStyles = makeStyles(styles);

function OrganizationCreatorDialogBody2(props) {
  const classes = useStyles();

  const { t } = useTranslation(["organizations_page"]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <label className={classes.inputLabel}>
          {t("organization_creator_dialog.input_label_type_logo")}
        </label>
        {props.isEditingOrganization && (
          <>
            <label className={classes.inputLabelSecondary}>
              {t("organization_creator_dialog.input_label_logo_current")}
            </label>
            <img
              src={props.currentLogoSrc}
              alt=""
              style={{
                border: "1px grey dotted",
                justifyContent: "center",
                // margin: "10px",
                maxHeight: "70px",
                maxWidth: "70px",
                borderRadius: "70px",
              }}
            />
          </>
        )}
        <LogoUpload
          handleAddFilesToStage={props.handleAddFilesToStage}
          accept="image/*"
          maxSize={20000000}
          maxFiles={1}
        />

        {props.stagedFilesForUpload.length > 0 && (
          <>
            <label className={classes.inputLabelSecondary}>
              {t("organization_creator_dialog.input_label_logo_new")}
            </label>
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Grid item xs={12} sm={2} md={2} lg={2}>
                <img
                  src={props.src}
                  alt=""
                  style={{
                    border: "1px grey dotted",
                    justifyContent: "center",
                    // margin: "10px",
                    maxHeight: "70px",
                    maxWidth: "70px",
                    borderRadius: "70px",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={10} lg={10}>
                <LogoFileList
                  stagedFilesForUpload={props.stagedFilesForUpload}
                  handleRemoveFileFromStage={props.handleRemoveFileFromStage}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default OrganizationCreatorDialogBody2;
