import React, { useState } from 'react'
import { TextField, Button, Container, Box, InputAdornment, IconButton, Snackbar, CircularProgress } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import logo from '../../assets/vlearned-final-logo.webp'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Cookies from 'js-cookie';

import axios from 'axios';
import { Alert } from '@material-ui/lab';

const Login = () => {
  const api_link = process.env.REACT_APP_API_LINK_VLEARNED
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const [loading, setLoading] = useState(false);
  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);

    var options = {
      method: 'POST',
      url: `${api_link}/users/login`,
      data: { email: email, password: password }
    };

    axios.request(options).then(function (response) {
      if (response.data.status === 200) {
        const user_details = response.data.user;
        handleClick();
        setErrorMessage('Success! welcome to Vlearned.');
        if (user_details.role === "Administrator") {
          Cookies.set('user_details', JSON.stringify(user_details), { expires: 7 });
          localStorage.setItem('user_details', JSON.stringify(user_details));
          setTimeout(() => history.push(`/admin/${user_details._id}/next-steps`), 500);
        } else if (user_details.role === "Super Administrator") {
          Cookies.set('user_details', JSON.stringify(user_details), { expires: 7 });
          localStorage.setItem('user_details', JSON.stringify(user_details));
          setTimeout(() => history.push(`/super-admin/${user_details._id}/dashboard`), 500);
        } else if (user_details.role === "Student") {
          var options = {
            method: 'GET',
            url: `${api_link}/students/user-student/${user_details._id}`
          };

          axios.request(options).then(function (response) {
            const student = response.data.data;

            if (student) {
              const combinedUserDetails = {
                ...user_details,
                organizationId: student.organizationId?._id,
                studentId: student?._id
              };
              Cookies.set('user_details', JSON.stringify(combinedUserDetails), { expires: 7 });
              setLoading(false);
              setTimeout(() => history.push(`/student/${student?._id}/${student.organizationId?.type}/${student.organizationId?._id}/next-steps`), 200);
            } else {
              console.log('nothing')
            }

            // setTimeout(() => window.location.href = `/student/${student?._id}/${student.organizationId?.type}/${student.organizationId?._id}/next-steps`, 200);
          }).catch(function (error) {
            console.error(error);
          });
        } else if (user_details.role === "Teacher") {
          var newoptions = {
            method: 'GET',
            url: `${api_link}/instructors/user-instructor/${user_details._id}`
          };

          axios.request(newoptions).then(function (response) {
            const teacher = response.data.data;

            if (teacher) {
              const combinedUserDetails = {
                ...user_details,
                organizationId: teacher.organizationId?._id,
                teacherId: teacher?._id
              };
              Cookies.set('user_details', JSON.stringify(combinedUserDetails), { expires: 7 });
              setLoading(false);
              setTimeout(() => history.push(`/teacher/${teacher?._id}/${teacher.organisationId?.type}/${teacher.organisationId?._id}/next-steps`), 200);
            } else {
              console.log('nothing')
            }

            // setTimeout(() => window.location.href = `/teacher/${teacher?._id}/${teacher.organisationId?.type}/${teacher.organisationId?._id}/next-steps`, 500);            
          }).catch(function (error) {
            console.error(error);
          });
        } else {
          Cookies.set('user_details', JSON.stringify(user_details), { expires: 7 });
          localStorage.setItem('user_details', JSON.stringify(user_details));
          setTimeout(() => history.push(`/admin/${user_details._id}/next-steps`), 500);
        }
      } else {
        console.log(response);
        setErrorMessage(`Error: ${response.data.error}`);
        setOpen(true);
        setLoading(false);
      }
    }).catch(function (error) {
      handleClick();
      if (error.response.data.error === "Account not activated, Activate first to login") {
        setErrorMessage('Error: Account not activated');

        setTimeout(() => {
          window.location.href = '/activate-account';
        }, 3000)
      }

      console.log(error);
      setLoading(false);
      setErrorMessage('Error: ' + error.response.data.error);
    });
  }

  return (
    <Container maxWidth="sm" className='container-flex'>
      <Box height={500} width={'100%'} my={4} display="flex" alignItems="center" gap={4} p={2} alignSelf="center">
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={errorMessage.startsWith('Error:') ? 'error' : 'success'} id='errorMessage'>
            {errorMessage}
          </Alert>
        </Snackbar>

        <form className='account-bg-white'>
          <center><Link to='/'><img src={logo} alt="logo" width={240} /></Link></center>

          <p className='head'>Welcome!</p>

          <p className='subhead'>Signup to VLEARNED to get started</p>

          <TextField type='email' id="outlined-basic" label="Email" required variant="outlined" className='w-100' onChange={(e) => setEmail(e.target.value)} />

          <TextField type={showPassword ? 'text' : 'password'} id="outlined-basic" label="Password" onChange={(e) => setPassword(e.target.value)} required variant="outlined" className='w-100' InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
          />
          <p className='option2'><Link className='link' to='/forgot-password'>Forgot Password</Link></p>

          {loading ? (
            <Button variant="contained" color="primary" className='mt-7' disabled>
              <CircularProgress size={24} />
            </Button>
          ) : (
            <Button variant="contained" color="primary" className='mt-7' onClick={handleLogin}>Login</Button>
          )}

          <p className='option'>Don't have an account? <Link to='/register' className='link text-danger'>Register</Link></p>
        </form>
      </Box>
    </Container>
  )
}

export default Login;
