import { Button } from "@material-ui/core";
import React from "react";

export const adminWelcomeSteps = [
  {
    selector: ".first-step",
    content: ({ goTo }) => (
      <div>
        <div style={{ display: "flex" }}>
          <h1
            style={{
              marginBottom: "20px",
              marginRight: "10px",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            🚀
          </h1>
          <h1
            style={{
              marginBottom: "20px",
              fontSize: "20px",
              fontWeight: "bold",
              background:
                "-webkit-linear-gradient(45deg, #ff5e00 10%, purple 90%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            First things first
          </h1>
        </div>

        <p style={{ marginTop: "10px", fontSize: "14px", color: "white" }}>
          This is your administrative module. If you ever need help, the first
          two places to run to are
        </p>
        <Button
          target="_blank"
          href="https://docs.vlearned.com/"
          style={{
            borderRadius: "4px",
            background: "#604a9f",
            color: "white",
            fontSize: "14px",
            letterSpacing: "1px",
            transition: "all 0.3s ease",
            cursor: "pointer",
            fontWeight: "bold",
            textTransform: "none",
            "&:hover": {
              color: "#F5811F",
            },
            marginTop: "20px",
            marginRight: "10px",
          }}
        >
          Documentation
        </Button>
        <Button
          target="_blank"
          href="https://vlearned.uvdesk.com/apps/form-builder/en/form/html/130326399e0a29119d6399e0a29124a"
          style={{
            borderRadius: "4px",
            background: "#604a9f",
            color: "white",
            fontSize: "14px",
            letterSpacing: "1px",
            transition: "all 0.3s ease",
            cursor: "pointer",
            fontWeight: "bold",
            textTransform: "none",
            "&:hover": {
              color: "#F5811F",
            },
            marginTop: "20px",
            marginRight: "10px",
          }}
        >
          Support
        </Button>
      </div>
    ),
  },
  {
    selector: ".second-step",
    content: ({ goTo }) => (
      <div>
        <div style={{ display: "flex" }}>
          <h1
            style={{
              marginBottom: "20px",
              marginRight: "10px",
              fontSize: "25px",
              fontWeight: "bold",
            }}
          >
            👉🏼
          </h1>
          <h1
            style={{
              marginBottom: "20px",
              fontSize: "20px",
              fontWeight: "bold",
              background:
                "-webkit-linear-gradient(45deg, #ff5e00 10%, purple 90%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            A good place to start
          </h1>
        </div>
        <p style={{ marginTop: "10px", fontSize: "14px", color: "white" }}>
          This module is designed so that you have a bird's eye view of your
          organization at all times. Here you can onboard your users and manage
          your subscription plans.
        </p>
      </div>
    ),
  },
  {
    selector: ".third-step",
    content: ({ goTo }) => (
      <div>
        <div style={{ display: "flex" }}>
          <h1
            style={{
              marginBottom: "20px",
              marginRight: "10px",
              fontSize: "25px",
              fontWeight: "bold",
            }}
          >
            📚
          </h1>
          <h1
            style={{
              marginBottom: "20px",
              fontSize: "20px",
              fontWeight: "bold",
              background:
                "-webkit-linear-gradient(45deg, #ff5e00 10%, purple 90%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Create your courses
          </h1>
        </div>
        <p style={{ marginTop: "10px", fontSize: "14px", color: "white" }}>
          Use this module to create your organization's courses and assign them
          to your onboarded instructors and trainees.
        </p>
      </div>
    ),
  },
  {
    selector: ".fourth-step",
    content: ({ goTo }) => (
      <div>
        <div style={{ display: "flex" }}>
          <h1
            style={{
              marginBottom: "20px",
              marginRight: "10px",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            📄
          </h1>
          <h1
            style={{
              marginBottom: "20px",
              fontSize: "20px",
              fontWeight: "bold",
              background:
                "-webkit-linear-gradient(45deg, #ff5e00 10%, purple 90%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            One-stop reporting
          </h1>
        </div>
        <p style={{ marginTop: "10px", fontSize: "14px", color: "white" }}>
          We're still building this one but check back soon. This module will
          provide you with customizable tools to monitor your organization's
          learning and training performance.
        </p>
      </div>
    ),
  },
  {
    selector: ".fifth-step",
    content: ({ goTo }) => (
      <div>
        <div style={{ display: "flex" }}>
          <h1
            style={{
              marginBottom: "20px",
              marginRight: "10px",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            🎙️
          </h1>
          <h1
            style={{
              marginBottom: "20px",
              fontSize: "20px",
              fontWeight: "bold",
              background:
                "-webkit-linear-gradient(45deg, #ff5e00 10%, purple 90%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Vlearned Forum
          </h1>
        </div>
        <p style={{ marginTop: "10px", fontSize: "14px", color: "white" }}>
          Whether you are curious about what Vlearned has to offer, asking or
          answering questions, or meeting fellow Vlearned community members, you
          are welcome here. This is also a safe space to make feature requests.
          Don't be shy, we are nothing without you.
        </p>
      </div>
    ),
  },
  {
    selector: ".sixth-step",
    content: ({ goTo }) => (
      <div>
        <div style={{ display: "flex" }}>
          <h1
            style={{
              marginBottom: "20px",
              marginRight: "10px",
              fontSize: "25px",
              fontWeight: "bold",
            }}
          >
            🤙🏼
          </h1>
          <h1
            style={{
              marginBottom: "20px",
              fontSize: "20px",
              fontWeight: "bold",
              background:
                "-webkit-linear-gradient(45deg, #ff5e00 10%, purple 90%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Talk to us anytime
          </h1>
        </div>
        <p style={{ marginTop: "10px", fontSize: "14px", color: "white" }}>
          Our team is here to help with any issues you face. Feel free to submit
          a ticket here and someone will get back to you.
        </p>
      </div>
    ),
  },
  {
    selector: ".seventh-step",
    content: ({ goTo, setIsOpen, setCurrentStep }) => (
      <div>
        <div style={{ display: "flex" }}>
          <h1
            style={{
              marginBottom: "20px",
              marginRight: "10px",
              fontSize: "25px",
              fontWeight: "bold",
            }}
          >
            🙌🏼
          </h1>
          <h1
            style={{
              marginBottom: "20px",
              fontSize: "20px",
              fontWeight: "bold",
              background:
                "-webkit-linear-gradient(45deg, #ff5e00 10%, purple 90%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            You made it!
          </h1>
        </div>
        <p style={{ marginTop: "10px", fontSize: "14px", color: "white" }}>
          You are now ready to get started <br /> <br />
          One last thing! Click this will give you a menu that helps you jump
          quickly between all administrative modules. This is also where you
          will find Vlearned Buddy to take this trip again. Feel free to pass by
          anytime.
        </p>
        <Button
          onClick={() => {
            setCurrentStep(1);
            setIsOpen(false);
            window.scrollTo(0, 0);
          }}
          style={{
            borderRadius: "4px",
            background: "#604a9f",
            color: "white",
            fontSize: "14px",
            letterSpacing: "1px",
            transition: "all 0.3s ease",
            cursor: "pointer",
            fontWeight: "bold",
            textTransform: "none",
            "&:hover": {
              color: "#F5811F",
            },
            marginTop: "20px",
            marginRight: "10px",
          }}
        >
          I'm ready to start
        </Button>
      </div>
    ),
  },
];
