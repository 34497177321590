// @flow
import * as React from "react";
import { Card, makeStyles } from "@material-ui/core";
import styles from "./styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

type Props = {
  warningActionIcon: React.Node,
  warningActionMsg?: string,
};

export default function WarningCard({
  warningActionIcon,
  warningActionMsg,
}: Props): React.Node {
  const classes = useStyles();
  const { t } = useTranslation("common");
  return (
    <Card className={classes.warningCard}>
      <div className={classes.warningCardDiv}>
        {warningActionIcon}
        <p className={classes.warningActionMsg}>
          {warningActionMsg ? warningActionMsg : t("warning_card.message")}
        </p>
      </div>
    </Card>
  );
}
